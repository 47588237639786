import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as DashboardIcon } from "../assets/images/icon-dashboard.svg";
import { ReactComponent as AccountIcon } from "../assets/images/icon-myaccount.svg";
import { ReactComponent as OrderHistoryIcon } from "../assets/images/icon-order-history.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/icon-logout.svg";
import { ReactComponent as HaveList } from "../assets/images/icon-stock.svg";
import { ReactComponent as Redeem } from "../assets/images/icon-havelist.svg";
import { ReactComponent as ReactivateIcon } from "../assets/images/icon-reactivate.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icon-notification.svg";
// import { ReactComponent as BrowseIcon } from "../assets/images/icon-browse.svg";
import { ReactComponent as ProductIcon } from "../assets/images/icon-product.svg";
import { ReactComponent as StatsIcon } from "../assets/images/icon-stats.svg";

import { handleLogout } from "../utils/CommonFunction";
export default function SideNavigation() {
  return (
    <aside>
      <ul>
        <li
          className={
            localStorage.getItem("user_role") === "Buyer" &&
            window.location.pathname === "/buyerhome" &&
            "/buyerlist"
              ? "active"
              : localStorage.getItem("user_role") === "Seller" &&
                window.location.pathname === "/sellerhome"
              ? "active"
              : null
          }
        >
          <Link
            to={
              localStorage.getItem("user_role") === "Buyer"
                ? "/buyerhome"
                : localStorage.getItem("user_role") === "Seller"
                ? "/sellerhome"
                : null
            }
          >
            {" "}
            <DashboardIcon className="icon-dashboard" /> <span> Home </span>
          </Link>
        </li>
        {localStorage.getItem("user_role") === "Seller" ? (
          <li
            className={
              window.location.pathname === "/sellerprofile" ? "active" : null
            }
          >
            <Link to="/sellerprofile">
              {" "}
              <AccountIcon className="icon-myaccount" /> <span> Account</span>
            </Link>
          </li>
        ) : null}
        {localStorage.getItem("user_role") === "Buyer" ? (
          <li
            className={
              window.location.pathname === "/buyerprofile" ? "active" : null
            }
          >
            <Link to="/buyerprofile">
              {" "}
              <AccountIcon className="icon-myaccount" /> <span> Account</span>
            </Link>
          </li>
        ) : null}
        {localStorage.getItem("user_role") === "Seller" ? (
          <li
            className={
              window.location.pathname === "/sellerProductList" ||
              window.location.pathname === "/addProduct"
                ? "active"
                : null
            }
          >
            <Link to="/sellerProductList">
              {" "}
              <ProductIcon className="icon-myaccount" /> <span>Product</span>
            </Link>
          </li>
        ) : null}

        {localStorage.getItem("user_role") === "Seller" ? (
          <li
            className={
              window.location.pathname === "/orderhistory" && "/orderdetails"
                ? "active"
                : null
            }
          >
            <Link to="/orderhistory">
              {" "}
              <OrderHistoryIcon className="icon-order-history" />{" "}
              <span className="pro-subheading"> Order History</span>
            </Link>
          </li>
        ) : null}
        {localStorage.getItem("user_role") === "Seller" ? (
          <li
            className={
              window.location.pathname === "/reactivationhistoryseller" &&
              "/reactivationhistoryseller"
                ? "active"
                : null
            }
          >
            <Link to="/reactivationhistoryseller">
              {" "}
              <ReactivateIcon className="icon-reactivate" />{" "}
              <span> Reactivation History</span>
            </Link>
          </li>
        ) : null}
        {localStorage.getItem("user_role") === "Seller" ? (
          <li
            className={
              window.location.pathname === "/sellernotification" &&
              "/sellernotification"
                ? "active"
                : null
            }
          >
            <Link to="/sellernotification">
              {" "}
              <NotificationIcon className="icon-reactivate" />{" "}
              <span> Notifications</span>
            </Link>
          </li>
        ) : null}
        {localStorage.getItem("user_role") === "Seller" ? (
          <li
            className={
              window.location.pathname === "/sellerstats" && "/sellerstats"
                ? "active"
                : null
            }
          >
            <Link to="/sellerstats">
              {" "}
              <StatsIcon className="icon-reactivate" />{" "}
              <span> Seller statistics</span>
            </Link>
          </li>
        ) : null}
        {/* {localStorage.getItem("user_role") === "Buyer" ? (
          <li
            className={
              window.location.pathname === "/createWantList" ? "active" : null
            }
          >
            <Link to="/createWantList">
              {" "}
              <BrowseIcon className="icon-browse" /> <span>Create Wantlist</span>
            </Link>
          </li>
        ) : null} */}

        {localStorage.getItem("user_role") === "Buyer" ? (
          <li
            className={
              window.location.pathname === "/my-stockroom" ? "active" : null
            }
          >
            <Link to="/my-stockroom">
              {" "}
              <HaveList className="icon-myaccount" /> <span>Stockroom</span>
            </Link>
          </li>
        ) : null}

        {localStorage.getItem("user_role") === "Buyer" ? (
          <li
            className={
              window.location.pathname === "/reactivationhistorybuyer" &&
              "/reactivationhistorybuyer"
                ? "active"
                : null
            }
          >
            <Link to="/reactivationhistorybuyer">
              {" "}
              <ReactivateIcon className="icon-reactivate" />{" "}
              <span> Reactivation History</span>
            </Link>
          </li>
        ) : null}

        {localStorage.getItem("user_role") === "Buyer" ? (
          <li
            className={
              window.location.pathname === "/redeemhistory" ? "active" : null
            }
          >
            <Link to="/redeemhistory">
              {" "}
              <Redeem className="icon-myaccount" />{" "}
              <span className="pro-subheading">Redeem History</span>
            </Link>
          </li>
        ) : null}
        {localStorage.getItem("user_role") === "Buyer" ? (
          <li
            className={
              window.location.pathname === "/buyernotification" &&
              "/buyernotification"
                ? "active"
                : null
            }
          >
            <Link to="/buyernotification">
              {" "}
              <NotificationIcon className="icon-reactivate" />{" "}
              <span> Notifications</span>
            </Link>
          </li>
        ) : null}
        <li>
          <Link to="/login" onClick={handleLogout}>
            {" "}
            <LogoutIcon className="icon-logout" /> <span>Logout</span>{" "}
          </Link>
        </li>
      </ul>
    </aside>
  );
}
