import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useReducer,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import SideNavigation from "../../components/SideNavigation";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";
import editPencil from "../../assets/images/icon-edit.svg";
import deleteIcon from "../../assets/images/icon-delete.svg";
import Box from "@mui/material/Box";
import DatePicker from "react-datepicker";
import { hideLoading, showLoading } from "../../redux/slices/LoaderSlice";
import Loader from "../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import { showErrorMessage } from "../../constants/Toast";
import {
  redeemOrder,
  ViewOrderDetailsStockRoomSellerWise,
  DeleteBuyerSideProduct,
  UpdateOrder,
  ReactivateOrderItemNew,
  EditBuyerSideProduct,
} from "../../api/request";
//** qr style  */
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "30%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
//** check box click component */
const CheckboxInput = React.memo(
  ({ item, onEditItemsChange, selectedQuantity }) => {
    return (
      <input
        type="checkbox"
        data-size-id="1"
        data-order-id={item.order_id}
        data-order-item-id={item.order_item_id}
        data-supplier-id={item.sellerId}
        data-product-id={item.product_id}
        data-variant-id={item.variant_id}
        data-total-quantity={item.orderedQuantity}
        data-delivered-quantity={item.delivered_quantity}
        data-price={item.item_price}
        data-redeem={selectedQuantity[item.redeem]}
        data-unique-row-identifier={item.unique_row_identifier}
        onChange={(e) => onEditItemsChange(e, item)}
      />
    );
  }
);
const OrderListPage = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { isLoading } = useSelector((state) => state.loader);
  const SellersId = location.state && location.state.id;
  const SellersName = location.state && location.state.name;
  const dataEditObject = useRef([]);
  const dataEditSellerId = useRef([]);
  const EditRedeemQty = useRef([]);
  const SelectedEditProduct = useRef({});
  const [cartItems, setCartItems] = useState({});
  const [selectedQuantity, setSelectQuantity] = useState({});
  const [dataObject, setDataObject] = useState([]);
  const [sellerId, setSellerId] = useState([]);
  const [code, setCode] = useState();
  const [apiResponse, setApiResponse] = useState();
  const [detailRedeemedOrderItems, setDetailRedeemedOrderItems] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [redeemQtyV, setRedeemQtyV] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [prevPage, setPrevPage] = useState(null);
  const [perPageData] = useState(10);
  const [searchResult, setSearchResult] = useState("");
  const [error, setError] = useState();
  const [resetValue, setResetValue] = useState(false);
  const [tags, setTags] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [editProductData, setEditData] = useState([]);
  const [editProductSellerData, setEditSellerData] = useState([]);
  const [selectedExpiryItem, setSelectedExpiryItem] = useState([]);
  const [editProductSeller, setEditSeller] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedProductOption, setSelectedProductOption] = useState({});
  const [selectedMaxDate, setSelectedMaxDate] = useState({});
  const [additionalInformation, setAdditionalInformation] = useState({});
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [addressInfo, setAddressInfo] = useState("");
  const [tableId, setTableId] = useState("");
  const [submitBtnClick, setSubmitBtnClick] = useState(false);
  const [timeSlot, setTimeSlot] = useState([]);
  const [selectedOrderItemId, setSelectedOrderItemId] = useState(0);

  const TypeOneoptions = React.useMemo(
    () => [{ id: 1, value: "Redeem via QR" }],
    []
  );
  const TypeTwooptions = React.useMemo(
    () => [
      { id: 1, value: "Redeem via QR" },
      // { id: 2, value: "Bring it to me" },
      { id: 3, value: "Pick up later" },
    ],
    []
  );

  const orderPlace = useCallback(
    async (reset = false) => {
      let viewRedeemHistory = [];
      let viewOrderItemHistory = [];

      setResetValue(false);

      let buyerId = await JSON.parse(localStorage.getItem("user_details"));
      let data = {
        buyer_id: buyerId?.user_id,
        item_name: searchResult,
        postcodes: tags ? tags : null,
        page_limit: perPageData,
        page: page,
        user_id: SellersId,
      };

      try {
        let result = await ViewOrderDetailsStockRoomSellerWise(data);
        if (result?.success) {
          if (result?.data) {
            setCartItems(result?.data[0]?.items);
            setTimeSlot(result?.data[0].time_ranges_all);
            setApiResponse(result?.data);
            setCode(result?.data?.code);
            setPrevPage(result?.data?.previousPageUrl);
            setNextPage(result?.data?.nextPageUrl);
            localStorage.setItem("code", result?.data?.code);
            localStorage.setItem(
              "sellerQr_image_name",
              result?.data?.image_name
            );
            const viewRedeemKeys = Object.keys(result?.data?.redeem_history);
            viewRedeemKeys.forEach((key) => {
              const viewsellerRedeemData = result?.data?.redeem_history[key];
              viewRedeemHistory.push(viewsellerRedeemData);
            });
            setDetailRedeemedOrderItems(viewRedeemHistory);
            setSelectedExpiryItem(result?.data?.expired_items);

            const viewOrderItemId = Object.keys(result?.data?.expired_items);
            viewOrderItemId.forEach((key) => {
              const viewOrderItemIdData =
                result?.data?.expired_items[key]?.[0]?.order_item_id;
              localStorage.setItem(
                "order_item_id",
                result?.data?.expired_items[key]?.[0]?.order_item_id
              );
              viewOrderItemHistory.push(viewOrderItemIdData);
            });
            setSelectedOrderItemId(viewOrderItemHistory);
          }
        } else if (result?.success == false) {
          setCartItems([]);
          // showErrorMessage(result?.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [page, perPageData, searchResult, tags, SellersId]
  );

  useEffect(() => {
    document.title = "Paysah | ORDERLIST";
    orderPlace();
  }, [page, resetValue, orderPlace]);

  const increaseQuantity = useCallback(
    (productId, itemVarientId, seller_Id) => {
      let latestData = dataObject[seller_Id] || [];
      let redeemQty =
        selectedProducts[`${productId}`]?.[`${itemVarientId}`]?.redeem || 0;
      setSelectedProducts((prevProducts) => ({
        ...prevProducts,
        [`${productId}`]: {
          ...prevProducts[`${productId}`],
          [`${itemVarientId}`]: {
            redeem: redeemQty + 1,
          },
        },
      }));
      const variantIndex = latestData.findIndex(
        (obj) => obj.variant_id == itemVarientId
      );
      if (variantIndex !== -1) {
        dataObject[seller_Id][variantIndex].redeem = parseInt(redeemQty) + 1;
      }
      if (parseInt(redeemQty) + 1 > 0 && redeemQtyV.includes(itemVarientId)) {
        let selectedItemIndex = redeemQtyV.indexOf(itemVarientId);
        if (selectedItemIndex > -1) {
          redeemQtyV.splice(selectedItemIndex, 1);
          setRedeemQtyV([...new Set(redeemQtyV)]);
        }
      }
    },
    [dataObject, selectedProducts, redeemQtyV]
  );

  const decreaseQuantity = useCallback(
    (productId, itemVarientId, seller_Id) => {
      let latestData = dataObject[seller_Id] || [];
      let redeemQty =
        selectedProducts[`${productId}`]?.[`${itemVarientId}`]?.redeem || 0;
      setSelectedProducts((prevProducts) => ({
        ...prevProducts,
        [`${productId}`]: {
          ...prevProducts[`${productId}`],
          [`${itemVarientId}`]: {
            redeem: redeemQty - 1,
          },
        },
      }));
      const variantIndex = latestData.findIndex(
        (obj) => obj.variant_id == itemVarientId
      );
      if (variantIndex !== -1) {
        dataObject[seller_Id][variantIndex].redeem = parseInt(redeemQty) - 1;
      }
      if (parseInt(redeemQty) - 1 <= 0 && !redeemQtyV.includes(itemVarientId)) {
        redeemQtyV.push(itemVarientId);
        setRedeemQtyV([...new Set(redeemQtyV)]);
      }
    },
    [dataObject, selectedProducts, redeemQtyV]
  );

  const handleChangeItems = useCallback(
    (
      e,
      seller_Id,
      product_id,
      variant_id,
      supplier_id,
      order_item_id,
      oID,
      UniqueId
    ) => {
      const { checked } = e.target;
      let dt_redeem = selectedProducts[e.target.dataset.uniqueRowIdentifier]?.[
        e.target.dataset.variantId
      ]?.redeem
        ? selectedProducts[e.target.dataset.uniqueRowIdentifier]?.[
            e.target.dataset.variantId
          ]?.redeem
        : 0;

      let newDataObject = { ...dataObject };
      if (checked) {
        setSellerId({ ...sellerId, [`${seller_Id}_${oID}`]: oID });
        newDataObject[seller_Id] = newDataObject[seller_Id] || [];
        newDataObject[seller_Id].push({
          supplier_id: e.target.dataset.supplierId,
          product_id: e.target.dataset.productId,
          variant_id: e.target.dataset.variantId,
          total_quantity: e.target.dataset.totalQuantity,
          delivered_quantity: e.target.dataset.deliveredQuantity,
          item_price: e.target.dataset.price,
          redeem: dt_redeem || 0,
          order_item_id: order_item_id,
        });
        if (dt_redeem === 0 && !redeemQtyV.includes(variant_id)) {
          redeemQtyV.push(variant_id);
          setRedeemQtyV([...new Set(redeemQtyV)]);
        }
      } else {
        if (newDataObject[seller_Id]) {
          newDataObject[seller_Id] = newDataObject[seller_Id].filter(
            (item) => item.order_item_id !== order_item_id
          );
        }
        const newData = JSON.parse(JSON.stringify(sellerId));
        delete newData[`${seller_Id}_${oID}`];
        setSellerId(newData);
      }
      setDataObject(newDataObject);
    },
    [dataObject, sellerId, selectedProducts, redeemQtyV]
  );

  const handleChangeEditItems = useCallback(
    (e, item) => {
      const { order_item_id } = item;
      let { seller_id } = editProductSellerData;
      let newDataObject = { ...dataEditSellerId.current[seller_id] };
      if (e.target.checked) {
        // Add item to array if checkbox is checked
        newDataObject[seller_id] = newDataObject[seller_id] || [];
        newDataObject[seller_id].push({
          supplier_id: e.target.dataset.supplierId,
          product_id: e.target.dataset.productId,
          variant_id: e.target.dataset.variantId,
          total_quantity: e.target.dataset.totalQuantity,
          delivered_quantity: e.target.dataset.deliveredQuantity,
          item_price: e.target.dataset.price,
          redeem: 0,
          redeem_quantity: 0,
          order_item_id: order_item_id,
          item_id: order_item_id,
          order_id: e.target.dataset.orderId,
        });
        dataEditObject.current = [...dataEditObject.current, item];
      } else {
        // Remove item from array if checkbox is unchecked
        if (newDataObject[seller_id]) {
          newDataObject[seller_id] = newDataObject[seller_id].filter(
            (item) => item.item_id !== order_item_id
          );
        }
        dataEditObject.current = dataEditObject.current.filter(
          (i) => i.order_item_id !== order_item_id
        );
      }

      dataEditSellerId.current = newDataObject;
      forceUpdate();
    },
    [editProductSellerData]
  );
  const keys = Object.keys(cartItems);

  const allSellers = React.useMemo(() => {
    let sellers = [];
    keys.forEach((key) => {
      const sellerData = cartItems[key];
      sellers.push(sellerData);
    });
    return sellers;
  }, [cartItems, keys]);
  //** back btn press */
  const handleBack = () => {
    navigation(`/my-stockroom`);
  };
  //** redeem via qr btn press */
  const handleRedeem = useCallback(
    async (sId, oID, QrCode, pId) => {
      // setDetailRedeemedOrderItems();
      let redeemHistory = [];
      let payload = dataObject[sId] || [];
      let updatedData = [];

      updatedData = payload.filter(
        (item) =>
          item.supplier_id !== sId &&
          item.redeem !== 0 &&
          item.product_id == pId
      );

      let user_details = JSON.parse(localStorage.getItem("user_details"));
      let data = {
        user_id: user_details.user_id,
        seller_id: sId,
        code: QrCode,
        order_items: updatedData,
      };
      dispatch(showLoading());
      // console.log({ updatedData, data, pId, sId, oID, QrCode });
      try {
        let result = await redeemOrder(data);
        if (result?.success) {
          dispatch(hideLoading());
          const redeemKeys = Object.keys(result?.data?.redeem_history);
          let lastIndexKey = redeemKeys[redeemKeys.length - 1];
          redeemKeys.map((key) => {
            const sellerRedeemData = result?.data?.redeem_history[key];

            redeemHistory.push(sellerRedeemData);
          });
          if (redeemHistory.length > 0) {
            setDetailRedeemedOrderItems(redeemHistory);

            Swal.fire({
              imageUrl:
                "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
                `${result?.data?.image_name_new_generated}`,
              imageWidth: 700,
              imageHeight: 400,
              imageAlt: "Custom image",
              confirmButtonText: "Ok",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                window.location.reload();
                orderPlace();
              } else {
                window.location.reload();
                orderPlace();
              }
            });
          } else {
            setDetailRedeemedOrderItems(detailRedeemedOrderItems);
          }
        } else {
          console.log("error there!");
          dispatch(hideLoading());
          // showErrorMessage(result?.data);
          Swal.fire({
            title: "Alert!",
            text: result?.data,
            icon: "warning",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            } else {
              window.location.reload();
            }
          });
          setShow(true);
        }
      } catch (error) {
        dispatch(hideLoading());
        console.log(error);
      }
    },
    [dispatch, dataObject, detailRedeemedOrderItems, orderPlace]
  );
  //** qty update  */
  const handleUpdateQty = useCallback(async (bId, sId, oID, QrCode) => {
    // setDetailRedeemedOrderItems();
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });
    let OrderItem = [];
    if (dataEditSellerId.current?.length === 0) {
      showErrorMessage("Please select checkbox first.");
      dataEditSellerId.current = [];
      OrderItem = [];
      return;
    }
    dataEditSellerId.current[sId]?.forEach((dt) => {
      OrderItem.push({
        item_id: dt.item_id,
        redeem_quantity: dt.redeem,
        order_id: dt.order_id,
      });
    });
    let rQty = OrderItem?.some((dt) => dt.redeem_quantity === 0);
    console.log("dataEditSellerId", dataEditSellerId.current, OrderItem, rQty);
    if (rQty === true) {
      showErrorMessage("Please add quantity first.");
      return;
    }
    let data = {
      buyer_id: bId,
      seller_id: sId,
      redemption_code: QrCode,
      order_items: OrderItem,
    };
    console.log({ data });
    try {
      let result = await UpdateOrder(data);
      if (result?.success) {
        swalWithBootstrapButtons
          .fire({
            title: "Updated",
            text: "Your product has been updated successfully.",
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            } else {
              window.location.reload();
            }
          });
        setOpen(false);
      } else {
        console.log("error there!");
        showErrorMessage(result?.data);
        setShow(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  //** popup btn click */
  const handlePopup = useCallback((imgName) => {
    Swal.fire({
      imageUrl:
        "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
        `${imgName}`,
      imageWidth: 700,
      imageHeight: 400,
      imageAlt: "Custom image",
      confirmButtonText: "Cancel",
    });
  }, []);

  //** handle Edit managegr */
  const handleEditManager = useCallback(async (obj) => {
    let redemption_code, sellerId, buyer_id, product_id;
    // console.log("obj", JSON.stringify(obj));

    function allNonNullValues(obj, key) {
      redemption_code = obj[key]?.redemption_code;
      sellerId = obj[key]?.sellerId;
      buyer_id = obj[key]?.buyer_id;
      product_id = obj[key]?.product_id;
    }
    const objectsWithRedemptionCode = obj.filter(
      (obj) => obj.redemption_code !== null
    );
    Object.keys(objectsWithRedemptionCode).forEach((key) => {
      allNonNullValues(obj, key);
    });
    const params = {
      buyer_id: buyer_id,
      seller_id: sellerId,
      redemption_code: redemption_code,
    };
    try {
      const result = await EditBuyerSideProduct(params);
      if (result?.success) {
        let redeemDataForEdit = result?.data?.products[product_id];
        handleOpen(params, redeemDataForEdit);
      } else {
        showErrorMessage(result?.data);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }, []);

  //** handle Delete manager */
  const handleDeleteManager = useCallback(
    async (obj) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
        allowOutsideClick: false,
        showCloseButton: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "Do you really want to cancel this request!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let redemption_code, sellerId, buyer_id;
            function allNonNullValues(obj, key) {
              console.log(obj, key);
              redemption_code =
                obj.length > 0
                  ? obj[key]?.redemption_code
                  : obj[key]?.redemption_code;
              sellerId = obj[key]?.sellerId;
              buyer_id = obj[key]?.buyer_id;
            }
            const objectsWithRedemptionCode = obj.filter(
              (obj) => obj.redemption_code !== null
            );
            console.log("objectsWithRedemptionCode", objectsWithRedemptionCode);
            Object.keys(objectsWithRedemptionCode).forEach((key) => {
              allNonNullValues(objectsWithRedemptionCode, key);
            });
            const params = {
              buyer_id: buyer_id,
              seller_id: sellerId,
              redemption_code: redemption_code,
            };

            const result = await DeleteBuyerSideProduct(params);
            try {
              if (result?.success) {
                swalWithBootstrapButtons.fire({
                  title: "Deleted!",
                  text: "Your product has been deleted.",
                  icon: "success",
                });
                orderPlace();
              } else {
                showErrorMessage(result?.data);
                setShow(true);
              }
            } catch (error) {
              console.log(error);
            }
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire({
              title: "Cancelled",
              text: "Your product is safe!",
              icon: "error",
            });
          }
        });
    },
    [orderPlace]
  );

  let dataObjKey = Object.keys(dataObject);
  //** max date */
  const getMaxDate = useCallback(
    (sellerId, colMaxCreatedAt) => {
      const selectedProduct = colMaxCreatedAt.filter(
        (user) => user?.supplier_id == sellerId
      );
      if (selectedProduct.length) {
        const maxDate = selectedProduct[0]?.order_item_validity_expires_at
          ? new Date(selectedProduct[0]?.order_item_validity_expires_at)
          : null;
        setSelectedMaxDate({ ...selectedMaxDate, [sellerId]: maxDate });
      } else {
        setSelectedMaxDate({ ...selectedMaxDate, [sellerId]: null });
      }
    },
    [selectedMaxDate]
  );
  //** select option change code */
  const handleOptionChanges = useCallback(
    (e, sId, pId, colMaxCreatedAt) => {
      const keys = Object.keys(cartItems);

      var variantId = localStorage.getItem("variant_id");
      const selectedValue = e.target.value;
      setSelectedOption({ ...selectedOption, [sId]: selectedValue });
      setSelectedProductOption({
        ...selectedProductOption,
        [pId]: selectedValue,
      });
      if (selectedValue == "3") {
        getMaxDate(sId, colMaxCreatedAt);
      }
    },
    [getMaxDate, selectedOption, selectedProductOption, cartItems]
  );
  //** Brin it to me functionality code */
  //Bring it to me onchange
  const onValueChange = async (event, sId) => {
    const selectedValue = event.target.value;
    setAdditionalInformation({
      ...additionalInformation,
      [sId]: selectedValue,
    });
  };
  const handleTableIdChange = (event, sId) => {
    const selectedValue = event.target.value;
    setTableId({ ...tableId, [sId]: selectedValue });
  };

  const handleAdditionalInfoChange = useCallback(
    (event, sId) => {
      const selectedValue = event.target.value;
      setAdditionalInfo({ ...additionalInfo, [sId]: selectedValue });
    },
    [additionalInfo]
  );

  const handleAddressInfoChange = useCallback(
    (event, sId) => {
      const selectedValue = event.target.value;
      setAddressInfo({ ...addressInfo, [sId]: selectedValue });
    },
    [addressInfo]
  );
  //** date change in pickup later */
  const handleDateChange = useCallback(
    (date, sId) => {
      setSelectedDate({ ...selectedDate, [sId]: date });
    },
    [selectedDate]
  );
  //** time slot change in pickup later */
  const handleTimeSlotChange = useCallback(
    (sId, timeSlot) => {
      const time = timeSlot?.id;
      setSelectedTimeSlot({ ...selectedTimeSlot, [sId]: time });
    },
    [selectedTimeSlot]
  );
  //** Pickup later submit click */
  const handleSubmitClick = useCallback(
    async (sId, code, pId) => {
      setSubmitBtnClick(true);

      let redeemHistory = [];
      let payload = dataObject[sId] || "";
      let updatedData = [];

      if (selectedOption[sId] == 1) {
        updatedData = payload.filter((item) => item.supplier_id !== sId);
      }

      if (selectedOption[sId] == 2 && tableId[sId] !== undefined) {
        updatedData = payload
          ?.filter((item) => item.supplier_id !== sId)
          .map((item) => ({
            ...item,
            bring_it_to_me: 2,
            bring_it_to_me_on_table_no: tableId[sId] || "",
            bring_it_to_me_additional_information: additionalInfo[sId] || "",
          }));
      }

      if (selectedOption[sId] == 2 && addressInfo[sId] !== undefined) {
        updatedData = payload
          ?.filter((item) => item.supplier_id !== sId)
          .map((item) => ({
            ...item,
            bring_it_to_me: 2,
            bring_it_to_me_at_address: addressInfo[sId] || "",
          }));
      }

      if (
        selectedOption[sId] == 3 &&
        selectedDate[sId] !== undefined &&
        selectedTimeSlot[sId] !== undefined
      ) {
        const dateString = selectedDate[sId];
        const dateObject = new Date(dateString);

        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
        const day = dateObject.getDate();

        const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
          day < 10 ? "0" : ""
        }${day}`;

        updatedData = payload
          .filter((item) => item.supplier_id !== sId)
          .map((item) => ({
            ...item,
            pick_up_later: 2,
            pick_up_later_date_time: formattedDate || "",
            pick_up_later_time_range_id: selectedTimeSlot[sId] || "",
          }));
      }
      let user_details = JSON.parse(localStorage.getItem("user_details"));

      // dispatch(showLoading());
      updatedData = updatedData.filter(
        (item) =>
          item.supplier_id !== sId &&
          item.redeem !== 0 &&
          item.product_id == pId
      );
      console.log("pickup later detail data ", updatedData, selectedDate[sId]);

      let data = {
        user_id: user_details.user_id,
        seller_id: sId,
        code: code,
        order_items: updatedData,
      };
      console.log("handle Click on product", JSON.stringify(data));
      if (
        selectedOption[sId] == 1 ||
        (selectedOption[sId] == 2 && tableId[sId] !== undefined) ||
        (selectedOption[sId] == 2 && addressInfo[sId] !== undefined) ||
        (selectedOption[sId] == 3 &&
          selectedDate[sId] !== undefined &&
          selectedTimeSlot[sId] !== undefined)
      ) {
        let result = await redeemOrder(data);
        // console.log({ result });
        if (result?.success) {
          dispatch(hideLoading());
          const redeemKeys = Object.keys(result?.data?.redeem_history);
          redeemKeys.forEach((key) => {
            const sellerRedeemData = result?.data?.redeem_history[key];
            redeemHistory.push(sellerRedeemData);
          });
          if (redeemHistory.length > 0) {
            setDetailRedeemedOrderItems(redeemHistory);
            Swal.fire({
              imageUrl:
                "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
                `${result?.data?.image_name_new_generated}`,
              imageWidth: 700,
              imageHeight: 400,
              imageAlt: "Custom image",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              } else {
                window.location.reload();
              }
            });
          } else {
            setDetailRedeemedOrderItems(detailRedeemedOrderItems);
          }
        } else {
          dispatch(hideLoading());
          // showErrorMessage(result?.data);
          Swal.fire({
            title: "Alert!",
            text: result?.data,
            icon: "warning",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            } else {
              window.location.reload();
            }
          });
          setShow(true);
        }
      }
    },
    [
      dispatch,
      selectedOption,
      tableId,
      addressInfo,
      selectedDate,
      selectedTimeSlot,
      dataObject,
      setDetailRedeemedOrderItems,
      setShow,
      detailRedeemedOrderItems,
      additionalInfo,
    ]
  );

  //** Active  / Inactive Order Item */
  const handleActive = useCallback(
    async (orId) => {
      dispatch(showLoading());
      let data = { order_item_id: orId };
      try {
        let result = await ReactivateOrderItemNew(data);
        if (result?.success) {
          dispatch(hideLoading());
          Swal.fire({
            title: "Product is activated",
            text: result?.message,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            } else {
              window.location.reload();
            }
          });
        } else {
          dispatch(hideLoading());
          Swal.fire({
            title: "Product not activated! Please contact admin!!",
            text: result?.message,
            icon: "error",
          }).then((result) => {
            if (result.isDenied) {
            }
          });
        }
      } catch (error) {
        dispatch(hideLoading());
        console.log(error);
      }
    },
    [dispatch]
  );

  //** Render Products */
  const renderAllObject = React.useCallback(() => {
    if (!allSellers || Object.keys(allSellers).length === 0) {
      return (
        <h3
          style={{
            display: "flex",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Orders Not found
        </h3>
      );
    }

    return (
      allSellers &&
      Object.keys(allSellers).length > 0 &&
      Object.entries(allSellers)
        .reverse()
        .map(([key, obj]) => {
          return (
            Object.keys(obj).length > 0 &&
            Object.entries(obj).map(([key, objItems]) => {
              return (
                Object.keys(objItems).length > 0 &&
                Object.entries(objItems).map(([key, objItem]) => {
                  let dataKey = dataObject[dataObjKey]?.map(
                    (t) => t.order_item_id
                  );
                  let numberArray = objItem?.some((order) =>
                    dataKey?.includes(order.order_item_id)
                  );
                  let showBtn = numberArray;
                  // const currentOption = selectedOption[productId] || "default_value";
                  return (
                    <div
                      class="order-item pro-subheading"
                      key={objItems.order_id}
                    >
                      {/* Add this line */}
                      <>
                        <div className="heading d-flex justify-space-between order-total pro-subheading">
                          <div>
                            <h5>
                              <strong className="pro-subheading">
                                Order Id: #{objItem[0].order_id_long}
                              </strong>
                            </h5>
                            <h7>
                              <strong>Order Date:</strong>{" "}
                              <span>
                                {moment(objItem[0].createdAt).format(
                                  "MMMM DD-YYYY hh:mm A"
                                )}
                              </span>
                            </h7>
                          </div>
                          <div className="total-order-price pro-subheading">
                            <h5>
                              <strong className="pro-subheading">
                                Order total: £
                                {objItem[0]?.order_item_new_sum_item_total}
                              </strong>
                            </h5>
                          </div>
                        </div>
                        <h5 className="order-heading pro-subheading">
                          {objItem[0].productName}
                        </h5>
                        <div className="table-responsive pro-subheading">
                          <table className="table havelisttable pro-subheading main-box order-table">
                            <tbody className="pro-subheading ">
                              <tr
                                className="pro-subheading "
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                <th width="5%"></th>
                                <th width="15%">Size</th>
                                <th width="25%">Valid From</th>
                                <th width="25%">Valid To (Expiry Date)</th>
                                <th width="15%">Quantity</th>
                                <th width="15%">Redeem</th>
                              </tr>
                              {objItem.map((item, index) => {
                                let remainingQty =
                                  item?.available_to_redeem_seller;
                                return (
                                  <>
                                    <tr
                                      key={key}
                                      style={{
                                        textAlign: "left",
                                      }}
                                      className="pro-subheading"
                                    >
                                      <td>
                                        {objItem[index].is_item_expired === 0 &&
                                        !objItem.some(
                                          (t) => t.redemption_code !== null
                                        ) &&
                                        remainingQty > 0 ? (
                                          <div>
                                            <input
                                              type="checkbox"
                                              data-size-id="1"
                                              data-order-item-id={
                                                item.order_item_id
                                              }
                                              data-supplier-id={item.sellerId}
                                              data-product-id={item.product_id}
                                              data-variant-id={item.variant_id}
                                              data-total-quantity={
                                                item.orderedQuantity
                                              }
                                              data-delivered-quantity={
                                                item.delivered_quantity
                                              }
                                              data-price={item.item_price}
                                              data-redeem={
                                                selectedQuantity[item.redeem]
                                              }
                                              data-unique-row-identifier={
                                                item.unique_row_identifier
                                              }
                                              onChange={(e, id) =>
                                                handleChangeItems(
                                                  e,
                                                  objItem[0]?.sellerId,
                                                  item.product_id,
                                                  item.variant_id,
                                                  item.supplier_id,
                                                  item.order_item_id,
                                                  item.order_id,
                                                  item.unique_row_identifier
                                                )
                                              }
                                            />
                                          </div>
                                        ) : null}
                                      </td>

                                      <td className="pro-subheading">
                                        {item.weight && item?.packing_unit ? (
                                          <>
                                            {" "}
                                            {item.weight}
                                            &nbsp;{item.packing_unit}
                                          </>
                                        ) : item.weight ? (
                                          <> {item.weight}</>
                                        ) : item.packing_unit ? (
                                          <> &nbsp;{item.packing_unit}</>
                                        ) : (
                                          "_"
                                        )}
                                      </td>
                                      <td>
                                        {moment(item.product_valid_from).format(
                                          "MMMM DD-YYYY hh:mm A"
                                        )}
                                      </td>
                                      <td>
                                        {moment(item.product_valid_till).format(
                                          "MMMM DD-YYYY hh:mm A"
                                        )}{" "}
                                      </td>
                                      <td className="pro-subheading">
                                        {item.is_requested_in_bunch == 1 ? (
                                          <span>
                                            {item.orderedQuantity} (
                                            {item.available_qty})
                                          </span>
                                        ) : (
                                          <span>{item.orderedQuantity}</span>
                                        )}
                                      </td>
                                      <td className="pro-subheading">
                                        {remainingQty > 0 ? (
                                          <div className="quantity">
                                            <div className="number ">
                                              <div className="number-quantity">
                                                <button
                                                  className="quantity-btn"
                                                  onClick={() =>
                                                    decreaseQuantity(
                                                      item.unique_row_identifier,
                                                      item.variant_id,
                                                      objItem[0]?.sellerId,
                                                      objItem[index]
                                                        ?.order_item_id
                                                    )
                                                  }
                                                  disabled={
                                                    selectedProducts[
                                                      `${item.unique_row_identifier}`
                                                    ]?.[`${item.variant_id}`]
                                                      ?.redeem === undefined ||
                                                    selectedProducts[
                                                      `${item.unique_row_identifier}`
                                                    ]?.[`${item.variant_id}`]
                                                      ?.redeem === 0
                                                  }
                                                >
                                                  <svg
                                                    width="20px"
                                                    height="20px"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M4 12H20"
                                                      stroke="#000000"
                                                      stroke-width="1.5"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    ></path>
                                                  </svg>
                                                </button>

                                                <input
                                                  width="300"
                                                  type="number"
                                                  className="quantity input pro-subheading"
                                                  value={
                                                    selectedProducts[
                                                      `${item.unique_row_identifier}`
                                                    ]?.[`${item.variant_id}`]
                                                      ?.redeem || 0
                                                  }
                                                />

                                                <button
                                                  className="quantity-btn"
                                                  onClick={() =>
                                                    increaseQuantity(
                                                      item.unique_row_identifier,
                                                      item.variant_id,
                                                      objItem[0]?.sellerId,
                                                      objItem[index]
                                                        ?.order_item_id
                                                    )
                                                  }
                                                  disabled={
                                                    selectedProducts[
                                                      `${item.unique_row_identifier}`
                                                    ]?.[`${item.variant_id}`]
                                                      ?.redeem === remainingQty
                                                  }
                                                >
                                                  <svg
                                                    width="20px"
                                                    height="20px"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                  >
                                                    <path
                                                      fill="#000000"
                                                      fill-rule="evenodd"
                                                      d="M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z"
                                                    ></path>
                                                  </svg>
                                                </button>
                                              </div>
                                            </div>
                                            {redeemQtyV.length > 0 &&
                                            redeemQtyV.includes(
                                              item.order_item_id &&
                                                item.seller_id
                                            ) ? (
                                              <div className="redeem-error pro-subheading">
                                                Please select redeem quantity
                                              </div>
                                            ) : null}
                                          </div>
                                        ) : (
                                          0
                                        )}
                                      </td>
                                      <div>
                                        {objItem[index].is_item_expired ===
                                          1 && (
                                          <button
                                            onClick={() =>
                                              handleActive(
                                                objItem[index]?.order_item_id
                                              )
                                            }
                                            className="btn  btn-dark "
                                          >
                                            Reactivate
                                          </button>
                                        )}
                                      </div>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>

                        <div className="">
                          {showBtn && (
                            <>
                              <div className="d-flex align-items-center mt-2 sm-d-block">
                                <h5 className="h6 mr-2">Select an option:</h5>

                                <select
                                  className="btn btn-default btn-dark "
                                  value={
                                    selectedProductOption[
                                      objItem[0].product_id
                                    ] || "default_option"
                                  }
                                  onChange={(e) =>
                                    handleOptionChanges(
                                      e,
                                      objItem[0].sellerId,
                                      objItem[0].product_id,
                                      objItem[0].colMaxCreatedAt
                                    )
                                  }
                                >
                                  <option value="0">Choose an option</option>
                                  {objItem[0].seller_type === "Type I"
                                    ? TypeOneoptions.map((op) => (
                                        <option key={op} value={op.id}>
                                          {op.value}
                                        </option>
                                      ))
                                    : null}
                                  {objItem[0].seller_type === "Type II"
                                    ? TypeTwooptions.map((op) => (
                                        <option key={op} value={op.id}>
                                          {op.value}
                                        </option>
                                      ))
                                    : null}
                                </select>
                              </div>
                            </>
                          )}
                          <br />
                          {/* {&&
                            sellerId.hasOwnProperty(
                              `${objItem[0].sellerId}_${objItem[0].order_id}`
                            )} */}
                          {showBtn &&
                            selectedProductOption[objItem[0].product_id] ===
                              "1" && (
                              <div
                                className="text-left redeem-sec sub-heading"
                                style={{ display: "flex" }}
                              >
                                <div className="text-end sub-heading">
                                  <button
                                    onClick={() => {
                                      handleRedeem(
                                        objItem[0].sellerId,
                                        objItem[0]?.order_item_id,
                                        objItem[0]?.code,
                                        objItem[0]?.product_id
                                      );
                                    }}
                                    className="btn btn-default btn-dark w-20 m-auto px-3 my-2 border-round pro-subheading"
                                    disabled={
                                      selectedOption[objItem[0].sellerId] ===
                                        "1" &&
                                      !sellerId.hasOwnProperty(
                                        `${objItem[0].sellerId}_${objItem[0].order_id}`
                                      ) &&
                                      !numberArray
                                    }
                                  >
                                    Redeem Via QR
                                  </button>
                                </div>
                              </div>
                            )}
                          {/* {selectedOption[objItem[0].sellerId] == "2" ? (
                            <>
                              <div className="radio">
                                <h5>Please select option</h5>
                                <input
                                  type="radio"
                                  value="On Table!"
                                  checked={
                                    additionalInformation[
                                      objItem[0].sellerId
                                    ] == "On Table!"
                                  }
                                  onChange={(e) =>
                                    onValueChange(e, objItem[0].sellerId)
                                  }
                                  style={{ marginRight: "5px" }}
                                />
                                <label style={{ marginRight: "10px" }}>
                                  On Table
                                </label>
                                <input
                                  type="radio"
                                  value="At Address!"
                                  checked={
                                    additionalInformation[
                                      objItem[0].sellerId
                                    ] == "At Address!"
                                  }
                                  onChange={(e) =>
                                    onValueChange(e, objItem[0].sellerId)
                                  }
                                  style={{ marginRight: "5px" }}
                                />
                                <label style={{ marginRight: "10px" }}>
                                  At Address
                                </label>
                              </div>

                              <div className="col-md-12">
                                {additionalInformation[objItem[0].sellerId] ==
                                "On Table!" ? (
                                  <div className="mt-3">
                                    <label htmlFor="tableIdField">
                                      Table ID:
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="tableIdField"
                                      value={tableId[objItem[0].sellerId]}
                                      onChange={(e) =>
                                        handleTableIdChange(
                                          e,
                                          objItem[0].sellerId
                                        )
                                      }
                                    />
                                    {(tableId[objItem[0].sellerId] == "" ||
                                      tableId[objItem[0].sellerId] ==
                                        undefined) &&
                                    submitBtnClick ? (
                                      <div
                                        style={{
                                          color: "red",
                                          textAlign: "centre",
                                          fontSize: "14px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        Please enter table id
                                      </div>
                                    ) : null}
                                    <div className="mt-3">
                                      <label htmlFor="additionalInfoField">
                                        Additional Information:
                                      </label>
                                      <textarea
                                        className="form-control"
                                        id="additionalInfoField"
                                        value={
                                          additionalInfo[objItem[0].sellerId]
                                        }
                                        onChange={(e) =>
                                          handleAdditionalInfoChange(
                                            e,
                                            objItem[0].sellerId
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                {additionalInformation[objItem[0].sellerId] ==
                                "At Address!" ? (
                                  <div className="mt-3">
                                    <label htmlFor="additionalInfoField">
                                      Address:
                                    </label>
                                    <textarea
                                      className="form-control"
                                      id="additionalInfoField"
                                      value={addressInfo[objItem[0].sellerId]}
                                      onChange={(e) =>
                                        handleAddressInfoChange(
                                          e,
                                          objItem[0].sellerId
                                        )
                                      }
                                    />
                                    {(addressInfo[objItem[0].sellerId] == "" ||
                                      addressInfo[objItem[0].sellerId] ==
                                        undefined) &&
                                    submitBtnClick ? (
                                      <div
                                        style={{
                                          color: "red",
                                          textAlign: "centre",
                                          fontSize: "14px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        Please enter address
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                              <button
                                onClick={() =>
                                  handleSubmitClick(
                                    objItem[0].sellerId,
                                    objItem[0].code
                                  )
                                }
                                className="btn btn-default btn-dark w-20 m-auto px-3 my-2"
                                disabled={
                                  selectedOption[objItem[0].sellerId] == "2" &&
                                  !sellerId.includes(objItem[0].sellerId)
                                    ? true
                                    : false
                                }
                              >
                                Submit
                              </button>
                            </>
                          ) : null} */}

                          {showBtn &&
                          selectedProductOption[objItem[0].product_id] ==
                            "3" ? (
                            <>
                              <div>
                                <h6 style={{ color: "#e53935" }}>
                                  * Please select date and time for pickup later
                                </h6>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="pickup-options">
                                    <label>Date:</label>
                                    {console.log(
                                      "selectedMaxDate[objItem[0].sellerId]",
                                      selectedMaxDate[objItem[0].sellerId]
                                    )}
                                    <DatePicker
                                      value={
                                        selectedDate[objItem[0].sellerId] || ""
                                      }
                                      selected={
                                        selectedDate[objItem[0].sellerId] || ""
                                      }
                                      onChange={(e) =>
                                        handleDateChange(e, objItem[0].sellerId)
                                      }
                                      minDate={new Date()} // Disable previous dates starting from today
                                      maxDate={
                                        selectedMaxDate[objItem[0].sellerId] ||
                                        null
                                      }
                                      className="form-control w-100 mb-2 date-picker-reports"
                                    />
                                    {/* })} */}
                                    {(selectedDate[objItem[0].sellerId] == "" ||
                                      selectedDate[objItem[0].sellerId] ==
                                        undefined) &&
                                    submitBtnClick ? (
                                      <div
                                        style={{
                                          color: "red",
                                          textAlign: "centre",
                                          fontSize: "14px",
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Please select date
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="d-block text-start">
                                      Time Slots:
                                    </label>

                                    <div
                                      className="btn-group time-slot-button choose-time mb-2"
                                      style={{ gap: "2px" }}
                                    >
                                      {timeSlot.map((slot) => {
                                        return (
                                          <button
                                            key={slot}
                                            type="button"
                                            className={`btn ${
                                              slot.id ===
                                              selectedTimeSlot[
                                                objItem[0]?.sellerId || ""
                                              ]
                                                ? "btn-dark"
                                                : "btn-outline-primary"
                                            }`}
                                            value={
                                              selectedTimeSlot[
                                                objItem[0]?.sellerId
                                              ] || ""
                                            }
                                            onClick={() =>
                                              handleTimeSlotChange(
                                                objItem[0].sellerId,
                                                slot
                                              )
                                            }
                                          >
                                            {slot?.time_range}
                                          </button>
                                        );
                                      })}
                                    </div>
                                    {(selectedTimeSlot[objItem[0].sellerId] ==
                                      "" ||
                                      selectedTimeSlot[objItem[0].sellerId] ==
                                        undefined) &&
                                    submitBtnClick ? (
                                      <div
                                        style={{
                                          color: "red",
                                          textAlign: "centre",
                                          fontSize: "14px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        Please select time range
                                      </div>
                                    ) : null}
                                  </div>
                                  <button
                                    onClick={() =>
                                      handleSubmitClick(
                                        objItem[0].sellerId,
                                        objItem[0].code,
                                        objItem[0].product_id
                                      )
                                    }
                                    className="btn btn-default btn-dark w-20 m-auto px-3 my-2 mb-50"
                                    disabled={
                                      sellerId.length === 0 &&
                                      selectedOption[objItem[0].sellerId] ===
                                        "3"
                                    }
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : null}

                          {objItem[0]?.image_name ? (
                            <div className="text-end have-qr">
                              <div className="qrcode">
                                <img
                                  onClick={(e) =>
                                    handlePopup(objItem[0]?.image_name)
                                  }
                                  src={
                                    "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
                                    `${objItem[0]?.image_name}`
                                  }
                                  alt="QR Code"
                                />
                                <div className="qrcode-option">
                                  <img
                                    src={editPencil}
                                    alt="Edit pencile"
                                    onClick={() => handleEditManager(objItem)}
                                  />
                                  <img
                                    src={deleteIcon}
                                    alt="Delete pencile"
                                    onClick={() => handleDeleteManager(objItem)}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    </div>
                  );
                })
              );
            })
          );
        })
    );
  }, [
    allSellers,
    handleChangeItems,
    handleRedeem,
    handlePopup,
    selectedQuantity,
    dataObjKey,
    dataObject,
    decreaseQuantity,
    increaseQuantity,
    redeemQtyV,
    handleDeleteManager,
    handleEditManager,
    selectedProducts,
    TypeOneoptions,
    TypeTwooptions,
    handleOptionChanges,
    selectedOption,
    sellerId,
    handleDateChange,
    handleSubmitClick,
    handleTimeSlotChange,
    selectedDate,
    selectedTimeSlot,
    submitBtnClick,
    timeSlot,
    selectedMaxDate,
    selectedProductOption,
    handleActive,
  ]);
  //** popup open of update qty*/
  const handleOpen = (params, data) => {
    setOpen(true);
    setEditData(data);
    setEditSellerData(params);
  };
  //** popup close of update qty*/
  const handleClose = () => {
    dispatch(showLoading());
    SelectedEditProduct.current = [];
    EditRedeemQty.current = [];
    dataEditSellerId.current = [];
    setOpen(false);
    window.location.reload();
  };
  //** when update check box click */
  const handleCheckboxChange = useCallback(
    (e, item) => {
      handleChangeEditItems(e, item);
    },
    [handleChangeEditItems]
  );
  //** increase update qty */
  const increaseUpdateQuantity = useCallback(
    (productId, itemVarientId, seller_Id) => {
      let latestData = dataEditSellerId.current[seller_Id] || [];
      console.log(
        { productId, itemVarientId, seller_Id, latestData },
        dataEditSellerId.current
      );
      const variantIndex = latestData.findIndex(
        (obj) => obj.variant_id == itemVarientId
      );
      console.log("variantIndex", variantIndex);
      if (variantIndex == -1) {
        showErrorMessage("Please select checkbox first.");
        return;
      }
      let redeemQty =
        SelectedEditProduct.current[`${productId}`]?.[`${itemVarientId}`]
          ?.redeem || 0;
      SelectedEditProduct.current = {
        ...SelectedEditProduct.current,
        [`${productId}`]: {
          ...SelectedEditProduct.current[`${productId}`],
          [`${itemVarientId}`]: {
            redeem: redeemQty + 1,
          },
        },
      };

      if (variantIndex !== -1) {
        dataEditSellerId.current[seller_Id][variantIndex].redeem =
          parseInt(redeemQty) + 1;
      }
      if (
        parseInt(redeemQty) + 1 > 0 &&
        EditRedeemQty.current.includes(itemVarientId)
      ) {
        let selectedItemIndex = EditRedeemQty.current.indexOf(itemVarientId);
        if (selectedItemIndex > -1) {
          EditRedeemQty.current.splice(selectedItemIndex, 1);
        }
      }
      forceUpdate();
    },
    [SelectedEditProduct, EditRedeemQty]
  );
  //** decrease update qty */
  const decreaseUpdateQuantity = useCallback(
    (productId, itemVarientId, seller_Id) => {
      let latestData = dataEditSellerId.current[seller_Id] || [];
      const variantIndex = latestData.findIndex(
        (obj) => obj.variant_id == itemVarientId
      );
      if (variantIndex == -1) {
        showErrorMessage("Please select checkbox first.");
        return;
      }
      let redeemQty =
        SelectedEditProduct.current[`${productId}`]?.[`${itemVarientId}`]
          ?.redeem || 0;
      SelectedEditProduct.current = {
        ...SelectedEditProduct.current,
        [`${productId}`]: {
          ...SelectedEditProduct.current[`${productId}`],
          [`${itemVarientId}`]: {
            redeem: redeemQty - 1,
          },
        },
      };
      if (variantIndex !== -1) {
        dataEditSellerId.current[seller_Id][variantIndex].redeem =
          parseInt(redeemQty) - 1;
      }
      if (
        parseInt(redeemQty) - 1 <= 0 &&
        !EditRedeemQty.current.includes(itemVarientId)
      ) {
        EditRedeemQty.current.push(itemVarientId);
      }
      forceUpdate();
      // console.log(
      //   "dataEditObject decrease",
      //   dataEditSellerId.current,
      //   EditRedeemQty.current,
      //   SelectedEditProduct.current
      // );
    },
    [SelectedEditProduct, EditRedeemQty]
  );
  //** render update popup model */
  const RenderModalEditPopup = useCallback(() => {
    return (
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="table-responsive pro-subheading">
            <table className="table havelisttable pro-subheading main-box">
              <tbody className="pro-subheading ">
                <tr
                  className="pro-subheading "
                  style={{
                    textAlign: "left",
                  }}
                >
                  <th width="10%"></th>
                  <th width="20%">Size</th>
                  <th width="20%">Quantity</th>
                  <th width="20%">Redeem</th>
                </tr>
                {editProductData.map((item, index) => {
                  let remainingQty = item?.available_to_redeem_seller;
                  return (
                    <>
                      <tr
                        key={item.product_id}
                        style={{
                          textAlign: "left",
                        }}
                        className="pro-subheading"
                      >
                        <td>
                          <CheckboxInput
                            key={item.unique_row_identifier} // Ensure a stable key
                            item={item}
                            selectedQuantity={selectedQuantity} // Pass selectedQuantity as a prop
                            onEditItemsChange={handleCheckboxChange}
                          />
                        </td>

                        <td className="pro-subheading">
                          {item.weight && item?.packing_unit ? (
                            <>
                              {" "}
                              {item.weight}
                              &nbsp;{item.packing_unit}
                            </>
                          ) : item.weight ? (
                            <> {item.weight}</>
                          ) : item.packing_unit ? (
                            <> &nbsp;{item.packing_unit}</>
                          ) : (
                            "_"
                          )}
                        </td>

                        <td className="pro-subheading">
                          {item.is_requested_in_bunch == 1 ? (
                            <span>
                              {item.orderedQuantity} ({item.available_qty})
                            </span>
                          ) : (
                            <span>{item.orderedQuantity}</span>
                          )}
                        </td>
                        <td className="pro-subheading">
                          {remainingQty > 0 ? (
                            <div className="quantity">
                              <div className="number ">
                                <div className="number-quantity">
                                  <button
                                    className="quantity-btn"
                                    onClick={() =>
                                      decreaseUpdateQuantity(
                                        item.unique_row_identifier,
                                        item.variant_id,
                                        editProductData[0]?.sellerId,
                                        editProductData[index]?.order_item_id
                                      )
                                    }
                                    disabled={
                                      SelectedEditProduct.current[
                                        `${item.unique_row_identifier}`
                                      ]?.[`${item.variant_id}`]?.redeem ===
                                        undefined ||
                                      SelectedEditProduct.current[
                                        `${item.unique_row_identifier}`
                                      ]?.[`${item.variant_id}`]?.redeem === 0
                                    }
                                  >
                                    <svg
                                      width="20px"
                                      height="20px"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4 12H20"
                                        stroke="#000000"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </svg>
                                  </button>

                                  <input
                                    width="300"
                                    type="number"
                                    className="quantity input pro-subheading"
                                    value={
                                      SelectedEditProduct.current[
                                        `${item.unique_row_identifier}`
                                      ]?.[`${item.variant_id}`]?.redeem || 0
                                    }
                                    readOnly
                                  />

                                  <button
                                    className="quantity-btn"
                                    onClick={() =>
                                      increaseUpdateQuantity(
                                        item.unique_row_identifier,
                                        item.variant_id,
                                        editProductData[0]?.sellerId,
                                        editProductData[index]?.order_item_id
                                      )
                                    }
                                    disabled={
                                      SelectedEditProduct.current[
                                        `${item.unique_row_identifier}`
                                      ]?.[`${item.variant_id}`]?.redeem ===
                                      remainingQty
                                    }
                                  >
                                    <svg
                                      width="20px"
                                      height="20px"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                    >
                                      <path
                                        fill="#000000"
                                        fill-rule="evenodd"
                                        d="M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              {EditRedeemQty.current.length > 0 &&
                              EditRedeemQty.current.includes(
                                item.order_item_id && item.seller_id
                              ) ? (
                                <div className="redeem-error pro-subheading">
                                  Please select redeem quantity
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>

            <div className="text-end sub-heading" style={{ gap: "10px" }}>
              <button
                onClick={handleClose}
                className="btn btn-default btn-dark w-20 m-auto px-3 my-2 border-round pro-subheading mx-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleUpdateQty(
                    editProductData[0].buyer_id,
                    editProductData[0].sellerId,
                    editProductData[0]?.order_item_id,
                    editProductData[0]?.redemption_code
                  );
                }}
                className="btn btn-default btn-dark w-20 m-auto px-3 my-2 border-round pro-subheading"
              >
                Update
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }, [
    decreaseUpdateQuantity,
    editProductData,
    handleCheckboxChange,
    handleUpdateQty,
    increaseUpdateQuantity,
    open,
    selectedQuantity,
  ]);
  //** main return */
  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <LoggedInHeader>
          <div className="body-container d-flex">
            <SideNavigation />
            <div className="right-panel">
              <div className="styleBtn">
                <button className="back-btn btn-dark w-40" onClick={handleBack}>
                  <i class="fa fa-arrow-circle-left" aria-hidden="true">
                    {" "}
                  </i>
                </button>
              </div>
              <br />
              <br />
              <h2>
                <strong>{SellersName}</strong>
              </h2>

              <div className="redeem-history">{renderAllObject()}</div>
            </div>
          </div>
          <RenderModalEditPopup />
          <Footer />
        </LoggedInHeader>
      )}
    </>
  );
};

export default OrderListPage;
