import React, { useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";
import SideNavigation from "../../components/SideNavigation";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";

export default function BuyerQRCode() {
  // const location = useLocation();
  // const imageName = localStorage.getItem("sellerQr_image_name");

  useEffect(() => {
    document.title = "Paysah | Buyer_QRCode";
  });

  return (
    <>
      <LoggedInHeader>
        <div className="body-container d-flex">
          <SideNavigation />
          <div className="right-panel qrcode-container">
            <div className="register-form">
              <div className="text-center mt-5">
                <h4>Generated Code</h4>
              </div>
              <div className="text-center">
                {" "}
                <img
                  className="d-block mx-auto w-100 mt-2"
                  src="https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/kuNSdSMGrX-1.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </LoggedInHeader>
    </>
  );
}
