import React, { useEffect, useState } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import SideNavigation from "../components/SideNavigation";
import {
  haveListOrderDetails,
  redeemOrder,
  ReactivateOrderItemNew,
} from "../api/request";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import LoggedInHeader from "../components/LoggedInHeader";
// import { showErrorMessage, showInformation } from "../constants/Toast";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

export default function HaveListdetails() {
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.loader);
  const [cartItems, setCartItems] = useState({});
  const [selectedQuantity, setSelectQuantity] = useState({});
  const [dataObject, setDataObject] = useState([]);
  const [code, setCode] = useState();
  const [apiResponse, setApiResponse] = useState();
  const [detailRedeemedOrderItems, setDetailRedeemedOrderItems] = useState([]);
  const [sellerId, setSellerId] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [redeemQtyV, setRedeemQtyV] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    document.title = "Paysah | HaveList_OrderDetails";
    const fetchData = async () => {
      let viewRedeemHistory = [];
      var haveListItemId = localStorage.getItem("haveListId");
      let data = {
        id: haveListItemId,
      };

      try {
        let result = await haveListOrderDetails(data);
        if (result?.success) {
          if (result?.data) {
            setCartItems(result?.data?.items);
            setApiResponse(result?.data);
            setCode(result?.data?.code);
            localStorage.setItem("code", result?.data?.code);
            localStorage.setItem(
              "sellerQr_image_name",
              result?.data?.image_name
            );
            const viewRedeemKeys = Object.keys(result?.data?.redeem_history);
            viewRedeemKeys.map((key) => {
              const viewsellerRedeemData = result?.data?.redeem_history[key];
              viewRedeemHistory.push(viewsellerRedeemData);
            });
            setDetailRedeemedOrderItems(viewRedeemHistory);
          }
        } else {
          console.log("error there!");
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (localStorage.getItem("haveListId")) {
      fetchData();
    }
  }, []);

  const increaseQuantity = (productId, itemVarientId, seller_Id) => {
    let latestData = dataObject[seller_Id] || [];
    let redeemQty =
      selectedProducts[`${productId}`]?.[`${itemVarientId}`]?.redeem || 0;
    setSelectedProducts({
      ...selectedProducts,
      [`${productId}`]: {
        ...selectedProducts[`${productId}`],
        [`${itemVarientId}`]: {
          redeem: redeemQty + 1,
        },
      },
    });

    const variantIndex = latestData.findIndex(
      (obj) => obj.variant_id == itemVarientId
    );
    if (variantIndex !== -1) {
      dataObject[seller_Id][variantIndex].redeem = parseInt(redeemQty) + 1;
    }
    if (parseInt(redeemQty) + 1 > 0 && redeemQtyV.includes(itemVarientId)) {
      let selectedItemIndex = redeemQtyV.indexOf(itemVarientId);
      if (selectedItemIndex > -1) {
        redeemQtyV.splice(selectedItemIndex, 1);
        setRedeemQtyV([...new Set(redeemQtyV)]);
      }
    }
  };

  const decreaseQuantity = (productId, itemVarientId, seller_Id) => {
    let latestData = dataObject[seller_Id] || [];
    let redeemQty =
      selectedProducts[`${productId}`]?.[`${itemVarientId}`]?.redeem || 0;
    setSelectedProducts({
      ...selectedProducts,
      [`${productId}`]: {
        ...selectedProducts[`${productId}`],
        [`${itemVarientId}`]: {
          redeem: redeemQty - 1,
        },
      },
    });

    const variantIndex = latestData.findIndex(
      (obj) => obj.variant_id == itemVarientId
    );
    if (variantIndex !== -1) {
      dataObject[seller_Id][variantIndex].redeem = parseInt(redeemQty) - 1;
    }
    if (parseInt(redeemQty) - 1 <= 0 && !redeemQtyV.includes(itemVarientId)) {
      redeemQtyV.push(itemVarientId);
      setRedeemQtyV([...new Set(redeemQtyV)]);
    }
  };

  const handleChangeItems = (
    e,
    seller_Id,
    product_id,
    variant_id,
    supplier_id
  ) => {
    const { name, checked } = e.target;
    let dt_redeem = selectedProducts[e.target.dataset.productId]?.[
      e.target.dataset.variantId
    ]?.redeem
      ? selectedProducts[e.target.dataset.productId]?.[
          e.target.dataset.variantId
        ]?.redeem
      : 0;

    let obj = {
      supplier_id: e.target.dataset.supplierId,
      product_id: e.target.dataset.productId,
      variant_id: e.target.dataset.variantId,
      total_quantity: e.target.dataset.totalQuantity,
      delivered_quantity: e.target.dataset.deliveredQuantity,
      item_price: e.target.dataset.price,
      redeem: selectedProducts[e.target.dataset.productId]?.[
        e.target.dataset.variantId
      ]?.redeem
        ? selectedProducts[e.target.dataset.productId]?.[
            e.target.dataset.variantId
          ]?.redeem
        : 0,
    };
    var mainExistingData = dataObject[seller_Id] || [];
    if (checked) {
      sellerId.push(seller_Id);
      setSellerId([...new Set(sellerId)]);
      setDataObject({ ...dataObject, [seller_Id]: [...mainExistingData, obj] });
      if (dt_redeem == 0 && !redeemQtyV.includes(variant_id)) {
        redeemQtyV.push(variant_id);
        setRedeemQtyV([...new Set(redeemQtyV)]);
      }
    } else {
      sellerId.pop(seller_Id);
      setSellerId([...new Set(sellerId)]);
      var removeExistingData = mainExistingData.splice(supplier_id, 1);
      setDataObject(removeExistingData);
    }
  };

  const keys = Object.keys(cartItems);
  let allSellers = [];
  keys.map((key) => {
    const sellerData = cartItems[key];
    allSellers.push(sellerData);
  });

  const handleRedeem = async (sId) => {
    setDetailRedeemedOrderItems();
    let redeemHistory = [];
    let payload = dataObject[sId] || "";
    let updatedData = [];

    updatedData = payload.filter((item) => item.supplier_id !== sId);

    let user_details = JSON.parse(localStorage.getItem("user_details"));
    let data = {
      user_id: user_details.user_id,
      seller_id: sId,
      code: code,
      order_items: updatedData,
    };
    let result = await redeemOrder(data);
    try {
      if (result?.success) {
        const redeemKeys = Object.keys(result?.data?.redeem_history);
        let lastIndexKey = redeemKeys[redeemKeys.length - 1];
        redeemKeys.map((key) => {
          const sellerRedeemData = result?.data?.redeem_history[key];

          redeemHistory.push(sellerRedeemData);
        });
        if (redeemHistory.length > 0) {
          setDetailRedeemedOrderItems(redeemHistory);

          Swal.fire({
            imageUrl:
              "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
              `${result?.data?.image_name_new_generated}`,
            imageWidth: 700,
            imageHeight: 400,
            imageAlt: "Custom image",
            confirmButtonText: "Ok",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          setDetailRedeemedOrderItems(detailRedeemedOrderItems);
        }
      } else {
        console.log("error there!");
        setError(result?.data);
        setShow(true);
        // showErrorMessage("Invalid redeem quantity for product(s)!");
        // //  window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvoice = (id) => {
    window.open(
      "https://atlasbackend.24livehost.com/uploads/invoice_pdfs/" + `${id}.pdf`,
      "_blank"
    );
  };

  const handlePopup = (imgName) => {
    Swal.fire({
      imageUrl:
        "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
        `${imgName}`,
      imageWidth: 700,
      imageHeight: 400,
      imageAlt: "Custom image",
      confirmButtonText: "Cancel",
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleOkayBtn = () => {
    window.location.reload();
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />
              <div className="right-panel">
                {apiResponse && (
                  <div className="page-title">
                    <h2>
                      Order Id: <span>#{apiResponse?.orderId}</span>
                    </h2>
                    <p>
                      <strong>Order Date:</strong>{" "}
                      <span>
                        {moment(apiResponse.orderCreatedDate).format(
                          "MMMM DD-YYYY hh:mm A"
                        )}
                      </span>
                    </p>
                  </div>
                )}
                {allSellers?.length > 0
                  ? allSellers.map((sellerProducts) => {
                      return (
                        <div key={sellerProducts[0].sellerId}>
                          <h3>
                            <strong>{sellerProducts[0].sellerName}</strong>
                          </h3>
                          <div className="row">
                            <div className="mb-3">
                              {allSellers && allSellers?.length > 0 ? (
                                <div className="card shadow-sm">
                                  <div className="card-body">
                                    <div className="table-responsive">
                                      <table className="table">
                                        <tbody>
                                          <tr style={{ textAlign: "center" }}>
                                            <th></th>
                                            <th>Item Name</th>
                                            <th>Size</th>
                                            <th>Price</th>
                                            <th>Ordered QTY</th>
                                            <th>Remaining QTY</th>
                                            <th>Redeem</th>
                                          </tr>
                                          {sellerProducts.map((item) => {
                                            let remainingQty =
                                              item?.available_to_redeem_seller;
                                            return (
                                              <tr
                                                style={{ textAlign: "center" }}
                                              >
                                                <td>
                                                  {remainingQty > 0 ? (
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        data-size-id="1"
                                                        data-order-item-id={
                                                          item.order_item_id
                                                        }
                                                        data-supplier-id={
                                                          item.sellerId
                                                        }
                                                        data-product-id={
                                                          item.product_id
                                                        }
                                                        data-variant-id={
                                                          item.variant_id
                                                        }
                                                        data-total-quantity={
                                                          item.orderedQuantity
                                                        }
                                                        data-delivered-quantity={
                                                          item.delivered_quantity
                                                        }
                                                        data-price={
                                                          item.item_price
                                                        }
                                                        data-redeem={
                                                          selectedQuantity[
                                                            item.redeem
                                                          ]
                                                        }
                                                        onChange={(e, id) =>
                                                          handleChangeItems(
                                                            e,

                                                            item.sellerId,
                                                            item.product_id,
                                                            item.variant_id
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  ) : null}
                                                </td>

                                                <td className="product-name">
                                                  {item.productName}
                                                </td>

                                                <td>
                                                  {item.weight &&
                                                  item?.weightUnit ? (
                                                    <>
                                                      {" "}
                                                      {item.weight}
                                                      {item.weightUnit}
                                                    </>
                                                  ) : item.weight ? (
                                                    <> {item.weight}</>
                                                  ) : item.weightUnit ? (
                                                    <> {item.weightUnit}</>
                                                  ) : (
                                                    "_"
                                                  )}
                                                </td>
                                                {item.item_price ? (
                                                  <>
                                                    {" "}
                                                    <td>£{item.item_price}</td>
                                                  </>
                                                ) : (
                                                  <td>_</td>
                                                )}
                                                <td>{item.orderedQuantity}</td>
                                                <td>
                                                  {
                                                    item.available_to_redeem_seller
                                                  }
                                                </td>
                                                <td>
                                                  {remainingQty > 0 ? (
                                                    <div className="quantity">
                                                      <div className="number ">
                                                        <div className="number-quantity">
                                                          <button
                                                            className="quantity-btn"
                                                            onClick={() =>
                                                              decreaseQuantity(
                                                                item.product_id,
                                                                item.variant_id,
                                                                item.sellerId
                                                              )
                                                            }
                                                            disabled={
                                                              selectedProducts[
                                                                `${item.product_id}`
                                                              ]?.[
                                                                `${item.variant_id}`
                                                              ]?.redeem ==
                                                                undefined ||
                                                              selectedProducts[
                                                                `${item.product_id}`
                                                              ]?.[
                                                                `${item.variant_id}`
                                                              ]?.redeem == 0
                                                                ? true
                                                                : false
                                                            }
                                                          >
                                                            <svg
                                                              width="24"
                                                              height="24"
                                                              viewBox="0 0 24 24"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM7 13H17V11H7"
                                                                fill="black"
                                                              />
                                                            </svg>
                                                          </button>

                                                          <input
                                                            width="300"
                                                            type="number"
                                                            className="quantity input"
                                                            value={
                                                              selectedProducts[
                                                                `${item.product_id}`
                                                              ]?.[
                                                                `${item.variant_id}`
                                                              ]?.redeem || 0
                                                            }
                                                          />

                                                          <button
                                                            className="quantity-btn"
                                                            onClick={() =>
                                                              increaseQuantity(
                                                                item.product_id,
                                                                item.variant_id,
                                                                item.sellerId
                                                              )
                                                            }
                                                            disabled={
                                                              selectedProducts[
                                                                `${item.product_id}`
                                                              ]?.[
                                                                `${item.variant_id}`
                                                              ]?.redeem ==
                                                              remainingQty
                                                                ? true
                                                                : false
                                                            }
                                                          >
                                                            <svg
                                                              width="24"
                                                              height="24"
                                                              viewBox="0 0 24 24"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M17 13H13V17H11V13H7V11H11V7H13V11H17M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
                                                                fill="black"
                                                              />
                                                            </svg>
                                                          </button>
                                                        </div>
                                                      </div>
                                                      {redeemQtyV.length > 0 &&
                                                      redeemQtyV.includes(
                                                        item.variant_id
                                                      ) ? (
                                                        <div
                                                          style={{
                                                            color: "red",
                                                            textAlign: "centre",
                                                            fontSize: "14px",
                                                            marginTop: "10px",
                                                          }}
                                                        >
                                                          Please select redeem
                                                          quatity
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  ) : (
                                                    0
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                      <div className="text-center">
                                        <button
                                          onClick={() => {
                                            handleRedeem(
                                              sellerProducts[0].sellerId
                                            );
                                          }}
                                          className="btn btn-default btn-dark w-20 m-auto px-3 my-2"
                                          disabled={
                                            !sellerId.includes(
                                              sellerProducts[0].sellerId
                                            )
                                              ? true
                                              : false
                                          }
                                        >
                                          Redeem Via QR
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <h4>Order Items not found</h4>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
                {detailRedeemedOrderItems?.length > 0 ? (
                  <>
                    {" "}
                    <div className="redeem-history">
                      <h3>
                        <strong className="pro-subheading">
                          Redeem History
                        </strong>
                      </h3>
                      {detailRedeemedOrderItems.length > 0
                        ? detailRedeemedOrderItems?.map((redeemItems) => {
                            return (
                              <>
                                <div className="tableStyle">
                                  <div className="row align-items-center mb-3">
                                    <div className="col-md-8">
                                      <h4>
                                        <strong>
                                          {
                                            redeemItems?.bunch_data[0]
                                              ?.seller_name
                                          }{" "}
                                        </strong>{" "}
                                      </h4>
                                    </div>
                                    <div className="col-md-3 text-end">
                                      {redeemItems?.qrdata
                                        ?.is_invoice_generated == 2 ? (
                                        <button
                                          onClick={() =>
                                            handleInvoice(
                                              redeemItems?.bunch_data[0]
                                                ?.bunch_id
                                            )
                                          }
                                          className="btn btn-default btn-dark w-20 m-auto px-3 my-2"
                                          // disabled={
                                          //   redeemItems?.qrdata
                                          //     ?.is_invoice_generated == 1
                                          //     ? true
                                          //     : false
                                          // }
                                        >
                                          Show invoice
                                        </button>
                                      ) : null}
                                    </div>
                                    <div className="col-md-1 text-end">
                                      <div className="qrcode">
                                        {redeemItems?.qrdata ? (
                                          <img
                                            onClick={(e) =>
                                              handlePopup(
                                                redeemItems?.qrdata?.image_name
                                              )
                                            }
                                            src={
                                              "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
                                              `${redeemItems?.qrdata?.image_name}`
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                    {redeemItems?.qrdata
                                      .isBunchPartiallyRedeemed ||
                                    redeemItems?.qrdata.is_delete ? (
                                      <div>
                                        <span>
                                          {redeemItems?.qrdata.is_delete ==
                                          2 ? (
                                            <>
                                              {" "}
                                              <strong>
                                                Product/Bunch status :{" "}
                                              </strong>{" "}
                                              Deleted{" "}
                                            </>
                                          ) : null}
                                          {redeemItems?.qrdata
                                            .isBunchPartiallyRedeemed == 1 ? (
                                            <>
                                              {" "}
                                              <strong>
                                                Product/Bunch status :{" "}
                                              </strong>{" "}
                                              Not Redeemed{" "}
                                            </>
                                          ) : null}
                                          {redeemItems?.qrdata
                                            .isBunchPartiallyRedeemed == 2 ? (
                                            <>
                                              <strong>
                                                Product/Bunch status :{" "}
                                              </strong>
                                              Partially Redeemed{" "}
                                            </>
                                          ) : null}
                                          {redeemItems?.qrdata
                                            .isBunchPartiallyRedeemed == 3 ? (
                                            <>
                                              {" "}
                                              <strong>
                                                Product/Bunch status :
                                              </strong>{" "}
                                              Redeemed{" "}
                                            </>
                                          ) : null}
                                        </span>
                                        <br />
                                      </div>
                                    ) : null}
                                    <span>
                                      <strong>Redeem Request Date :</strong>{" "}
                                      {moment(
                                        redeemItems?.qrdata.redeem_date
                                      ).format("MMMM DD-YYYY hh:mm A")}
                                    </span>
                                  </div>
                                  <div className="row">
                                    <div>
                                      <div className="card">
                                        <div className="card-body">
                                          <div className="table-responsive">
                                            <table className="table">
                                              <tbody>
                                                <tr
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <th>Item Name</th>
                                                  <th>Size</th>
                                                  {redeemItems?.qrdata
                                                    .isBunchPartiallyRedeemed ==
                                                    2 ||
                                                  redeemItems?.qrdata
                                                    .isBunchPartiallyRedeemed ==
                                                    3 ? (
                                                    <th>
                                                      Redeem By Seller Date
                                                    </th>
                                                  ) : null}

                                                  {redeemItems?.qrdata
                                                    .isBunchPartiallyRedeemed ==
                                                    2 ||
                                                  redeemItems?.qrdata
                                                    .isBunchPartiallyRedeemed ==
                                                    3 ? (
                                                    <th>Redeemed</th>
                                                  ) : (
                                                    <th>Redeem To Be</th>
                                                  )}
                                                  <th>Redeemed By Seller</th>
                                                  <th>Sub-total</th>
                                                </tr>
                                                {redeemItems?.bunch_data?.map(
                                                  (item) => {
                                                    return (
                                                      <tr
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <td>
                                                          {item.item_name}
                                                        </td>
                                                        <td>
                                                          {item.weight &&
                                                          item?.packing_unit ? (
                                                            <>
                                                              {" "}
                                                              {item.weight}
                                                              {" " +
                                                                item.packing_unit}
                                                            </>
                                                          ) : item.weight ? (
                                                            <> {item.weight}</>
                                                          ) : item.packing_unit ? (
                                                            <>
                                                              {" "}
                                                              {" " +
                                                                item.packing_unit}
                                                            </>
                                                          ) : (
                                                            "_"
                                                          )}
                                                        </td>

                                                        {redeemItems?.qrdata
                                                          .isBunchPartiallyRedeemed ==
                                                          2 ||
                                                        redeemItems?.qrdata
                                                          .isBunchPartiallyRedeemed ==
                                                          3 ? (
                                                          <td>
                                                            {item?.redeemed_by_seller !=
                                                            null
                                                              ? moment(
                                                                  item?.redeemed_by_seller
                                                                ).format(
                                                                  "MMMM DD-YYYY hh:mm A"
                                                                )
                                                              : "_"}
                                                          </td>
                                                        ) : null}

                                                        <td>
                                                          {
                                                            item.recent_delivered_quantity
                                                          }
                                                        </td>
                                                        <td>
                                                          {
                                                            item.actual_redeemed_qty
                                                          }
                                                        </td>
                                                        <td>
                                                          £{item.billing_amount}
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : null}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </LoggedInHeader>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>PaySah</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p
                style={{
                  color: "red",
                }}
              >
                {error}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleOkayBtn}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer />
        </>
      )}
    </>
  );
}
