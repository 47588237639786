import React from "react";
import { useNavigate } from "react-router-dom";

export default function Forbidden() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page-wrap d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <span className="display-1 mt-5 d-block">403</span>
              <div className="mb-4 lead">
                You don't have permission to access this page.
              </div>
              <button
                type="submit"
                className="btn btn-dark w-100"
                onClick={() => {
                  navigate("/");
                }}
              >
                Back to Home Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
