import { createSlice } from "@reduxjs/toolkit";

const orderHistoryData = createSlice({
  name: "orderHistoryDetails",
  initialState: {
    orderHistoryData: {},
  },
  reducers: {
    storeOrderHistoryData: (state, action) => {
      state.orderHistoryData = action.payload;
    },
  },
});

export const { storeOrderHistoryData } = orderHistoryData.actions;
export default orderHistoryData.reducer;
