import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Import eye and eye-slash icons from react-icons
import { Logo } from "../../constants/StaticFiles";
import { UpdateFrontUserResetPassword } from "../../api/request";
import Loader from "../../components/Loader";
import { showSuccessMessage, showErrorMessage } from "../../constants/Toast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

// import { getValue } from "@testing-library/user-event/dist/utils";

function ResetPassword() {
  const { isLoading } = useSelector((state) => state.loader);
  const [userToken, setUserToken] = useState(null);
  const { token } = useParams();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const navigation = useNavigate();
  let location = useLocation();

  //   useEffect(() => {
  //     document.title = 'Paysah | ResetPassword';
  //     let token =
  //       location.pathname && location.pathname.split("/").slice(-1)?.[0];
  //     setUserToken(token);
  //   });

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const submitForm = async (data) => {
    toast.dismiss();
    try {
      const params = {
        token,
        ...data,
      };
      let res = await UpdateFrontUserResetPassword(params);

      if (res.success === true) {
        showSuccessMessage("The password reset successfully.");
        navigation("/login");

      }
      //  else if (res.success === false) {
      //   showErrorMessage(res.success?.message);
      // }
      else if (res.success == false) {
          showErrorMessage(res?.data);
        }
      
      //     navigation("/login");
      //   } else {
      //     showErrorMessage(
      //       res?.data
      //     );
      //     navigation("/signup");
      //   }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  return (
    <>
      {/* <div className="top-action-bar">
        <div className="container">
          <Header onclickSection={() => navigation("/")} />
        </div>
      </div> */}
      <div className="container">
        <div className="px-4 py-5 my-5 text-center">
          <Link to="/">
            {" "}
            <img className="d-block mx-auto heading-logo" src={Logo} alt="" />
          </Link>
          <div className="register-form">
            <form
              noValidate
              autoComplete="on"
              onSubmit={handleSubmit(submitForm)}
            >
              <div className="section-heading">
                <h1>Reset Password</h1>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12" style={{ display: "flex" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Password is required",
                      },
                      pattern: {
                        value: /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}.*$/,
                        message:
                          "Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number and 1 special character(!@#$%^&*)",
                      },
                    })}
                  />
                  <button type="button" onClick={handleTogglePassword}>
                    {showPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </button>
                </div>
                <div
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  {errors.password?.message}
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-12" style={{ display: "flex" }}>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Confirm Password"
                    name="password_confirmation"
                    {...register("password_confirmation", {
                      required: "Confirm Password is required", // Add required message
                      validate: (val) => {
                        if (watch("password") != val) {
                          return "Your passwords do no match";
                        }
                      },
                    })}
                  />
                  <button type="button" onClick={handleToggleConfirmPassword}>
                    {showConfirmPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </button>
                </div>
                <div
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  {errors.password_confirmation?.message}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <button
                    className="btn btn-dark w-100"
                    type="submit"
                    onSubmit={(e) => e.preventDefault()}
                    disabled={!isValid || isLoading}
                  >
                    {isLoading ? <Loader /> : null}
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResetPassword;
