import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SideNavigation from "../SideNavigation";
import Footer from "../Footer";
import LoggedInHeader from "../LoggedInHeader";
import Loader from "../Loader";
import { ViewNotificationsSeller } from "../../api/request";
import moment from "moment";

const SellerNotification = () => {
  const { isLoading } = useSelector((state) => state.loader);
  const [details, setDetails] = useState([]);
  const [foundUsers, setFoundUsers] = useState({});
  const [perPageData] = useState(10);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [prevPage, setPrevPage] = useState(null);
  useEffect(() => {
    document.title = "Paysah | HaveListListing";
    const getNotificatioDetails = async () => {
      let userInfo = localStorage.getItem("user_details");
      let userData = JSON.parse(userInfo);
      let user_id = userData?.user_id;
      let data = {
        user_id: user_id,
        page_limit: perPageData,
        page: page,
      };
      try {
        let result = await ViewNotificationsSeller(data);
        if (result?.success) {
          setDetails(result?.data);
          setFoundUsers(result?.data);
          setPrevPage(result?.data?.prev_page_url);
          setNextPage(result?.data?.next_page_url);
        } else {
          console.log("error there!");
        }
      } catch (error) {
        console.log(error);
      }
    };

    getNotificatioDetails();
  }, [page, perPageData]);

  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />

              <div className="right-panel">
                <h2>
                  <strong className="pro-subheading">Notifications</strong>
                </h2>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card shadow-sm">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table-borderless order-table">
                            <thead>
                              <tr>
                                <th className="OrderId">Title</th>
                                <th>Created Date & Time</th>
                                <th>Message</th>
                              </tr>
                            </thead>
                            <tbody>
                              {details?.data?.map((curElem, id) => {
                                return (
                                  <tr>
                                    <td>
                                      {curElem.order_id
                                        ? `# ${curElem.order_id}`
                                        : null}{" "}
                                      {curElem.title || ""}
                                    </td>

                                    <td>
                                      {moment(curElem.created_at || "").format(
                                        "MMMM DD-YYYY hh:mm A"
                                      )}
                                    </td>
                                    <td>{curElem.description || ""}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(nextPage || prevPage) &&
                  foundUsers &&
                  foundUsers?.data?.length > 0 ? (
                    <div className="StylePagination">
                      <button
                        disabled={prevPage == null ? true : false}
                        onClick={() => {
                          setPage(parseInt(prevPage?.split("=")[1]));
                        }}
                        className="previous text-white bg-dark border-dark"
                      >
                        &laquo; Previous
                      </button>
                      <button
                        disabled={nextPage == null ? true : false}
                        className="next text-white bg-dark border-dark"
                        onClick={() => {
                          setPage(parseInt(nextPage?.split("=")[1]));
                        }}
                      >
                        Next &raquo;
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <Footer />
          </LoggedInHeader>
        </>
      )}
    </>
  );
};

export default SellerNotification;
