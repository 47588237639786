import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import SideNavigation from "../../components/SideNavigation";
import Button from "react-bootstrap/Button";
import {
  viewQrCodeInformation,
  redeemOrderItemQuantitySeller,
  CancelOrderSeller,
  proceedOrder,
  Invoice,
} from "../../api/request";
import Loader from "../../components/Loader";
import { showErrorMessage, showSuccessMessage } from "../../constants/Toast";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";
import Swal from "sweetalert2";
import moment from "moment";
export default function SellerDetails() {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const { isLoading } = useSelector((state) => state.loader);
  const [bunchDataLatest, setBunchDataLatest] = useState([]);
  const [dataObject, setDataObject] = useState([]);
  const [detailRedeemedOrderItems, setDetailRedeemedOrderItems] = useState([]);
  const [viewQrCodeInformations, setViewQrCodeInformations] = useState([]);
  const [redeemsHistory, setRedeemsHistory] = useState([]);
  const [bunchId, setBunchId] = useState();
  const [buyerId, setbuyerId] = useState();
  const [selectedProducts, setSelectedProducts] = useState({});
  const [sellerId, setSellerId] = useState([]);
  const [redeemQtyV, setRedeemQtyV] = useState([]);
  const [errorData, setErrorData] = useState("");
  let allDeatils = [];
  useEffect(() => {
    if (redeemsHistory.length == 0) {
      setRedeemsHistory(bunchDataLatest);
    }
  }, [redeemsHistory, bunchDataLatest]);

  useEffect(() => {
    let pageTitle =
      localStorage.getItem("user_role") === "Buyer"
        ? "BuyerDetails"
        : "SellerDetails";

    document.title = `Paysah | ${pageTitle}`;
    getWantListItems();
  }, []);
  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };
  const getWantListItems = async () => {
    var code = localStorage.getItem("sellerData");
    let redeemHistory = [];
    localStorage.setItem("qrcode", code);
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    try {
      if (code) {
        let data = {
          code: code,
          seller_id: user_details.user_id,
        };
        const result = await viewQrCodeInformation(data);
        console.log("result-----", result?.data);
        if (result?.success) {
          localStorage.setItem("redeemCode", result?.data?.code);
          setViewQrCodeInformations(result?.data?.items);
          setbuyerId(result?.data?.buyer?.id);
          setBunchDataLatest(
            result?.data?.redeem_history_latest?.bunch_data_latest
          );
          const redeemKeys = Object.keys(result?.data?.redeem_history);
          redeemKeys.map((key) => {
            const sellerRedeemData = result?.data?.redeem_history[key];
            redeemHistory.push(sellerRedeemData);
          });
          if (redeemHistory.length > 0) {
            setDetailRedeemedOrderItems(redeemHistory);
          } else {
            setDetailRedeemedOrderItems(detailRedeemedOrderItems);
          }
        } else {
          setErrorData("No Data Found");
        }
      } else {
        showErrorMessage(
          "QR code not found , Make sure the code is properly framed on your screen"
        );
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const increaseQuantity = (productId, itemVarientId, dlvQty) => {
    let redeemQty =
      selectedProducts[`${productId}`]?.[`${itemVarientId}`]?.redeem || dlvQty;
    setSelectedProducts({
      ...selectedProducts,
      [`${productId}`]: {
        ...selectedProducts[`${productId}`],
        [`${itemVarientId}`]: {
          redeem: redeemQty + 1,
        },
      },
    });
    if (dataObject?.length > 0) {
      dataObject[
        dataObject.findIndex((obj) => obj.variant_id == itemVarientId)
      ].redeem = parseInt(redeemQty) + 1;
      if (parseInt(redeemQty) + 1 > 0 && redeemQtyV.includes(itemVarientId)) {
        let selectedItemIndex = redeemQtyV.indexOf(itemVarientId);
        if (selectedItemIndex > -1) {
          redeemQtyV.splice(selectedItemIndex, 1);
          setRedeemQtyV([...new Set(redeemQtyV)]);
        }
      }
    }
  };
  const decreaseQuantity = (productId, itemVarientId, dlvQty) => {
    let redeemQty =
      selectedProducts[`${productId}`]?.[`${itemVarientId}`]?.redeem || dlvQty;
    setSelectedProducts({
      ...selectedProducts,
      [`${productId}`]: {
        ...selectedProducts[`${productId}`],
        [`${itemVarientId}`]: {
          redeem: redeemQty - 1,
        },
      },
    });
    if (dataObject?.length > 0) {
      dataObject[
        dataObject.findIndex((obj) => obj.variant_id == itemVarientId)
      ].redeem = parseInt(redeemQty) - 1;
      if (parseInt(redeemQty) - 1 <= 0 && !redeemQtyV.includes(itemVarientId)) {
        redeemQtyV.push(itemVarientId);
        setRedeemQtyV([...new Set(redeemQtyV)]);
      }
    }
  };
  const handleChangeItems = (e, bunchId, proId, varId, seller_Id, dlvQty) => {
    let dt_redeem = selectedProducts[e.target.dataset.productId]?.[
      e.target.dataset.variantId
    ]?.redeem
      ? selectedProducts[e.target.dataset.productId]?.[
          e.target.dataset.variantId
        ]?.redeem
      : 1;
    const { name, checked } = e.target;
    setBunchId(bunchId);
    setSellerId(e.target.dataset.supplierId);

    let obj = {
      supplier_id: e.target.dataset.supplierId,
      product_id: e.target.dataset.productId,
      variant_id: e.target.dataset.variantId,
      total_quantity: e.target.dataset.totalQuantity,
      delivered_quantity: e.target.dataset.deliveredQuantity,
      item_price: e.target.dataset.price,
      redeem:
        selectedProducts[e.target.dataset.productId]?.[
          e.target.dataset.variantId
        ]?.redeem || dlvQty,
    };

    if (checked) {
      sellerId.push(seller_Id);
      setSellerId(sellerId);

      dataObject.push(obj);
      setDataObject([...dataObject]);
      if (dt_redeem == 0 && !redeemQtyV.includes(varId)) {
        redeemQtyV.push(varId);
        setRedeemQtyV([...new Set(redeemQtyV)]);
      }
    } else {
      dataObject.pop(obj);
      sellerId.pop(sellerId);
      setSellerId(sellerId);
      setDataObject([...dataObject]);
      if (redeemQtyV.includes(varId)) {
        let selectedItemIndex = redeemQtyV.indexOf(varId);
        if (selectedItemIndex > -1) {
          redeemQtyV.splice(selectedItemIndex, 1);
          setRedeemQtyV([...new Set(redeemQtyV)]);
        }
      }
    }
  };
  const handleBackBtn = () => {
    navigation("/sellerhome");
  };
  const handleRedeem = async (sellerId) => {
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    const redeemcode = localStorage.getItem("redeemCode");
    let newDataObject = dataObject.filter((objItem) => {
      if (objItem.supplier_id == sellerId) return objItem;
    });
    if (bunchId) {
      let data = {
        seller_id: sellerId,
        user_id: buyerId,
        code: redeemcode,
        bunch_id: bunchId,
        order_items: newDataObject,
      };
      try {
        let result = await redeemOrderItemQuantitySeller(data);
        if (result?.success) {
          // showSuccessMessage("Item redeem sucessfully");
          setRedeemsHistory(result?.data?.redeem_history?.bunch_data);
          handleProceedOrder(result?.data?.bunch_id_param);
          let nextApidata = {
            bunch_id: bunchId,
          };
          try {
            let result = await Invoice(nextApidata);
            if (result?.success) {
              navigation("/orderhistory");
              // showSuccessMessage(result?.message)
            }
          } catch (error) {
            console.log(error);
          }
        } else if (result?.false) {
          setErrorData(result?.data);
          showErrorMessage("No order found");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      showErrorMessage("Bunch Id Not Found");
    }
  };
  const keys = Object.keys(viewQrCodeInformations);
  keys.map((key) => {
    const codeInformation = viewQrCodeInformations[key];
    allDeatils.push(codeInformation);
  });
  const handleProceedOrder = async (bunchId) => {
    let data = {
      code: bunchId,
    };
    try {
      let result = await proceedOrder(data);
      if (result?.success) {
        setShow(true);
      } else {
        console.log("error there!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = async () => {
    let QRCode = localStorage.getItem("qrcode");
    let data = {
      code: QRCode,
    };
    try {
      let result = await CancelOrderSeller(data);
      if (result?.success) {
        // Swal.fire({
        //   title: "Success",
        //   text: result?.message,
        //   icon: "success",
        //   confirmButtonText: "Ok",
        // }).then((result) => {
        //   /* Read more about isConfirmed, isDenied below */
        //   if (result.isConfirmed) {
        //     navigation("/orderhistory");
        //   } else {
        //     navigation("/orderhistory");
        //   }
        // });
        setIsCancel(true);
      } else {
        console.log("error there!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {" "}
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <LoggedInHeader>
          <div className="body-container d-flex">
            <SideNavigation />
            <div className="right-panel">
              {detailRedeemedOrderItems.length > 0 ? (
                detailRedeemedOrderItems?.map((redeemItems) => {
                  return (
                    <>
                      <div className="page-title">
                        <h2>
                          Order Id:{" "}
                          <span>
                            #{redeemItems.bunch_data[0].order_id_generated}
                          </span>
                        </h2>
                        <p>
                          <strong>Order Date:</strong>{" "}
                          <span>
                            {moment(
                              redeemItems.bunch_data[0].order_date
                            ).format("MMMM DD-YYYY hh:mm A")}
                          </span>
                        </p>
                      </div>
                      <div>
                        {redeemItems?.qrdata?.is_delete == 2 ? (
                          <>
                            <div className="text-center have-list">
                              <h2
                                style={{
                                  display: "flex",
                                  // height: "70vh",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                This order item bunch is deleted
                              </h2>
                              <button
                                onClick={handleBackBtn}
                                className="btn btn-default btn-dark w-20 m-auto px-3 my-2"
                              >
                                Go To Dashboard Page
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="row">
                            <div>
                              <div className="card shadow-sm">
                                <div className="card-body">
                                  <div className="table-responsive">
                                    <table className="table seller_detail">
                                      <tbody>
                                        <tr style={{ textAlign: "center" }}>
                                          <th></th>
                                          <th>Item Name</th>
                                          <th>Size</th>
                                          <th>Price</th>
                                          <th>Item Remaining Quantity</th>
                                          <th>Total Ordered Qty</th>
                                          <th>Redeemed By Buyer</th>
                                          <th>Maximum redeemable by seller</th>
                                          <th>Redeem</th>
                                        </tr>
                                        {redeemItems?.bunch_data?.map(
                                          (item) => {
                                            let remainingQty =
                                              item?.recent_delivered_quantity;
                                            return (
                                              <tr
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                <td>
                                                  {remainingQty > 0 ? (
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        data-size-id="1"
                                                        data-order-item-id={
                                                          item.variant_id
                                                        }
                                                        data-supplier-id={
                                                          item.seller_id
                                                        }
                                                        data-product-id={
                                                          item.product_id
                                                        }
                                                        data-variant-id={
                                                          item.variant_id
                                                        }
                                                        data-total-quantity={
                                                          item.ordered_quantity
                                                        }
                                                        data-delivered-quantity={
                                                          item.recent_delivered_quantity
                                                        }
                                                        data-price={
                                                          item.item_price
                                                        }
                                                        // data-bunch-id={item.bunch_id}
                                                        onChange={(e) =>
                                                          handleChangeItems(
                                                            e,
                                                            item.bunch_id,
                                                            item?.product_id,
                                                            item?.variant_id,
                                                            item.seller_id,
                                                            item.recent_delivered_quantity
                                                          )
                                                        }

                                                        //     checked={selectedProducts[
                                                        //       `${item.product_id}`
                                                        //     ]?.[
                                                        //       `${item.variant_id}`
                                                        //     ]?.redeem ==
                                                        //    remainingQty ? false : true}
                                                        // disabled={
                                                        //   !sellerId?.includes(
                                                        //     detailRedeemedOrderItems[0]?.bunch_data[0].seller_id
                                                        //   )
                                                        //     ? true
                                                        //     : false
                                                        // }
                                                      />
                                                    </div>
                                                  ) : null}
                                                </td>

                                                {/* <td>{item[0].bunch_id}</td> */}
                                                <td>{item.item_name}</td>

                                                <td>
                                                  {item.weight &&
                                                  item?.packing_unit ? (
                                                    <>
                                                      {" "}
                                                      {item.weight}
                                                      {item.packing_unit}
                                                    </>
                                                  ) : item.weight ? (
                                                    <> {item.weight}</>
                                                  ) : item.packing_unit ? (
                                                    <> {item.packing_unit}</>
                                                  ) : (
                                                    "_"
                                                  )}
                                                </td>
                                                <td>
                                                  {item.item_price
                                                    ? `£${item.item_price}`
                                                    : null}
                                                </td>
                                                <td>
                                                  {item.item_remaining_quantity
                                                    ? `${item.item_remaining_quantity}`
                                                    : "-"}
                                                </td>

                                                <td>{item.ordered_quantity}</td>

                                                <td>
                                                  {
                                                    item.recent_delivered_quantity
                                                  }
                                                </td>

                                                <td>
                                                  {
                                                    item.recent_delivered_quantity
                                                  }
                                                </td>
                                                <td>
                                                  {remainingQty > 0 ? (
                                                    <div className="quantity">
                                                      <div className="number">
                                                        <div className="quantity-box">
                                                          <button
                                                            className="quantity-btn"
                                                            onClick={() =>
                                                              decreaseQuantity(
                                                                item.product_id,
                                                                item.variant_id,
                                                                item.recent_delivered_quantity
                                                              )
                                                            }
                                                            disabled={
                                                              selectedProducts[
                                                                `${item.product_id}`
                                                              ]?.[
                                                                `${item.variant_id}`
                                                              ]?.redeem == 1 ||
                                                              (selectedProducts[
                                                                `${item.product_id}`
                                                              ]?.[
                                                                `${item.variant_id}`
                                                              ]?.redeem ==
                                                                undefined &&
                                                                remainingQty ==
                                                                  1)
                                                                ? true
                                                                : false
                                                            }
                                                          >
                                                            <svg
                                                              width="20px"
                                                              height="20px"
                                                              viewBox="0 0 20 20"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M4 12H20"
                                                                stroke="#000000"
                                                                stroke-width="1.5"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              ></path>
                                                            </svg>
                                                          </button>

                                                          <input
                                                            width="300"
                                                            type="number"
                                                            className="quantity input"
                                                            //  id={opt.variant_id}
                                                            value={
                                                              selectedProducts[
                                                                `${item.product_id}`
                                                              ]?.[
                                                                `${item.variant_id}`
                                                              ]?.redeem ||
                                                              item.recent_delivered_quantity
                                                            }
                                                          />
                                                          <button
                                                            className="quantity-btn"
                                                            id={item.variant_id}
                                                            onClick={() =>
                                                              increaseQuantity(
                                                                item.product_id,
                                                                item.variant_id,
                                                                item.recent_delivered_quantity
                                                              )
                                                            }
                                                            disabled={
                                                              selectedProducts[
                                                                `${item.product_id}`
                                                              ]?.[
                                                                `${item.variant_id}`
                                                              ]?.redeem ==
                                                                remainingQty ||
                                                              (selectedProducts[
                                                                `${item.product_id}`
                                                              ]?.[
                                                                `${item.variant_id}`
                                                              ]?.redeem ==
                                                                undefined &&
                                                                item.recent_delivered_quantity ==
                                                                  item.recent_delivered_quantity)
                                                                ? true
                                                                : false
                                                            }
                                                          >
                                                            <svg
                                                              width="20px"
                                                              height="20px"
                                                              viewBox="0 0 20 20"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              fill="none"
                                                            >
                                                              <path
                                                                fill="#000000"
                                                                fill-rule="evenodd"
                                                                d="M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z"
                                                              ></path>
                                                            </svg>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    0
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                    <div className="text-center">
                                      {detailRedeemedOrderItems[0]?.qrdata
                                        ?.isBunchPartiallyRedeemedSeller == 1 &&
                                      detailRedeemedOrderItems[0]?.qrdata
                                        ?.is_delete == 1 ? (
                                        <button
                                          onClick={() => {
                                            handleRedeem(
                                              detailRedeemedOrderItems[0]
                                                ?.bunch_data[0].seller_id
                                            );
                                          }}
                                          className="btn btn-default btn-dark w-20 m-auto px-3 my-2"
                                          disabled={
                                            !sellerId?.includes(
                                              detailRedeemedOrderItems[0]
                                                ?.bunch_data[0].seller_id
                                            )
                                              ? true
                                              : false
                                          }
                                        >
                                          Confirm
                                        </button>
                                      ) : null}
                                      {detailRedeemedOrderItems[0]?.qrdata
                                        ?.is_delete == 1 &&
                                      detailRedeemedOrderItems[0]?.qrdata
                                        ?.isBunchPartiallyRedeemedSeller ==
                                        1 ? (
                                        <button
                                          onClick={handleCancel}
                                          className="cancleBtn btn btn-default btn-dark w-20 m-auto px-3 my-2"
                                          disabled={
                                            !sellerId?.includes(
                                              detailRedeemedOrderItems[0]
                                                ?.bunch_data[0].seller_id
                                            )
                                              ? true
                                              : false
                                          }
                                        >
                                          Cancel
                                        </button>
                                      ) : null}
                                    </div>
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  );
                })
              ) : (
                <div
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontSize: "40px",
                    marginTop: "10px",
                    marginLeft: "0px",
                  }}
                >
                  {errorData}
                </div>
              )}
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>PaySah</Modal.Title>
            </Modal.Header>
            <Modal.Body>Order proceed successfully</Modal.Body>
            <Modal.Footer>
              {/* <Button variant="secondary" onClick={}>
                  Ok  window.location.reload();
                </Button> */}
              <Button variant="primary" onClick={handleClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={isCancel}
            onHide={() => navigation("/orderhistory")}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>PaySah</Modal.Title>
            </Modal.Header>
            <Modal.Body>Product has been canceled!</Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => navigation("/orderhistory")}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer />
        </LoggedInHeader>
      )}
    </>
  );
}
