import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import SideNavigation from "../components/SideNavigation";
import {
  ViewOrderDetailsSellerNew,
  updateOrderStatus,
  redeemOrder,
} from "../api/request";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import LoggedInHeader from "../components/LoggedInHeader";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export default function SellerOrderDetails() {
  const { isLoading } = useSelector((state) => state.loader);
  const [cartItems, setCartItems] = useState({});
  const [apiResponse, setApiResponse] = useState();
  const [show, setShow] = useState(false);
  const [detailRedeemedOrderItems, setDetailRedeemedOrderItems] = useState([]);
  const [deliveryStatus, setDeliveryStatus] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  useEffect(() => {
    document.title = "Paysah | OrderDetails";
    (async () => {
      let viewRedeemHistory = [];
      var orderId = localStorage.getItem("sellerSide-orderId");
      let user_details = await JSON.parse(localStorage.getItem("user_details"));
      let data = {
        supplier_id: user_details?.user_id,
        id: orderId,
      };
      try {
        let result = await ViewOrderDetailsSellerNew(data);

        if (result?.success) {
          if (result?.data) {
            setCartItems(result?.data?.items);
            setApiResponse(result?.data);
            setDeliveryStatus(result?.data?.product_delivery_statuses);
            localStorage.setItem("code", result?.data?.code);
            localStorage.setItem(
              "sellerQr_image_name",
              result?.data?.image_name
            );
            const viewRedeemKeys = Object.keys(result?.data?.redeem_history);
            viewRedeemKeys.map((key) => {
              const viewsellerRedeemData = result?.data?.redeem_history[key];
              viewRedeemHistory.push(viewsellerRedeemData);
            });
            setDetailRedeemedOrderItems(viewRedeemHistory);
          }
        } else {
          console.log("error there!");
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const keys = Object.keys(cartItems);
  let allSellers = [];
  keys.map((key) => {
    const sellerData = cartItems[key];
    allSellers.push(sellerData);
  });
  const handlePopup = (imgName) => {
    Swal.fire({
      imageUrl:
        "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
        `${imgName}`,
      imageWidth: 700,
      imageHeight: 400,
      imageAlt: "Custom image",
      confirmButtonText: "Cancel",
    });
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleOkayBtn = () => {
    window.location.reload();
  };
  const handleOptionChanges = (e, pId) => {
    const selectedValue = e.target.value;
    setSelectedOption({ ...selectedOption, [pId]: selectedValue });
  };
  const orderStatusChange = async (bunch_id, pId, bringId, PickUpId) => {
    let apiData = {};
    apiData = {
      bunch_id: bunch_id,
      order_status: selectedOption[pId],
      bring_it_to_me: bringId,
      pick_up_later: PickUpId,
    };
    console.log({ apiData });
    try {
      let result = await updateOrderStatus(apiData);
      if (result?.success) {
        Swal.fire({
          text: result?.message,
          icon: "success",
        });
        // showSuccessMessage(result?.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />
              <div className="right-panel">
                {apiResponse && (
                  <div className="page-title">
                    <h2 className="text-start">
                      Order Id: <span>#{apiResponse?.orderId}</span>
                    </h2>
                    <p>
                      <strong>Order Date:</strong>{" "}
                      <span>
                        {moment(apiResponse.orderCreatedDate).format(
                          "MMMM DD-YYYY hh:mm A"
                        )}
                      </span>
                    </p>
                  </div>
                )}
                {cartItems?.length > 0 ? (
                  <>
                    {" "}
                    <div className="redeem-history">
                      <h3>
                        <strong className="pro-subheading">
                          Order History
                        </strong>
                      </h3>
                      <div className="tableStyle">
                        <div className="row align-items-center mb-3">
                          <div className="col-md-8">
                            <h4 className="pro-subheading">
                              <strong>{cartItems?.[0]?.buyerName} </strong>{" "}
                            </h4>
                          </div>
                          {/* <span>
                            <strong>Created Date :</strong>{" "}
                            {moment(cartItems?.[0]?.createdAt).format(
                              "MMMM DD-YYYY hh:mm A"
                            )}
                          </span> */}
                        </div>

                        <>
                          <div className="row">
                            <div>
                              <div className="card">
                                <div className="card-body">
                                  <div className="table-responsive">
                                    <table className="table" width="100%">
                                      <tbody>
                                        <tr style={{ textAlign: "center" }}>
                                          <th className="pro-subheading">
                                            Name
                                          </th>
                                          <th className="pro-subheading">
                                            Size
                                          </th>
                                          {/* <th>Sizing Unit</th> */}
                                          <th className="pro-subheading">
                                            Price
                                          </th>
                                          <th className="pro-subheading">
                                            Quantity
                                          </th>
                                        </tr>
                                        {cartItems.length > 0
                                          ? cartItems?.map((orderItems) => {
                                              return (
                                                <tr
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <td className="pro-subheading">
                                                    {orderItems?.productName}
                                                  </td>
                                                  {/* <td>{orderItems?.weight}</td>
                                                  <td>{orderItems?.weightUnit}</td> */}
                                                  <td className="pro-subheading">
                                                    {orderItems.weight &&
                                                    orderItems?.weightUnit ? (
                                                      <>
                                                        {" "}
                                                        {orderItems.weight}
                                                        {" " +
                                                          orderItems.weightUnit}
                                                      </>
                                                    ) : orderItems.weight ? (
                                                      <> {orderItems.weight}</>
                                                    ) : orderItems.weightUnit ? (
                                                      <>
                                                        {" "}
                                                        {" " +
                                                          orderItems.weightUnit}
                                                      </>
                                                    ) : (
                                                      "_"
                                                    )}
                                                  </td>
                                                  <td className="pro-subheading">
                                                    £{orderItems?.item_price}
                                                  </td>
                                                  <td className="pro-subheading">
                                                    {
                                                      orderItems?.orderedQuantity
                                                    }
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          : null}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </>
                ) : (
                  <h3
                    style={{
                      display: "flex",
                      height: "70vh",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Order History Not Found
                  </h3>
                )}

                {detailRedeemedOrderItems?.length > 0 ? (
                  <>
                    {" "}
                    <div className="redeem-history">
                      <h3>
                        <strong>Redeem History</strong>
                      </h3>
                      {detailRedeemedOrderItems.length > 0
                        ? detailRedeemedOrderItems?.map((redeemItems) => {
                            return (
                              <>
                                <div className="tableStyle">
                                  <div className="row align-items-center mb-3">
                                    <div className="col-md-8">
                                      <h4>
                                        <strong>
                                          {
                                            redeemItems?.bunch_data[0]
                                              ?.seller_name
                                          }{" "}
                                        </strong>{" "}
                                      </h4>
                                    </div>
                                    <div className="col-md-4 text-end">
                                      <div className="qrcode">
                                        {redeemItems?.qrdata ? (
                                          <img
                                            onClick={(e) =>
                                              handlePopup(
                                                redeemItems?.qrdata?.image_name
                                              )
                                            }
                                            src={
                                              "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
                                              `${redeemItems?.qrdata?.image_name}`
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                    {redeemItems?.qrdata
                                      .isBunchPartiallyRedeemed ||
                                    redeemItems?.qrdata.is_delete ? (
                                      <div>
                                        <span>
                                          {redeemItems?.qrdata.is_delete ==
                                          2 ? (
                                            <>
                                              {" "}
                                              <strong>
                                                Order Status :{" "}
                                              </strong>{" "}
                                              Deleted{" "}
                                            </>
                                          ) : null}
                                          {redeemItems?.qrdata
                                            .isBunchPartiallyRedeemed == 1 ? (
                                            <>
                                              {" "}
                                              <strong>
                                                Order Status :{" "}
                                              </strong>{" "}
                                              Not Redeemed{" "}
                                            </>
                                          ) : null}
                                          {redeemItems?.qrdata
                                            .isBunchPartiallyRedeemed == 2 ? (
                                            <>
                                              <strong>Order Status : </strong>
                                              Partially Redeemed{" "}
                                            </>
                                          ) : null}
                                          {redeemItems?.qrdata
                                            .isBunchPartiallyRedeemed == 3 ? (
                                            <>
                                              {" "}
                                              <strong>
                                                Order Status :
                                              </strong>{" "}
                                              Redeemed{" "}
                                            </>
                                          ) : null}
                                        </span>
                                        <br />
                                      </div>
                                    ) : null}
                                    <span>
                                      <strong>Redeem Request Date :</strong>{" "}
                                      {moment(
                                        redeemItems?.qrdata.redeem_date
                                      ).format("MMMM DD-YYYY hh:mm A")}
                                    </span>
                                    {redeemItems?.qrdata.bring_it_to_me == 2 ? (
                                      <>
                                        <strong>Bring it to me </strong>
                                        <span>
                                          <strong>Table Id : </strong>{" "}
                                          {
                                            redeemItems?.qrdata
                                              .bring_it_to_me_on_table_no
                                          }
                                        </span>
                                        <br />
                                        <span>
                                          <strong>Additional Info : </strong>{" "}
                                          {
                                            redeemItems?.qrdata
                                              .bring_it_to_me_at_address
                                          }
                                        </span>
                                      </>
                                    ) : null}
                                    {redeemItems?.qrdata.pick_up_later == 2 ? (
                                      <>
                                        <strong>Pick up later</strong>
                                        <span>
                                          <strong>Date : </strong>{" "}
                                          {moment(
                                            redeemItems?.qrdata
                                              .pick_up_later_date_time
                                          ).format("MMMM DD-YYYY ")}
                                        </span>
                                        <br />
                                        <span>
                                          <strong>Time : </strong>{" "}
                                          {
                                            redeemItems?.qrdata
                                              .pick_up_later_time_range
                                          }
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="row">
                                    <div>
                                      <div className="card">
                                        <div className="card-body">
                                          <div className="table-responsive">
                                            <table className="table">
                                              <tbody>
                                                <tr
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <th>Item Name</th>
                                                  <th>Measurement</th>
                                                  {redeemItems?.qrdata
                                                    .isBunchPartiallyRedeemed ==
                                                    2 ||
                                                  redeemItems?.qrdata
                                                    .isBunchPartiallyRedeemed ==
                                                    3 ? (
                                                    <th>
                                                      Redeem By Seller Date
                                                    </th>
                                                  ) : null}

                                                  {/* <th>Seller Name</th> */}

                                                  {redeemItems?.qrdata
                                                    .isBunchPartiallyRedeemed ==
                                                    2 ||
                                                  redeemItems?.qrdata
                                                    .isBunchPartiallyRedeemed ==
                                                    3 ? (
                                                    <th>Redeemed</th>
                                                  ) : (
                                                    <th>Redeem To Be</th>
                                                  )}

                                                  <th>Redeemed By Seller</th>
                                                </tr>
                                                {redeemItems?.bunch_data?.map(
                                                  (item) => {
                                                    return (
                                                      <tr
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <td>
                                                          {item.item_name}
                                                        </td>
                                                        {/* <td>{item.seller_name}</td> */}
                                                        <td>
                                                          {item.weight &&
                                                          item?.packing_unit ? (
                                                            <>
                                                              {" "}
                                                              {item.weight}
                                                              {
                                                                item.packing_unit
                                                              }
                                                            </>
                                                          ) : item.weight ? (
                                                            <> {item.weight}</>
                                                          ) : item.packing_unit ? (
                                                            <>
                                                              {" "}
                                                              {
                                                                item.packing_unit
                                                              }
                                                            </>
                                                          ) : (
                                                            "_"
                                                          )}
                                                        </td>

                                                        {redeemItems?.qrdata
                                                          .isBunchPartiallyRedeemed ==
                                                          2 ||
                                                        redeemItems?.qrdata
                                                          .isBunchPartiallyRedeemed ==
                                                          3 ? (
                                                          <td>
                                                            {item?.redeemed_by_seller !=
                                                            null
                                                              ? moment(
                                                                  item?.redeemed_by_seller
                                                                ).format(
                                                                  "MMMM DD-YYYY hh:mm A"
                                                                )
                                                              : "_"}
                                                          </td>
                                                        ) : null}

                                                        <td>
                                                          {
                                                            item.recent_delivered_quantity
                                                          }
                                                        </td>
                                                        <td>
                                                          {
                                                            item.actual_redeemed_qty
                                                          }
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>

                                            {/* {redeemItems?.qrdata?.is_delete ===
                                              1 && (
                                              <div className="text-center">
                                                <strong>Order status:</strong>
                                                <br />
                                                <select
                                                  className="btn btn-default btn-dark w-20 m-auto px-3 my-2"
                                                  value={
                                                    selectedOption[
                                                      redeemItems?.bunch_data[0]
                                                        .sellerId
                                                    ]
                                                  }
                                                  onChange={(e) =>
                                                    handleOptionChanges(
                                                      e,
                                                      redeemItems?.bunch_data[0]
                                                        .product_id,
                                                      redeemItems?.bunch_data[0]
                                                        ?.bunch_id
                                                    )
                                                  }
                                                >
                                                  <option value="0">
                                                    Choose an option
                                                  </option>
                                                  {deliveryStatus.map(
                                                    (status) => (
                                                      <option
                                                        key={status.id}
                                                        value={status.id}
                                                      >
                                                        {
                                                          status.product_delivery_status
                                                        }
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                <br />
                                                <button
                                                  onClick={() =>
                                                    orderStatusChange(
                                                      redeemItems?.bunch_data[0]
                                                        ?.bunch_id,
                                                      redeemItems?.bunch_data[0]
                                                        ?.product_id,
                                                      redeemItems?.qrdata
                                                        ?.bring_it_to_me,
                                                      redeemItems?.qrdata
                                                        ?.pick_up_later
                                                    )
                                                  }
                                                  className="btn btn-default btn-dark w-20 m-auto px-3 my-2"
                                                >
                                                  Submit
                                                </button>
                                              </div>
                                            )} */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : null}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </LoggedInHeader>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>PaySah</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p
                style={{
                  color: "red",
                }}
              >
                Invalid redeem quantity for product(s)!
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleOkayBtn}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer />
        </>
      )}
    </>
  );
}
