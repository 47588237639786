import React from "react";
import { Navigate } from "react-router-dom";

export const ProtectedSeller = ({ redirectPath="/", children }) => {
  const Token = localStorage.getItem("token");
  const User_Role = localStorage.getItem("user_role");

  if(!Token){
       return <Navigate to={redirectPath} replace /> 
  }
  else if (Token && User_Role === "Seller") {
    return children;
  }
  else{
    return <Navigate to="/forbidden" />;
  }
};
