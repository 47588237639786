import axios from "axios";
import { BASEURL, SELLERBASEURL } from "./constants";
import { useNavigate } from "react-router-dom";

// const navigate = useNavigate();
let headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
const instance = axios.create({
  baseURL: BASEURL,
  headers: headers,
  timeout: 20000,
});
// GET REQUEST
export async function getRequest(requestUrl) {
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  try {
    const response = await instance.get(
      requestUrl,
      {
        responseType: "text",
        validateStatus: function (status_1) {
          return status_1;
        },
      },
      {
        cancelToken: source.token,
      }
    );
    return response;
  } catch (error) {
    source.cancel();
    return error;
  }
}

// GET SELLER PRODUCTS
// GET REQUEST TOKEN
export async function getSellerProduct(requestUrl) {
  const token = localStorage.getItem("token");
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  try {
    const response = await instance.get(
      requestUrl,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "text",

        validateStatus: function (status_1) {
          return status_1;
        },
      },
      {
        cancelToken: source.token,
      }
    );
    return response.data;
  } catch (error) {
    source.cancel();
    return error;
  }
}

export async function getSellerListing(requestUrl, data) {
  const token = localStorage.getItem("token");

  // if (token) {
  //   headers.Authorization = `Bearer ${token}`;
  // }
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();

  try {
    const response = await instance.post(
      requestUrl,
      data,
      {
        ...(token && { headers: { Authorization: `Bearer ${token}` } }),
        responseType: "text",
        validateStatus: function (status_1) {
          return status_1;
        },
      },
      {
        cancelToken: source.token,
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    source.cancel();
    return error;
  }
}

// export async function getSellerListing(requestUrl) {
//   const token = localStorage.getItem("token");
//   if (token) {
//     headers.Authorization = `Bearer ${token}`;
//   }
//   const CancelToken = axios.CancelToken;
//   let source = CancelToken.source();
//   try {
//     const response = await instance.get(
//       requestUrl,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         responseType: "text",

//         validateStatus: function (status_1) {
//           return status_1;
//         },
//       },
//       {
//         cancelToken: source.token,
//       }
//     );
//     return response.data;
//   } catch (error) {
//     source.cancel();
//     return error;
//   }
// }

// GET REQUEST TOKEN
export async function getRequestToken(requestUrl) {
  const token = localStorage.getItem("token");
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();

  try {
    const response = await instance.get(
      requestUrl,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "text",

        validateStatus: function (status_1) {
          return status_1;
        },
      },
      {
        cancelToken: source.token,
      }
    );
    if (
      response?.status == 404 &&
      response?.data?.message == "un_activated_user"
    ) {
      window.localStorage.clear();
      window.location.href = "/"; // Navigate to your desired route
    }
    return response.data;
  } catch (error) {
    source.cancel();
    return error;
  }
}

// POST REQUEST TOKEN
export async function postRequest(requestUrl, data) {
  const token = localStorage.getItem("token");
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();

  try {
    const response = await instance.post(
      requestUrl,
      data,
      {
        responseType: "text",
        validateStatus: function (status_1) {
          return status_1;
        },
      },
      {
        cancelToken: source.token,
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    source.cancel();
    return error;
  }
}

export async function buyerWantList(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        // window.localStorage.clear();
        // window.location.href = '/'; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function addBuyerList(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {})
    .catch((error) => {
      console.log({ error });
    });
}

export async function postRequestToken(requestUrl, body) {
  const token = localStorage.getItem("token");

  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function postSellerProductList(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function postProductList(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function showCategory(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function sendProductRequest(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "json",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function sellerProductSave(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "json",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function BuyerOrderlistsellerApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function ShowSellerSendproductRequestApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function viewSellerProductList(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function showSellerListing(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function postSellerToken(requestUrl, body) {
  const token = localStorage.getItem("token");

  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function updateProductList(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function deleteSellerProduct(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function updateCreateCartApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function getCartDataApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function deleteCart(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function createOrderApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function haveListListingApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function haveListOrderDetailsApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function viewQrCodeInformationApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function RedeemOrderItemQuantityApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function UpdateOrderItemQuantityApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function viewQrCodeOrderInformationApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function ViewOrderListSellerApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function ViewOrderDetailsSellerApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function CancelOrderSellerApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function redeemOrderItemQuantitySellerApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function proceedOrderApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function cancelOrdersWholeApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function ReplacCartItemsQuantityApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function OrderHistoryBuyerApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function GetCartExceedQuantityApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function ViewOrderDetailsSellerNewApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function DeleteSellerProductsItemsApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function InvoiceApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function sellertempsApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function ViewOrderDetailsStockRoomApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function ViewRedeemHistoryStockRoomApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function UpdateFrontUserResetPasswordApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function ViewOrderDetailsStockRoomSellerWiseApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status == 404 &&
        response?.data?.message == "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function DeleteBuyerSideProductQR(requestUrl, body) {
  console.log(requestUrl, body);
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status === 404 &&
        response?.data?.message === "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function UpdateBuyerSideProductQR(requestUrl, body) {
  console.log(requestUrl, body);
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status === 404 &&
        response?.data?.message === "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
export async function EditBuyerSideProductQR(requestUrl, body) {
  console.log(requestUrl, body);
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      if (
        response?.status === 404 &&
        response?.data?.message === "un_activated_user"
      ) {
        window.localStorage.clear();
        window.location.href = "/"; // Navigate to your desired route
      }
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

// GET Notification
export async function ViewNotificationsBuyerApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function ViewNotificationsSellerApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

//** Reactivation History */

export async function showOrderItemNewReactivationHistorySellerApi(
  requestUrl,
  body
) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function showOrderItemNewReactivationHistoryBuyerApi(
  requestUrl,
  body
) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
//** Analytics Data Status */
export async function analyticsDataBuyerApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}

export async function analyticsDataSellerApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
//** Update Order Status */
export async function updateOrderStatusApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
//** Reactivation history */
export async function ReactivateOrderItemNewApi(requestUrl, body) {
  const token = localStorage.getItem("token");
  const CancelToken = axios.CancelToken;
  let source = CancelToken.source();
  axios.defaults.timeout = 20000;
  return axios(
    {
      method: "post",
      url: BASEURL + requestUrl,
      responseType: "text",
      data: body,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 20000,
      validateStatus: function (status) {
        return status;
      },
    },
    {
      cancelToken: source.token,
    }
  )
    .then(function (response) {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
    });
}
