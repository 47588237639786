import React, { useState } from "react";
import { Link } from "react-router-dom";
export default function LoggedInHeader({ children }) {
  const [isActive, setIsActive] = useState(false);
  const changeClass = () => {
    setIsActive(!isActive);
  };
  return (
    <div className={isActive ? "wrapper active" : "wrapper"}>
      <div className="top-action-bar">
        <div className="container">
          <header className="d-flex justify-content-center">
            <div className="mobile-user">
              <Link to="/">
                <img src="../assets/images/icon-user.svg" alt="" />
              </Link>
            </div>
            <Link to="/" className="header-logo">
              <img className="logo-image" src="/static/media/logo.e61c3bfebcbd6c6b01cd.png" alt="" />
            </Link>
            <Link to="/" className="mobile-logo">
              <img className="logo-image" src="/static/media/logo.e61c3bfebcbd6c6b01cd.png" alt="" />
            </Link>
            <div className="toggle-btn" onClick={changeClass}>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </div>
          </header>
        </div>
      </div>
      {children}
    </div>
  );
}
