import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { EmailVerification } from "../../api/request";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export default function AccountVerification() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const token = query.get("token");
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    document.title = "Paysah | Account_Verification";
    const AccountValidator = async () => {
      try {
        if (token) {
          let response = await EmailVerification(token);
          if (response?.success) {
            setSuccessMessage(true);
          } else {
            setSuccessMessage(false);
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    AccountValidator();
  }, [token]);


  return (
    <>
      <div className="wrapper">
        <div className="top-action-bar">
          <div className="container"></div>
        </div>
        <div className="container">
          <div className="px-4 py-5 my-5 text-center">
            <Link to="/">
              <img
                className="d-block mx-auto heading-logo"
                src="../../assets/images/logo.png"
                alt=""
              />
            </Link>
            <div className="register-form">
              <form>
                {successMessage === true ? (
                  <>
                    <div className="section-heading mb-4">
                      <i
                        className=" fa fa-check-circle text-success fa-4x"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="text-center" style={{ marginBottom: "6" }}>
                      <h3>Congratulations, Your email has been verified</h3>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="section-heading mb-4">
                      <i className="fa fa-times-circle-o fa-4x"
                      aria-hidden="true"></i>
                    </div>
                    <div className="text-center" style={{ marginBottom: "6" }}>
                      <h3>Invalid Token</h3>
                    </div>
                  </>
                )}
              </form>
              {!token && (
                <>
                  <div className="text-center" style={{ marginBottom: "6" }}>
                    <h3>Token Not Found</h3>
                  </div>
                </>
              )}
              <div className="row mb-3">
                <div className="col-sm-12">
                  <Link to="/login">
                    <button className="btn btn-dark w-100">Login</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
