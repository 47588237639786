import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../redux/slices/LoaderSlice";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Spinner from "react-bootstrap/Spinner";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import {
  showSellerProductList,
  removeSellerProduct,
  showProductList,
  saveSellerProducts,
} from "../api/request";
import SideNavigation from "../components/SideNavigation";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import LoggedInHeader from "../components/LoggedInHeader";
import { clearWantList } from "../redux/slices/QuantitySlice";
import { showErrorMessage, showSuccessMessage } from "../constants/Toast";

const columns = [
  { field: "title", headerName: "Title", width: 130 },
  { field: "description", headerName: "Description", width: 950 },
];
export default function SellerProductList() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [foundUsers, setFoundUsers] = useState({});
  const [dataRow, setDataRow] = useState([]);
  const [searchResult, setSearchResult] = useState("");
  const { isLoading } = useSelector((state) => state.loader);
  const navigation = useNavigate();
  const [orders, setOrders] = useState([]);
  const [perPageData] = useState(10);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [prevPage, setPrevPage] = useState(null);
  const [resetValue, setResetValue] = useState(false);
  const [deleteProducts, setDeleteProducts] = useState([]);
  const [dataObject, setDataObject] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    orderPlace();
  }, [page, resetValue]);
  useEffect(() => {
    if (searchQuery.length > 3) {
      orderPlace();
    }
  }, [searchQuery]);

  const orderPlace = React.useCallback(async () => {
    let pageTitle =
      localStorage.getItem("user_role") === "Buyer"
        ? "BuyerProductListing"
        : "SellerProductListing";
    document.title = `Paysah | ${pageTitle}`;

    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    let data = null;
    if (searchResult.length >= 3) {
      data = {
        user_id: user_details?.user_id,
        search_keyword: searchResult,
        page: 1,
        limit: perPageData,
      };
    } else {
      data = {
        user_id: user_details?.user_id,
        search_keyword: null,
        page: page,
        limit: perPageData,
      };
    }

    try {
      let result = await showProductList(data);
      if (result?.success) {
        if (result?.data) {
          let imageUrl = result?.data?.image_url;
          let defaultImageUrl = result?.data?.default_image_url;
          let dataRow = [];
          result?.data?.products?.data?.map((user, index) => {
            let img = imageUrl + user.image;
            dataRow.push({
              id: index + 1,
              title: user.title,
              description: user.description,
              image:
                user.image === null || user.image === ""
                  ? defaultImageUrl
                  : img,
            });
          });
          setDataRow(dataRow);
          setFoundUsers(result?.data?.products?.data);
          setPrevPage(result?.data?.products?.prev_page_url);
          setNextPage(result?.data?.products?.next_page_url);
          setOrders(result?.data?.products?.data);
          setResetValue(false);
        }
      } else {
        // showErrorMessage(result?.data);
        setFoundUsers([]);
        setResetValue(false);
      }
    } catch (error) {
      setResetValue(false);
      console.log(error);
    }
  }, [page, perPageData, searchResult]);

  const reset = () => {
    setResetValue(true);
    setTags("");
    setSearchResult("");
  };

  const handleSubmitBtn = async () => {
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    let data = {
      user_id: user_details?.user_id,
      products: dataObject,
    };
    try {
      let result = await saveSellerProducts(data);
      if (result.success) {
        dispatch(hideLoading());
        showSuccessMessage(result?.message);
        if (result?.data?.redirect_url_status == 1) {
          setTimeout(() => {
            navigation("/selectedProductList");
          }, 2000);
        }
      } else if (result.success == false) {
        dispatch(hideLoading());
        showErrorMessage(result?.message);
      }
    } catch (error) {
      dispatch(hideLoading());
      console.log("ERROR", error);
    }
  };

  const filter = async (e) => {
    const keyword = e.target.value;
    setSearchResult(keyword);

    // if (keyword.length > 3) {
    //   setSearchQuery(keyword);
    // } else {
    //   setSearchQuery("");
    // }
  };

  const [tags, setTags] = React.useState([]);
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value]);
      // props.selectedTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };
  const handleSelectProducts = React.useCallback(
    (e, productId, productName, catId) => {
      const { name, checked } = e.target;
      const obj = {
        product_id: productId,
        name: productName,
        category_id: catId,
      };

      if (checked) {
        setDataObject((prevDataObject) => [...prevDataObject, obj]);
      } else {
        setDataObject((prevDataObject) =>
          prevDataObject.filter((item) => item.product_id !== productId)
        );
      }
    },
    []
  );
  const sendProductReq = () => {
    navigation(`/newProductRequest`);
  };
  const productListPage = () => {
    navigation(`/selectedProductList`);
  };
  const listProductReq = () => {
    navigation(`/reqProductList`);
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />
              <div className="right-panel">
                <h2>
                  <strong className="pro-subheading">Product Listing</strong>
                </h2>
                <div>
                  <div className="row ">
                    <div className="col-lg-8 ">
                      <Alert
                        variant="filled"
                        severity="info"
                        className="bg-white text-dark"
                      >
                        Explore our extensive product catalog to discover a
                        diverse range of offerings. To add your own products,
                        click on the 'Send New Product Request' button, fill in
                        the details, and submit for admin approval. From 'List
                        of requested products' you can check your product
                        status. Alternatively, you can choose from our default
                        product list by selecting items via checkboxes and
                        clicking 'Add Products To Store'. For updates to your
                        existing products, click on the 'My Products' button to
                        modify and enhance your product information. Your
                        journey to seamless product management starts here!
                      </Alert>
                    </div>
                    <div className="col-lg-4">
                      <Stack
                        direction="column"
                        className="btn-productlist"
                        spacing={2}
                      >
                        <Button
                          variant="contained"
                          onClick={productListPage}
                          className="bg-white text-dark"
                        >
                          My Products
                        </Button>
                        <div className="d-flex justify-content-between small-block gap-2">
                          <Button
                            variant="contained"
                            onClick={sendProductReq}
                            className="bg-white text-dark"
                          >
                            Send New Product Request
                          </Button>
                          <Button
                            variant="contained"
                            onClick={listProductReq}
                            className="bg-white text-dark"
                          >
                            List of requested products
                          </Button>
                        </div>
                      </Stack>
                    </div>
                  </div>
                  {/* Rest of your component */}
                </div>

                <div
                  className="col-12 text-end"
                  style={{ marginBottom: "15px" }}
                ></div>

                <div className="headerSearch">
                  <div className="productsearch">
                    <>
                      <div className="search">
                        <input
                          type="search"
                          value={searchResult}
                          onChange={filter}
                          className="searchTerm"
                          placeholder="Search Product"
                        />
                      </div>
                    </>
                    <div className="seller-listing-p">
                      <button
                        type="submit"
                        className="searchButtonflex btn btn-transparent search"
                        disabled={
                          tags == "" && searchResult == "" ? true : false
                        }
                        onClick={orderPlace}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        type="submit"
                        className="resetButtonflex btn btn-transparent"
                        onClick={reset}
                        value={resetValue}
                        disabled={
                          tags == "" && searchResult == "" ? true : false
                        }
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div class="productitem">
                      {/* {isLoading ||
            (isCompLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ))} */}
                      {foundUsers && foundUsers.length > 0 ? (
                        foundUsers?.map((user) => (
                          <>
                            <div key={user.id}>
                              <div className="itemheading">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={dataObject.some(
                                      (itm) => itm.product_id === user.id
                                    )}
                                    onChange={(e) =>
                                      handleSelectProducts(
                                        e,
                                        user.id,
                                        user.title,
                                        user.categories_id
                                      )
                                    }
                                  />
                                  <h2 className="pro-title">{user.title}</h2>
                                </div>
                              </div>
                              <p className="pro-subheading">
                                {user.description}
                              </p>
                              <hr />
                            </div>
                          </>
                        ))
                      ) : (
                        <h3
                          style={{
                            display: "flex",
                            height: "70vh",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          No products found
                        </h3>
                      )}
                    </div>
                  </div>
                </div>

                {(nextPage || prevPage) &&
                foundUsers &&
                foundUsers?.length > 0 ? (
                  <div className="StylePagination">
                    <button
                      disabled={prevPage == null ? true : false}
                      onClick={() => {
                        setPage(parseInt(prevPage?.split("=")[1]));
                      }}
                      className="previous text-white bg-dark border-dark"
                    >
                      &laquo; Previous
                    </button>
                    <button
                      disabled={nextPage == null ? true : false}
                      className="next text-white bg-dark border-dark"
                      onClick={() => {
                        setPage(parseInt(nextPage?.split("=")[1]));
                      }}
                    >
                      Next &raquo;
                    </button>
                  </div>
                ) : null}
                {foundUsers && foundUsers.length > 0 ? (
                  <div class="btn-addstore">
                    <button
                      onClick={handleSubmitBtn}
                      className="btn btn-dark w-100 mt-4 border-round"
                      disabled={dataObject?.length == 0 ? true : false}
                    >
                      {isLoading ? <Loader /> : null}
                      Add Products to store
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <Footer />
          </LoggedInHeader>
        </>
      )}
    </>
  );
}
