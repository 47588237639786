import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, use } from "react-router-dom";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";
import SideNavigation from "../../components/SideNavigation";
import Button from "react-bootstrap/Button";
import {
  sellerListing,
  getBuyerWantList,
  getCartApi,
  updateCreateCart,
  GetCartExceedQuantity,
} from "../../api/request";
import { useLocation } from "react-router-dom";
import { showErrorMessage, showSuccessMessage } from "../../constants/Toast";
import { hideLoading, showLoading } from "../../redux/slices/LoaderSlice";
import Modal from "react-bootstrap/Modal";

export default function CreateBasket() {
  const location = useLocation();
  const vendorId = location.state && location.state.id;
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { isLoading } = useSelector((state) => state.loader);
  const [searchResult, setSearchResult] = useState("");
  const [show, setShow] = useState(false);
  const [seller, setSeller] = useState("");
  const [buyersWantList, setBuyersWantList] = useState([]);
  const [selectOption, setSelectOption] = useState([]);
  const [perPageData] = useState(10);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [cartData, setCartData] = useState([]);
  const [prevPage, setPrevPage] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [dataObject, setDataObject] = useState([]);
  const [resetValue, setResetValue] = useState(false);
  const [tags, setTags] = React.useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [showCategory, setShowCategory] = useState({});

  useEffect(() => {
    orderPlace();
  }, [page, resetValue, categoryId]);

  useEffect(() => {
    document.title = "Paysah | CreateWantList";
    addSellerProducts();
    getCartApiData();
  }, []);
  useEffect(() => {
    handleCategory();
  }, [showCategory]);

  const getCartApiData = async () => {
    var cart_id = localStorage.getItem("CartSequenceId");
    let data = {
      cart_seq_id: cart_id,
    };
    try {
      dispatch(showLoading());
      let result = await getCartApi(data?.cart_seq_id ? data : null);
      if (result?.success) {
        setCartData(result.data.cart_items);
        localStorage.setItem("cartDataLength", result?.data.cart_items?.length);
        dispatch(hideLoading());
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addSellerProducts = async () => {
    const token = localStorage.getItem("token");
    let data = {
      limit: 1,
    };
    try {
      if (data) {
        let response = await sellerListing(data);
        if (response?.success) {
          if (response?.data?.length > 0) {
            let opts = [];
            response.data.forEach((opt, i) => {
              let dt = { id: opt.id, unit: opt.name };
              setSeller(dt.unit);
              opts.push(dt);
            });
            setSelectOption(opts);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const orderPlace = async (reset = false) => {
    setResetValue(false);
    console.log("Order Details Placed");
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    let data = {
      user_id: vendorId,
      name: searchResult,
      page: page,
      // postcodes: tags ? tags : null,
      page_limit: perPageData,
      buyer_id: user_details?.user_id,
    };
    // let defaultData = {
    //   user_id: sellerId,
    //   name: "",
    //   page: page,
    //   postcodes: "",
    //   page_limit: perPageData,
    //   buyer_id:user_details?.user_id
    // };
    let categoryData = {
      user_id: vendorId,
      name: searchResult,
      page: page,
      category_id: categoryId.join(","),
      // postcodes: tags ? tags : null,
      page_limit: perPageData,
      buyer_id: user_details?.user_id,
    };
    try {
      dispatch(showLoading());
      let result = await getBuyerWantList(categoryData);
      console.log("Order Details", { result });
      if (result?.success) {
        setBuyersWantList(result?.data?.products);
        setCategoryList(result?.data?.categories);
        setPrevPage(result?.data?.page_info?.prev_page_url);
        setNextPage(result?.data?.page_info?.next_page_url);
        dispatch(hideLoading());

        // localStorage.setItem("CartSequenceId", result?.data?.cart_sequence_id);
      } else if (result?.success == false) {
        // showErrorMessage("Seller Product Not Found");
        setBuyersWantList([]);
        dispatch(hideLoading());
      }
    } catch (error) {
      dispatch(hideLoading());
      console.log(error);
    }
  };

  const reset = () => {
    setResetValue(true);
    setTags("");
    setSearchResult("");
  };
  const handleUpdateCreateCart = async (orderData) => {
    var cart_id = localStorage.getItem("CartSequenceId");
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    let dataWithCart = {
      cart_seq_id: cart_id,
      user_id: user_details.user_id,
      cart_items: orderData,
    };
    let dataWithoutCart = {
      user_id: user_details.user_id,
      cart_items: orderData,
    };
    try {
      dispatch(showLoading());
      if (dataWithCart) {
        let resultData = await GetCartExceedQuantity(dataWithCart);
        if (resultData?.data?.quantityExceed == 1) {
          showErrorMessage("Qunatity Limit Exceed");
        } else {
          let result = await updateCreateCart(
            cart_id ? dataWithCart : dataWithoutCart
          );
          if (result?.success) {
            dispatch(hideLoading());
            setShow(true);
            localStorage.setItem(
              "CartSequenceId",
              result?.data?.cart?.cart_seq_id
            );
            getCartApiData();
            setShow(true);
          } else {
            showErrorMessage("Something Went Wrong");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filter = (e) => {
    const keyword = e.target.value;
    setSearchResult(keyword);
  };

  const increaseQuantity = (productId, itemVarientId, price, user_id) => {
    let qty =
      selectedProducts[`${productId}`]?.[`${itemVarientId}`]?.quantity || 1;
    setSelectedProducts({
      ...selectedProducts,
      [`${productId}`]: {
        ...selectedProducts[`${productId}`],
        [`${itemVarientId}`]: {
          supplier_id: user_id,
          product_id: productId,
          variant_id: itemVarientId,
          quantity: qty + 1,
          item_price: price,
        },
      },
    });
  };
  const decreaseQuantity = (productId, itemVarientId, price, user_id) => {
    let qty =
      selectedProducts[`${productId}`]?.[`${itemVarientId}`]?.quantity || 1;
    setSelectedProducts({
      ...selectedProducts,
      [`${productId}`]: {
        ...selectedProducts[`${productId}`],
        [`${itemVarientId}`]: {
          supplier_id: user_id,
          product_id: productId,
          variant_id: itemVarientId,
          quantity: qty - 1,
          item_price: price,
        },
      },
    });
  };

  let data = {};
  const handleAddToCart = (
    productId,
    itemVarientId,
    price,
    user_id,
    avlQty
  ) => {
    setDataObject([]);
    data = {
      supplier_id: user_id,
      product_id: productId,
      variant_id: itemVarientId,
      quantity: selectedProducts[productId]?.[itemVarientId]?.quantity || 1,
      item_price: price,
    };

    setDataObject([data]);
    handleUpdateCreateCart([data]);
  };

  const handleCart = () => {
    localStorage.setItem("CreateBastekData", JSON.stringify(dataObject));
    navigation("/cart");
  };

  const handleClose = () => {
    setShow(false);
    // window.location.reload();
  };

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    const value = event.target.value.trim();
    if (value !== "") {
      setTags([...tags, value]);
      event.target.value = "";
    }
  };
  const handleCategoryPress = (id) => {
    setShowCategory((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const handleCategory = () => {
    // Your logic here using the updated showCategory
    // For example, update categoryId based on the new showCategory state
    const newCategoryId = Object.keys(showCategory).filter(
      (key) => showCategory[key]
    );
    setCategoryId(newCategoryId);
  };
  const handleBack = () => {
    navigation(`/buyerhome`);
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <LoggedInHeader>
          <div className="body-container d-flex pro-subheading">
            <SideNavigation />
            <div className="right-panel">
              <div className="search-header">
                <div className="row justify-content-between">
                  <div className="styleBtn col-2">
                    <button
                      className="back-btn btn-dark w-40"
                      onClick={handleBack}
                    >
                      <i class="fa fa-arrow-circle-left" aria-hidden="true">
                        {" "}
                      </i>
                    </button>
                  </div>
                  <div className="col-8">
                    <h2>
                      <strong>{buyersWantList[0]?.seller_name}</strong>
                    </h2>
                  </div>
                  <div className="col-2 text-right">
                    <div className="basketbtn basket-view-lg ">
                      <button className="cart-btn" onClick={handleCart}>
                        <div className="cart-bubble">
                          {cartData?.length ? cartData?.length : 0}
                        </div>
                        <img src="../assets/images/icon-cart.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-title">
                <div style={{ marginTop: "12px" }}>
                  <div className="headerSearch">
                    <div className="productsearch">
                      {" "}
                      <>
                        <div className="search">
                          <input
                            type="search"
                            value={searchResult}
                            onChange={filter}
                            className="searchTerm pro-subheading"
                            placeholder="Search Product"
                          />
                        </div>
                        <div class="button-items">
                          <button
                            type="submit"
                            className="btn btn-transparent search"
                            disabled={
                              tags == "" && searchResult == "" ? true : false
                            }
                            onClick={orderPlace}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                          <button
                            type="submit"
                            className="resetButtonflex btn btn-transparent pro-subheading"
                            onClick={reset}
                            value={resetValue}
                            disabled={
                              tags == "" && searchResult == "" ? true : false
                            }
                          >
                            <i className="fa fa-refresh"></i>
                          </button>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`d-flex justify-content-start filter-btns scrollable-container`}
                  >
                    {categoryList?.map((list) =>
                      list?.category_name ? (
                        <button
                          key={list?.category_id}
                          onClick={() => handleCategoryPress(list?.category_id)}
                          className={`btn category-btns ${
                            showCategory[list?.category_id]
                              ? "btns border-round"
                              : "btn-dark"
                          } mb-2`}
                        >
                          {list?.category_name}
                        </button>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="card-cart">
                  <div className="basket-table">
                    <div className="table-responsive">
                      {buyersWantList?.length > 0 ? (
                        buyersWantList?.map((order, id) => {
                          return (
                            <>
                              <div className="productitem">
                                <table className="table-borderless order-table wantlist-table buyer-want-list pro-subheading">
                                  <tbody>
                                    <tr>
                                      <td width="100%">
                                        <div className="itemName item-head">
                                          <strong className="pro-subheading">
                                            {order.item_name}{" "}
                                          </strong>{" "}
                                          <div
                                            className="sellername"
                                            style={{
                                              float: "right",
                                              marginLeft: "auto",
                                            }}
                                          ></div>
                                        </div>
                                        <div className="descriptions pro-subheading">
                                          {order.description}
                                        </div>

                                        {/* <div className="sellername" style={{float:"right"}}>
                                        Sold By {order.user.name}
                                      </div> */}
                                        <div className="varient-table variant-des-table pro-subheading">
                                          {/* {order
                                          ?.seller_product_size_option_with_id
                                          ?.length ? (
                                          <tr>
                                            <th align="left">Sizing</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th></th>
                                          </tr>
                                        ) : null} */}
                                          {order?.options &&
                                          order?.options?.length > 0 ? (
                                            order?.options.map((opt, i) => {
                                              return (
                                                <table
                                                  width="100%"
                                                  className="subitems"
                                                >
                                                  <tr>
                                                    <td
                                                      className="col-proprice pro-subheading  multiple-heading"
                                                      width={"50%"}
                                                    >
                                                      <div className="itemName">
                                                        {opt.weight &&
                                                        opt?.weight_unit ? (
                                                          <>
                                                            {" "}
                                                            {opt.weight}&nbsp;
                                                            {opt?.weight_unit}
                                                          </>
                                                        ) : opt.weight ? (
                                                          <> {opt.weight}</>
                                                        ) : opt?.weight_unit ? (
                                                          <>
                                                            {" "}
                                                            {opt?.weight_unit}
                                                          </>
                                                        ) : (
                                                          "-"
                                                        )}

                                                        {opt.price ? (
                                                          <div className="col-proprice pro-subheading">
                                                            £{opt.price}
                                                          </div>
                                                        ) : (
                                                          <div className="col-proprice pro-subheading">
                                                            _
                                                          </div>
                                                        )}
                                                      </div>
                                                    </td>

                                                    <td
                                                      className="col-quantity atlas-create-list pro-subheading"
                                                      width={"50%"}
                                                    >
                                                      <div className="qty-button">
                                                        <div
                                                          className="quantity"
                                                          style={{
                                                            position:
                                                              "relative",
                                                          }}
                                                        >
                                                          <div className="number createBasket-btn">
                                                            <div className="d-flex">
                                                              <div className="quantity-box ">
                                                                <button
                                                                  className="quantity-btn"
                                                                  id={
                                                                    opt.variant_id
                                                                  }
                                                                  onClick={() =>
                                                                    decreaseQuantity(
                                                                      order.seller_product_id,
                                                                      opt.variant_id,
                                                                      opt.price,
                                                                      order.user_id,
                                                                      i
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    selectedProducts[
                                                                      `${opt.seller_product_id}`
                                                                    ]?.[
                                                                      `${opt.variant_id}`
                                                                    ]
                                                                      ?.quantity ==
                                                                      1 ||
                                                                    selectedProducts[
                                                                      `${opt.seller_product_id}`
                                                                    ]?.[
                                                                      `${opt.variant_id}`
                                                                    ]
                                                                      ?.quantity ==
                                                                      undefined
                                                                  }
                                                                >
                                                                  <svg
                                                                    width="20px"
                                                                    height="20px"
                                                                    viewBox="0 0 20 20"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <path
                                                                      d="M4 12H20"
                                                                      stroke="#000000"
                                                                      stroke-width="1.5"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                    />
                                                                  </svg>
                                                                </button>
                                                                <input
                                                                  width="300"
                                                                  type="number"
                                                                  className="quantity input"
                                                                  id={
                                                                    opt.variant_id
                                                                  }
                                                                  value={
                                                                    opt.available_quantity ==
                                                                    0
                                                                      ? 0
                                                                      : selectedProducts[
                                                                          `${opt.seller_product_id}`
                                                                        ]?.[
                                                                          `${opt.variant_id}`
                                                                        ]
                                                                          ?.quantity ||
                                                                        1
                                                                  }
                                                                />
                                                                <button
                                                                  className="quantity-btn"
                                                                  id={
                                                                    opt.variant_id
                                                                  }
                                                                  onClick={() =>
                                                                    increaseQuantity(
                                                                      order.seller_product_id,
                                                                      opt.variant_id,
                                                                      opt.price,
                                                                      order.user_id,
                                                                      i
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    selectedProducts[
                                                                      `${opt.seller_product_id}`
                                                                    ]?.[
                                                                      `${opt.variant_id}`
                                                                    ]
                                                                      ?.quantity >
                                                                    opt.available_quantity
                                                                      ? true
                                                                      : false
                                                                  }
                                                                >
                                                                  <svg
                                                                    width="20px"
                                                                    height="20px"
                                                                    viewBox="0 0 20 20"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                  >
                                                                    <path
                                                                      fill="#000000"
                                                                      fill-rule="evenodd"
                                                                      d="M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z"
                                                                    />
                                                                  </svg>
                                                                </button>{" "}
                                                              </div>
                                                            </div>
                                                          </div>
                                                          {selectedProducts[
                                                            `${opt.seller_product_id}`
                                                          ]?.[
                                                            `${opt.variant_id}`
                                                          ]?.quantity >
                                                          opt.available_quantity ? (
                                                            <div
                                                              className="quantity-alert"
                                                              style={{
                                                                color: "red",
                                                                position:
                                                                  "absolute",
                                                                fontSize:
                                                                  "10px",
                                                                bottom: "-15px",
                                                                left: 0,
                                                                right: 0,
                                                                textAlign:
                                                                  "center",
                                                                transform:
                                                                  "none",
                                                              }}
                                                            >
                                                              Quantity Limit
                                                              Exceed
                                                            </div>
                                                          ) : null}
                                                        </div>

                                                        <div className="col-quantitybtn">
                                                          {" "}
                                                          <button
                                                            className="action-button btn"
                                                            onClick={() =>
                                                              handleAddToCart(
                                                                order.seller_product_id,
                                                                opt.variant_id,
                                                                opt.price,
                                                                order.user_id,

                                                                opt.available_quantity
                                                              )
                                                            }
                                                            disabled={
                                                              selectedProducts[
                                                                `${opt.seller_product_id}`
                                                              ]?.[
                                                                `${opt.variant_id}`
                                                              ]?.quantity >
                                                              opt.available_quantity
                                                                ? true
                                                                : false
                                                            }
                                                          >
                                                            Add
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </table>
                                              );
                                            })
                                          ) : (
                                            <span className="styleSpan">
                                              Variants Not Found
                                            </span>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <h3
                          style={{
                            display: "flex",
                            height: "70vh",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          No Products Found
                        </h3>
                      )}
                      {(nextPage || prevPage) &&
                      buyersWantList &&
                      buyersWantList?.length > 0 ? (
                        <div className="StylePagination">
                          <button
                            disabled={prevPage == null ? true : false}
                            onClick={() => {
                              setPage(parseInt(prevPage?.split("=")[1]));
                            }}
                            className="previous text-white bg-dark border-dark"
                          >
                            &laquo; Previous
                          </button>
                          <button
                            disabled={nextPage == null ? true : false}
                            className="next text-white bg-dark border-dark"
                            onClick={() => {
                              setPage(parseInt(nextPage?.split("=")[1]));
                            }}
                          >
                            Next &raquo;
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {show ? (
            <>
              {" "}
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>PaySah</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Products has been successfully added in your cart.
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary border-round"
                    onClick={handleClose}
                  >
                    Continue Shopping
                  </Button>
                  <Button variant="primary border-round" onClick={handleCart}>
                    Go To Cart
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : null}
          <Footer />
        </LoggedInHeader>
      )}
    </>
  );
}
