import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Logo } from "../../constants/StaticFiles";
import { Sellertemps } from "../../api/request";
import Loader from "../../components/Loader";
import { showSuccessMessage, showErrorMessage } from "../../constants/Toast";
import Footer from "../../components/Footer";

export default function RegisterAsSeller() {
  const { isLoading } = useSelector((state) => state.loader);
  const [tags, setTags] = React.useState([]);

  const [submit, setSubmit] = React.useState(false);
  const [postalCodeError, setPostalCodeError] = React.useState(false);
  const [userDetail, setUserDetail] = React.useState({
    user_id: "",
    name: "",
    business_name: "",
    business_type: "",
    mobile_number: "",
    address: "",
    address_2: "",
    email: "",
    city: "",
    country: "",
    postcodes: [],
    errorFirstName: "",
    errorBusinessName: "",
    errorBusinessType: "",
    errorPhoneNumber: "",
    errorPostalCode: "",
    errorCity: "",
    errorAddress1: "",
    seller_type: "",
    // errorCountry: "",
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const navigation = useNavigate();

  useEffect(() => {
    document.title = "Paysah | SignUp";
  });
  const postalValidator = /^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][A-Za-z]{2}$/;
  // ... (Previous code)
  const handleFieldValidations = (value, type) => {
    let nameValidator = /[A-Za-z]/;
    let phoneValidator =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

    if (type === "name") {
      setUserDetail({
        ...userDetail,
        name: value,
        errorFirstName: nameValidator.test(value) && value.trim() !== "",
      });
    } else if (type === "email") {
      setUserDetail({
        ...userDetail,
        email: value,
        errorEmail: /^\S+@\S+$/i.test(value) && value.trim() !== "",
      });
    } else if (type === "business_name") {
      setUserDetail({
        ...userDetail,
        business_name: value,
        errorBusinessName: nameValidator.test(value) && value.trim() !== "",
      });
    } else if (type === "business_type") {
      setUserDetail({
        ...userDetail,
        business_type: value,
        errorBusinessType: nameValidator.test(value) && value.trim() !== "",
      });
    } else if (type === "mobile_number") {
      setUserDetail({
        ...userDetail,
        mobile_number: value,
        errorPhoneNumber: phoneValidator.test(value) && value.trim() !== "",
      });
    } else if (type === "address") {
      setUserDetail({
        ...userDetail,
        address: value,
        errorAddress1: nameValidator.test(value) && value.trim() !== "",
      });
    } else if (type === "postcodes") {
      const isValidPostalCode = postalValidator.test(value);
      setUserDetail({
        ...userDetail,
        postcodes: value,
        errorPostalCode: !isValidPostalCode,
      });
      setPostalCodeError(!isValidPostalCode);
    } else if (type === "city") {
      setUserDetail({
        ...userDetail,
        city: value,
        errorCity: nameValidator.test(value) && value.trim() !== "",
      });
    } else if (type === "country") {
      setUserDetail({
        ...userDetail,
        country: value,
        // errorCountry: nameValidator.test(value) && value.trim() !== '',
      });
    } else if (type === "seller_type") {
      setUserDetail({
        ...userDetail,
        seller_type: value,
      });
    }
  };

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event, postalValidator) => {
    if (event.key === "Enter") {
      const trimmedValue = event.target.value.trim();
      if (trimmedValue && postalValidator.test(trimmedValue)) {
        setUserDetail((prevUserDetail) => ({
          ...prevUserDetail,
          postcodes: "",
        }));
        setTags([...tags, trimmedValue]);
      }
    }
  };

  const handleSellerType = (e) => {
    console.log("handleSellerType", e.target.value);
  };

  // ... (previous code)
  const submitForm = async (e) => {
    setSubmit(true);
    if (
      userDetail.errorFirstName &&
      userDetail.errorBusinessName &&
      userDetail.errorBusinessType &&
      userDetail.errorPhoneNumber &&
      userDetail.errorEmail &&
      userDetail.errorAddress1 &&
      userDetail.errorCity &&
      userDetail?.name?.trim() !== "" &&
      userDetail?.business_name?.trim() !== "" &&
      userDetail?.business_type?.trim() !== "" &&
      userDetail?.email?.trim() !== "" &&
      userDetail?.mobile_number?.trim() !== "" &&
      userDetail?.address?.trim() !== "" &&
      userDetail?.city?.trim() !== "" &&
      userDetail.seller_type?.trim() !== "" &&
      postalCodeError == false
    ) {
      const data = {
        first_name: userDetail.name,
        business_name: userDetail.business_name,
        business_type: userDetail.business_type,
        mobile_number: userDetail.mobile_number,
        address_1: userDetail.address,
        address_2: userDetail.address_2,
        email: userDetail.email,
        postcodes: [userDetail.postcodes],
        city: userDetail.city,
        country: userDetail.country,
        seller_type: userDetail.seller_type,
      };
      let res = await Sellertemps(data);

      if (res.success) {
        showSuccessMessage(res?.message);
        navigation("/login");
      } else {
        if (res.errors?.seller_type?.[0]) {
          showErrorMessage(res.errors?.seller_type[0]);
        }
        if (res.errors?.email?.[0]) {
          showErrorMessage(res.errors?.email[0]);
        }
        navigation("/vendorregistration");
      }
    } else {
      //   showErrorMessage("Fill all the required fields with valid data");
    }
    // }
  };

  return (
    <>
      <div className="wrapper pro-subheading">
        <div className="container">
          <div className="px-4 py-5 my-5 ">
            <Link to="/">
              {" "}
              <img
                className="d-block mx-auto heading-logo style-logo"
                src={Logo}
                alt=""
              />
            </Link>
            <h2 className="text-center pro-subheading">Vendor Registration</h2>
            <div className="register-form vendor-form">
              <form
                noValidate
                autoComplete="on"
                onSubmit={(e) => {
                  e.preventDefault();
                  // Prevent the default form submission behavior
                  submitForm(e); // Call your custom form submission function
                }}
              >
                <div className="row mb-2 label">
                  <div className="col-sm-12 ">
                    <input
                      className="form-control text-center pro-subheading"
                      placeholder="Name&nbsp;*"
                      type="text"
                      value={userDetail && userDetail?.name}
                      onChange={(e) =>
                        handleFieldValidations(e.target.value, "name")
                      }
                    />
                    <div
                      style={{
                        color: "red",
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      <div
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "14px",
                          padding: "5px",
                          textAlignLast: "center",
                        }}
                      >
                        {userDetail?.errorFirstName === false
                          ? "Please enter a valid name (name cannot be numbers only!)"
                          : submit && userDetail?.name?.trim() === ""
                          ? "Name is required"
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-2 label">
                  <div className="col-sm-12">
                    <input
                      className="form-control text-center pro-subheading"
                      type="text"
                      placeholder="Business Name&nbsp;*"
                      value={userDetail && userDetail?.business_name}
                      onChange={(e) =>
                        handleFieldValidations(e.target.value, "business_name")
                      }
                    />
                    <div
                      style={{
                        color: "red",

                        fontSize: "14px",
                        padding: "5px",
                        textAlign: "center",
                      }}
                      className="pro-subheading"
                    >
                      {userDetail?.errorBusinessName === false
                        ? "Please enter a valid business name (business name cannot be numbers only!)"
                        : submit && userDetail?.business_name?.trim() === ""
                        ? "Business name is required"
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row mb-4 label">
                  <div className="col-sm-12">
                    <input
                      className="form-control  text-center pro-subheading"
                      type="text"
                      placeholder="Business Type&nbsp;*"
                      value={userDetail && userDetail?.business_type}
                      onChange={(e) =>
                        handleFieldValidations(e.target.value, "business_type")
                      }
                    />
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "5px",
                      }}
                    >
                      {userDetail?.errorBusinessType === false
                        ? "Please enter a valid type (name cannot be numbers only!)"
                        : submit && userDetail?.business_type?.trim() === ""
                        ? "Business type is required"
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="row mb-2 label">
                  <div className="col-sm-12">
                    <input
                      className="form-control text-center pro-subheading"
                      type="email"
                      placeholder="Email&nbsp;*"
                      value={userDetail && userDetail?.email}
                      onChange={(e) =>
                        handleFieldValidations(e.target.value, "email")
                      }
                    />
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "5px",
                      }}
                    >
                      {" "}
                      {userDetail?.errorEmail === false
                        ? "Please enter a valid email (name cannot be numbers only!)"
                        : submit && userDetail?.email?.trim() === ""
                        ? "Email is required"
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row mb-2 label">
                  <div className="col-sm-12">
                    <input
                      className="form-control  text-center pro-subheading"
                      type="number"
                      placeholder="Mobile Number&nbsp;*"
                      value={userDetail && userDetail?.mobile_number}
                      onChange={(e) =>
                        handleFieldValidations(e.target.value, "mobile_number")
                      }
                    />
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "5px",
                      }}
                    >
                      {" "}
                      {userDetail?.errorPhoneNumber === false
                        ? "Please enter a valid mobile number"
                        : submit && userDetail?.mobile_number?.trim() === ""
                        ? "Phone number is required"
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="row mb-2 label">
                  <div className="col-sm-12">
                    <input
                      className="form-control  text-center pro-subheading"
                      placeholder="Address Line 1&nbsp;*"
                      type="text"
                      value={userDetail && userDetail?.address}
                      onChange={(e) =>
                        handleFieldValidations(e.target.value, "address")
                      }
                    />
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "5px",
                      }}
                    >
                      {" "}
                      {userDetail?.errorAddress1 === false
                        ? "Please enter a valid address (address cannot be numbers only!)"
                        : submit && userDetail?.address?.trim() === ""
                        ? "Address line 1 is required"
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    {/* <label htmlFor="address_2" className="form-label">
                  Address line 2
                </label> */}
                    <textarea
                      className="form-control  text-center pro-subheading"
                      placeholder="Address Line 2"
                      onChange={(e) => {
                        setUserDetail({
                          ...userDetail,
                          address_2: e.target.value,
                        });
                      }}
                      value={userDetail && userDetail?.address_2}
                    />
                  </div>
                </div>
                <div className="row mb-2 label">
                  <div className="col-sm-12">
                    <input
                      className="form-control pro-subheading text-center"
                      type="text"
                      placeholder="Town/City&nbsp;*"
                      value={userDetail && userDetail?.city}
                      onChange={(e) =>
                        handleFieldValidations(e.target.value, "city")
                      }
                    />
                    <div
                      style={{
                        color: "red",

                        padding: "5px",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    >
                      {userDetail?.errorCity === false
                        ? "Please enter a valid city (name cannot be numbers only!)"
                        : submit && userDetail?.city?.trim() === ""
                        ? "Town/City is required"
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <input
                      className="form-control pro-subheading text-center"
                      type="text"
                      placeholder="Country"
                      value={userDetail && userDetail?.country}
                      onChange={(e) =>
                        handleFieldValidations(e.target.value, "country")
                      }
                    />
                  </div>
                </div>
                <div className="tags-input-profile text-align">
                  {tags.length > 0
                    ? tags?.map((tag, index) => (
                        <li key={index} className="tag">
                          <span className="tag-title">{tag}</span>
                          <span
                            className="tag-close-icon"
                            onClick={() => removeTags(index)}
                          >
                            x
                          </span>
                        </li>
                      ))
                    : null}
                  <input
                    type="text"
                    className="form-control pro-subheading text-center"
                    value={userDetail?.postcodes}
                    // onKeyUp={(event) => addTags(event, postalValidator)}
                    placeholder="Postal Code*"
                    maxLength={9}
                    onChange={(e) => {
                      const { value } = e.target;
                      setUserDetail((prevUserDetail) => ({
                        ...prevUserDetail,
                        postcodes: value,
                      }));
                      handleFieldValidations(value, "postcodes");
                    }}
                  />
                  <div
                    className="text-center validation"
                    style={{
                      color: "red",
                      // padding: "5px",
                      fontSize: "14px",
                      textAlign: "center !important",
                      display: "block",
                      margin: "auto",
                    }}
                  >
                    {postalCodeError
                      ? "Please enter a valid postal code "
                      : submit && userDetail?.postcodes?.length === 0
                      ? "Postal code is required"
                      : ""}
                  </div>
                  {/* {!postalCodeError && userDetail?.postcodes?.length > 0 ? (
                 <div className="validation"
                  style={{
                    color: "red",
                    fontSize: 15,
                    padding: "5px",
                    textAlign: "center !important",
                    display:"block",
                    margin:"auto"
                
                  }}
                >
                  Press enter to add postal code
              ) : null} */}
                </div>
                <div className="tags-input-profile text-align">
                  <select
                    class="form-control"
                    name="seller_type"
                    id="seller_type"
                    required
                    onChange={(e) =>
                      handleFieldValidations(e.target.value, "seller_type")
                    }
                  >
                    <option value="">Select Option*</option>
                    <option value="Type I">
                      Type I Seller (Ordinary Seller)
                    </option>
                    <option selected="" value="Type II">
                      Type II Seller (Pick up or Deliver to My Address Seller)
                    </option>
                  </select>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-12">
                    {/* {isLoading ? (
                  <Loader />
                ) : ( */}
                    <button
                      className="btn btn-dark w-100 pro-subheading"
                      type="submit"
                      disabled={!isValid || isLoading}
                    >
                      {isLoading ? <Loader /> : null}
                      Register
                    </button>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-sm-12 register-link">
                    <p className="text-center pro-subheading">
                      Already have an account? <Link to="/login">Log In</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
