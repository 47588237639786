import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import SideNavigation from "../../components/SideNavigation";
import { sellerListing } from "../../api/request";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function BuyerHome() {
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.loader);
  const [cartItems, setCartItems] = useState({});
  const [dataObject, setDataObject] = useState({});
  const [selectedProducts, setSelectedProducts] = useState({});
  const [redeemQtyV, setRedeemQtyV] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [prevPage, setPrevPage] = useState(null);
  const [perPageData] = useState(10);
  const [searchResult, setSearchResult] = useState("");
  const [postCodeSearchResult, setPostCodeSearchResult] = useState("");
  const [resetValue, setResetValue] = useState(false);
  const [cartLength, setCartLength] = useState();

  useEffect(() => {
    document.title = "Paysah | BuyerHome";

    orderPlace();
  }, [page, resetValue]);
  const keys = Object.keys(cartItems);
  let allSellers = [];
  keys.map((key) => {
    const sellerData = cartItems[key];
    allSellers.push(sellerData);
  });
  const sellerKeys = Object.keys(allSellers);
  let sellerValues = [];
  sellerKeys.map((key) => {
    const sellerData = allSellers[key];
    sellerValues.push(sellerData);
  });
  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };
  const handleOkayBtn = () => {
    setShow(false);
    window.location.reload();
  };

  const orderPlace = async (reset = false) => {
    setCartLength(localStorage.getItem("cartDataLength"));
    setResetValue(false);
    try {
      let data = {
        page: page,
        limit: perPageData,
        name: searchResult,
        postcode: postCodeSearchResult,
      };
      let searchTerm = {
        page: 1,
        limit: perPageData,
        name: searchResult,
        postcode: postCodeSearchResult,
      };

      let result = await sellerListing(
        searchResult || postCodeSearchResult ? searchTerm : data
      );
      if (result?.success) {
        if (result?.data) {
          setCartItems(result?.data?.seller_data);
          setPrevPage(result?.data?.pagination?.prev_page_url);
          setNextPage(result?.data?.pagination?.next_page_url);
        }
      } else if (result?.success == false) {
        setCartItems([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const filter = (e) => {
    const keyword = e.target.value;
    setSearchResult(keyword);
  };
  const filterPostCode = (e) => {
    const keyword = e.target.value;
    setPostCodeSearchResult(keyword);
  };

  const reset = () => {
    setResetValue(true);
    setSearchResult("");
    setPostCodeSearchResult("");
  };

  const handleClickVendor = async (e, id) => {
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    setResetValue(false);
    navigate("/createWantList", { state: { id } });
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />
              <div className="right-panel">
                <h2>
                  <strong className="pro-subheading">Stores</strong>
                </h2>
                <div className="headerSearch">
                  <div className="productsearch storesearch">
                    <div className="search">
                      <input
                        type="search"
                        value={searchResult}
                        onChange={filter}
                        className="searchTerm"
                        placeholder="Search name"
                      />
                    </div>
                    <div className="search">
                      <input
                        type="search"
                        value={postCodeSearchResult}
                        onChange={filterPostCode}
                        className="searchTerm"
                        placeholder="Search postcode"
                      />
                    </div>
                    <div className="seller-listing-p">
                      <button
                        type="submit"
                        className="btn btn-transparent search"
                        onClick={orderPlace}
                        disabled={
                          searchResult == "" && postCodeSearchResult == ""
                            ? true
                            : false
                        }
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        type="submit"
                        className="resetButtonflex btn btn-transparent"
                        onClick={reset}
                        value={resetValue}
                        disabled={
                          searchResult == "" && postCodeSearchResult == ""
                            ? true
                            : false
                        }
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                      {/* <div className="basketbtn basket-view-lg">
                            <button className="cart-btn" >
                              <div className="cart-bubble">
                                {cartLength ? cartLength : 0}
                              </div>
                              <img
                                src="../assets/images/icon-cart.svg"
                                alt=""
                              />
                            </button>
                          </div> */}
                    </div>
                  </div>
                </div>
                {cartItems && cartItems?.length > 0 ? (
                  cartItems.map((values) => {
                    return (
                      <>
                        <div className="card havelist-card shadow-sm">
                          <div
                            className="card-body have-card"
                            onClick={(e) => handleClickVendor(e, values?.id)}
                          >
                            <div>
                              <div
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                className="company-logo"
                              >
                                {values?.logo ? (
                                  <img src={values?.logo} />
                                ) : null}
                                <div className="page-title ref-name">
                                  <h2
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      handleClickVendor(e, values?.id)
                                    }
                                  >
                                    {values?.name}
                                  </h2>
                                  <p className="stleref">
                                    {" "}
                                    {values?.reference_number != null &&
                                    values?.reference_number !== undefined
                                      ? `Reference No.- ${values?.reference_number}`
                                      : null}
                                  </p>
                                </div>
                              </div>

                              <div
                                class="totalprice"
                                onClick={(e) =>
                                  handleClickVendor(e, values?.id)
                                }
                              >
                                {/* <span style={{ cursor: "pointer" }}>
                                  View Products
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="address">
                          <span>
                            {values?.address_1 +
                              (values?.address_2
                                ? ", " + values?.address_2
                                : "")}
                            {values?.postcode ? ", " + values?.postcode : ""}
                          </span>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <h3
                    style={{
                      display: "flex",
                      height: "70vh",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Seller List Found
                  </h3>
                )}
                {(nextPage || prevPage) &&
                cartItems &&
                cartItems?.length > 0 ? (
                  <>
                    {" "}
                    <div className="StylePagination">
                      <button
                        disabled={prevPage == null}
                        onClick={() => {
                          setPage(parseInt(prevPage?.split("=")[1]));
                        }}
                        className="previous text-white bg-dark border-dark"
                      >
                        &laquo; Previous
                      </button>
                      <button
                        disabled={nextPage == null}
                        onClick={() => {
                          setPage(parseInt(nextPage?.split("=")[1]));
                        }}
                        className="next text-white bg-dark border-dark"
                      >
                        Next &raquo;
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </LoggedInHeader>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>PaySah</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p
                style={{
                  color: "red",
                }}
              >
                {error}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleOkayBtn}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer />
        </>
      )}
    </>
  );
}
