import React, { useEffect, useState } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import SideNavigation from "../components/SideNavigation";
import {
  ViewOrderDetailsStockRoom,
  ViewOrderDetailsStockRoomSellerWise,
  redeemOrder,
  BuyerOrderlistseller,
} from "../api/request";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import LoggedInHeader from "../components/LoggedInHeader";
import { showErrorMessage, showInformation } from "../constants/Toast";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";

export default function HaveList() {
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.loader);
  const [cartItems, setCartItems] = useState({});
  const [selectedQuantity, setSelectQuantity] = useState({});
  const [dataObject, setDataObject] = useState({});
  const [code, setCode] = useState();
  const [apiResponse, setApiResponse] = useState();
  const [detailRedeemedOrderItems, setDetailRedeemedOrderItems] = useState([]);
  const [sellerId, setSellerId] = useState(null);
  const [orderId, setOrderId] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [redeemQtyV, setRedeemQtyV] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [prevPage, setPrevPage] = useState(null);
  const [perPageData] = useState(10);
  const [searchResult, setSearchResult] = useState("");
  const [postCodeSearchResult, setPostCodeSearchResult] = useState("");
  const [resetValue, setResetValue] = useState(false);
  const [tags, setTags] = React.useState([]);

  useEffect(() => {
    document.title = "Paysah | My-Stockroom";
    orderPlace();
  }, [page, resetValue]);

  const increaseQuantity = (UniqueId, itemVarientId, seller_Id, ordeItemId) => {
    let latestData = dataObject[seller_Id] || [];
    let redeemQty =
      selectedProducts[`${UniqueId}`]?.[`${itemVarientId}`]?.redeem || 0;
    setSelectedProducts({
      ...selectedProducts,
      [`${UniqueId}`]: {
        ...selectedProducts[`${UniqueId}`],
        [`${itemVarientId}`]: {
          redeem: redeemQty + 1,
        },
      },
    });

    const variantIndex = latestData.findIndex(
      (obj) => obj.order_item_id == ordeItemId
    );
    if (variantIndex !== -1) {
      dataObject[seller_Id][variantIndex].redeem = parseInt(redeemQty) + 1;
    }
    if (
      parseInt(redeemQty) + 1 > 0 &&
      redeemQtyV.includes(parseInt(ordeItemId))
    ) {
      let selectedItemIndex = redeemQtyV.indexOf(ordeItemId);
      if (selectedItemIndex > -1) {
        redeemQtyV.splice(selectedItemIndex, 1);
        setRedeemQtyV([...new Set(redeemQtyV)]);
      }
    }
  };

  const decreaseQuantity = (UniqueId, itemVarientId, seller_Id, ordeItemId) => {
    let latestData = dataObject[seller_Id] || [];
    let redeemQty =
      selectedProducts[`${UniqueId}`]?.[`${itemVarientId}`]?.redeem || 0;
    setSelectedProducts({
      ...selectedProducts,
      [`${UniqueId}`]: {
        ...selectedProducts[`${UniqueId}`],
        [`${itemVarientId}`]: {
          redeem: redeemQty - 1,
        },
      },
    });

    const variantIndex = latestData.findIndex(
      (obj) => obj.order_item_id == ordeItemId
    );
    if (variantIndex !== -1) {
      dataObject[seller_Id][variantIndex].redeem = parseInt(redeemQty) - 1;
    }
    if (parseInt(redeemQty) - 1 <= 0 && !redeemQtyV.includes(ordeItemId)) {
      redeemQtyV.push(ordeItemId);
      setRedeemQtyV([...new Set(redeemQtyV)]);
    }
  };

  const handleChangeItems = (
    e,
    seller_Id,
    product_id,
    variant_id,
    supplier_id,
    order_item_id,
    oID,
    UniqueId
  ) => {
    const { checked } = e.target;
    let dt_redeem = selectedProducts[e.target.dataset.uniqueRowIdentifier]?.[
      e.target.dataset.variantId
    ]?.redeem
      ? selectedProducts[e.target.dataset.uniqueRowIdentifier]?.[
          e.target.dataset.variantId
        ]?.redeem
      : 0;

    let obj = {
      supplier_id: e.target.dataset.supplierId,
      product_id: e.target.dataset.productId,
      variant_id: e.target.dataset.variantId,
      total_quantity: e.target.dataset.totalQuantity,
      delivered_quantity: e.target.dataset.deliveredQuantity,
      item_price: e.target.dataset.price,
      redeem: dt_redeem,
      order_item_id: order_item_id,
    };

    var mainExistingData = dataObject[seller_Id] || [];
    if (checked) {
      // let sIds = [...sellerId, seller_Id];
      // let oIds = [...sellerId, oID];
      // setSellerId([...new Set(sIds)]);
      // setOrderId([...new Set(oIds)]);
      setSellerId({ ...sellerId, [`${seller_Id}_${oID}`]: oID });
      setDataObject({ ...dataObject, [seller_Id]: [...mainExistingData, obj] });
      if (dt_redeem === 0 && !redeemQtyV.includes(variant_id)) {
        redeemQtyV.push(variant_id);
        setRedeemQtyV([...new Set(redeemQtyV)]);
      }
    } else {
      mainExistingData = mainExistingData.filter(
        (i) => i.order_item_id != order_item_id
      );
      setDataObject({ ...dataObject, [seller_Id]: [...mainExistingData] });
      const newData = JSON.parse(JSON.stringify(sellerId));
      delete newData[`${seller_Id}_${oID}`];
      setSellerId(newData);
    }
  };

  const keys = Object.keys(cartItems);
  let allSellers = [];
  keys.map((key) => {
    const sellerData = cartItems[key];
    allSellers.push(sellerData);
  });

  const sellerKeys = Object.keys(allSellers);
  let sellerValues = [];
  sellerKeys.map((key) => {
    const sellerData = allSellers[key];
    sellerValues.push(sellerData);
  });

  const handleRedeem = async (sId, oID, QrCode) => {
    setDetailRedeemedOrderItems();
    let redeemHistory = [];
    let payload = dataObject[sId] || [];
    let updatedData = [];
    updatedData = payload.filter((item) => item.supplier_id !== sId);
    let user_details = JSON.parse(localStorage.getItem("user_details"));
    let data = {
      user_id: user_details.user_id,
      seller_id: sId,
      code: QrCode,
      order_items: updatedData,
    };
    let result = await redeemOrder(data);
    console.log("result", result);
    try {
      if (result?.success) {
        const redeemKeys = Object.keys(result?.data?.redeem_history);
        let lastIndexKey = redeemKeys[redeemKeys.length - 1];
        redeemKeys.map((key) => {
          const sellerRedeemData = result?.data?.redeem_history[key];

          redeemHistory.push(sellerRedeemData);
        });
        if (redeemHistory.length > 0) {
          setDetailRedeemedOrderItems(redeemHistory);

          Swal.fire({
            imageUrl:
              "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
              `${result?.data?.image_name_new_generated}`,
            imageWidth: 700,
            imageHeight: 400,
            imageAlt: "Custom image",
            confirmButtonText: "Ok",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          setDetailRedeemedOrderItems(detailRedeemedOrderItems);
        }
      } else {
        console.log("error there!");
        setError(result?.data);
        setShow(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  const handleOkayBtn = () => {
    setShow(false);
    window.location.reload();
  };

  // const handlePopup = (imgName) => {
  //   Swal.fire({
  //     imageUrl:
  //       "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
  //       `${imgName}`,
  //     imageWidth: 700,
  //     imageHeight: 400,
  //     imageAlt: "Custom image",
  //     confirmButtonText: "Cancel",
  //   });
  // };

  const orderPlace = async () => {
    let user_details = await JSON.parse(localStorage.getItem("user_details"));

    setResetValue(false);
    let viewRedeemHistory = [];

    try {
      let data = {
        buyer_id: user_details?.user_id,
        page: page,
        limit: perPageData,
        name: searchResult,
        postcode: postCodeSearchResult,
      };
      let searchTerm = {
        buyer_id: user_details?.user_id,
        page: 1,
        limit: perPageData,
        name: searchResult,
        postcode: postCodeSearchResult,
      };

      let result = await BuyerOrderlistseller(
        searchResult || postCodeSearchResult ? searchTerm : data
      );

      if (result?.success) {
        if (result?.data) {
          setCartItems(result?.data?.seller_data);
          // setApiResponse(result?.data);
          // setCode(result?.data?.code);
          setPrevPage(result?.data?.pagination?.prev_page_url);
          setNextPage(result?.data?.pagination?.next_page_url);
          // localStorage.setItem("code", result?.data?.code);
          // localStorage.setItem("sellerQr_image_name", result?.data?.image_name);

          // if (result?.data?.redeem_history) {
          //   const viewRedeemKeys = Object.keys(result?.data?.redeem_history);
          //   viewRedeemKeys.map((key) => {
          //     const viewsellerRedeemData = result?.data?.redeem_history[key];
          //     viewRedeemHistory.push(viewsellerRedeemData);
          //   });
          //   setDetailRedeemedOrderItems(viewRedeemHistory);
          // } else {
          //   // Handle the case when redeem_history is not available
          //   setDetailRedeemedOrderItems([]);
          // }
        }
      } else if (result?.success == false) {
        setCartItems([]);
        // showErrorMessage(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const filter = (e) => {
    const keyword = e.target.value;
    setSearchResult(keyword);
  };
  const filterPostCode = (e) => {
    const keyword = e.target.value;
    setPostCodeSearchResult(keyword);
  };

  const reset = () => {
    setResetValue(true);
    setSearchResult("");
    setPostCodeSearchResult("");
  };

  const handleClickVendor = (e, id, name) => {
    navigate("/OrderListPage", { state: { id, name } });
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />
              <div className="right-panel">
                <h2>
                  <strong className="pro-subheading">Stockroom</strong>
                </h2>
                <div className="headerSearch">
                  <div className="productsearch storesearch">
                    <div className="search">
                      <input
                        type="search"
                        value={searchResult}
                        onChange={filter}
                        className="searchTerm"
                        placeholder="Search name"
                      />
                    </div>
                    <div className="search">
                      <input
                        type="search"
                        value={postCodeSearchResult}
                        onChange={filterPostCode}
                        className="searchTerm"
                        placeholder="Search postcode"
                      />
                    </div>
                    <div className="seller-listing-p">
                      <button
                        type="submit"
                        className="btn search btn-transparent"
                        onClick={orderPlace}
                        disabled={
                          searchResult == "" && postCodeSearchResult == ""
                            ? true
                            : false
                        }
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        type="submit"
                        className="resetButtonflex btn btn-transparent"
                        onClick={reset}
                        value={resetValue}
                        disabled={
                          searchResult == "" && postCodeSearchResult == ""
                            ? true
                            : false
                        }
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
                {cartItems && cartItems.length > 0 ? (
                  cartItems.map((values) => {
                    return (
                      <>
                        <div className="card havelist-card shadow-sm">
                          <div className="card-body have-card">
                            <div>
                              <div
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                className="company-logo"
                              >
                                {values?.logo ? (
                                  <img
                                    // onClick={(e) => handlePopup(values?.logo)}
                                    src={values?.logo}
                                    // src={
                                    //   "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
                                    //   `${values?.logo}`
                                    // }
                                  />
                                ) : null}
                                <div className="page-title ref-name">
                                  <h2
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      handleClickVendor(e, values?.id)
                                    }
                                  >
                                    {values?.name}
                                  </h2>
                                  <p className="stleref">
                                    {" "}
                                    {values?.reference_number != null &&
                                    values?.reference_number !== undefined
                                      ? `Reference No.- ${values?.reference_number}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                              <div
                                class="totalprice"
                                onClick={(e) =>
                                  handleClickVendor(e, values?.id)
                                }
                              >
                                <span style={{ cursor: "pointer" }}>
                                  View Orders
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="address">
                          <span>
                            {values?.address_1 +
                              (values?.address_2
                                ? ", " + values?.address_2
                                : "")}{" "}
                            {values?.postcode ? ", " + values?.postcode : ""}
                          </span>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <h3
                    style={{
                      display: "flex",
                      height: "70vh",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Seller List Found
                  </h3>
                )}
                {(nextPage || prevPage) &&
                cartItems &&
                cartItems?.length > 0 ? (
                  <>
                    {" "}
                    <div className="StylePagination">
                      <button
                        disabled={prevPage == null}
                        onClick={() => {
                          setPage(parseInt(prevPage?.split("=")[1]));
                        }}
                        className="previous text-white bg-dark border-dark"
                      >
                        &laquo; Previous
                      </button>
                      <button
                        disabled={nextPage == null}
                        onClick={() => {
                          setPage(parseInt(nextPage?.split("=")[1]));
                        }}
                        className="next text-white bg-dark border-dark"
                      >
                        Next &raquo;
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </LoggedInHeader>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>PaySah</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p
                style={{
                  color: "red",
                }}
              >
                {error}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleOkayBtn}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer />
        </>
      )}
    </>
  );
}
