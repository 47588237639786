import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const RedirectIfInactive = ({ timeout, loginPath }) => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      navigate(loginPath);
      localStorage.clear();
      window.location.reload();
      window.location.href = loginPath;
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    }, timeout);

    setTimer(newTimer);
  };

  // const confirmRedirect = () => {
  //   setShowPopup(false);

  //   clearTimeout(timer);
  // };

  useEffect(() => {
    resetTimer();

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      clearTimeout(timer);
    };
  }, []);

  // return (
  //   <div>
  //     {localStorage.getItem("token") !== null && showPopup && (
  //       <Modal
  //         show={showPopup}
  //         onHide={() => setShowPopup(false)}
  //         backdrop="static"
  //         keyboard={false}
  //       >
  //         <Modal.Header closeButton>
  //           <Modal.Title>PaySah</Modal.Title>
  //         </Modal.Header>
  //         <Modal.Body>
  //           <p
  //             style={{
  //               color: "red",
  //             }}
  //           >
  //             Your session has expired due to inactivity.
  //           </p>
  //         </Modal.Body>
  //         <Modal.Footer>
  //           <Button variant="primary" onClick={confirmRedirect}>
  //             Leave
  //           </Button>

  //           <Button
  //             variant="primary"
  //             onClick={() => {
  //               setShowPopup(false);
  //               resetTimer();
  //             }}
  //           >
  //             Continue
  //           </Button>
  //         </Modal.Footer>
  //       </Modal>
  //     )}
  //   </div>
  // );
};

export default RedirectIfInactive;
