import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  Login,
  SignUp,
  Forgot,
  Intro,
  EmailTemplate,
  RegisterAsSeller,
} from "../pages/Auth";
import {
  OrderHistory,
  OrderDetails,
  BuyerItemList,
  BuyerQRCode,
  NotificationBuyer,
  NotificationSeller,
  ReactivationHistoryBuyer,
  ReactivationHistorySeller,
  StatsSeller,
} from "../pages/Order";
import { SellerHome, BuyerHome, SellerDetails } from "../pages/Dashboard";
import SellerOrderDetails from "./SellerOrderDetails";
import { SellerProfile } from "../pages/Profile";
import { BuyerProfile } from "../pages/Profile";
import { ProtectedSeller } from "./ProtectedSeller";
import { ProtectedBuyer } from "./ProtectedBuyer";
import Notfound from "../utils/Notfound";

import AuthProtected from "./AuthProtected";
import { ProtectedIntro } from "./ProtectedIntro";
import ProtectedOrders from "./ProtectedOrder";
import AddProduct from "./AddProduct";
import EditProducts from "./EditProducts";
import SellerProductList from "./SellerProductList";
import CreateBasket from "../pages/Dashboard/CreateBasket";
import Forbidden from "../components/Forbidden";
import AccountVerification from "../pages/Auth/AccountVerification";
import Cart from "../pages/Dashboard/Cart";
import HaveListListing from "../routing/HaveListListing";
import HaveListdetails from "../routing/HaveListdetails";
import ThankyouPage from "../routing/ThankyouPage";
import LoginVerifySucess from "../routing/LoginVerifySucess";
import LoginverifyFail from "../routing/LoginverifyFail";
import ViewdetailsSeller from "./ViewdetailsSeller";
import HaveList from "../routing/Havelist";
import RedeemHistory from "../routing/RedeemHistory";
import { Redeem } from "@material-ui/icons";
import ResetPassword from "../pages/Auth/ResetPassword";
import SelectedProductList from "./SelectedProductList";
import RequestedProductList from "./RequestedProductList";
import OrderListPage from "../pages/Order/OrderListPage";

export default function Navigation() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedIntro>
            <Login />
          </ProtectedIntro>
        }
      />
      <Route
        path="/sellerhome"
        element={
          <ProtectedSeller>
            <SellerHome />
          </ProtectedSeller>
        }
      />
      <Route
        path="/buyerhome"
        element={
          <ProtectedBuyer>
            <BuyerHome />
          </ProtectedBuyer>
        }
      />
      <Route
        path="/OrderListPage"
        element={
          <ProtectedBuyer>
            <OrderListPage />
          </ProtectedBuyer>
        }
      />
      <Route
        path="/buyercode"
        element={
          <ProtectedBuyer>
            <BuyerQRCode />
          </ProtectedBuyer>
        }
      />
      <Route
        path="/buyerlist"
        element={
          <ProtectedBuyer>
            <BuyerItemList />
          </ProtectedBuyer>
        }
      />
      <Route
        path="/email_verify"
        element={
          //   <ProtectedAccount>
          <AccountVerification />
          //    </ProtectedAccount>
        }
      />
      <Route
        path="/createWantList"
        element={
          <ProtectedBuyer>
            <CreateBasket />
          </ProtectedBuyer>
        }
      />
      <Route
        path="/cart"
        element={
          <ProtectedBuyer>
            <Cart />
          </ProtectedBuyer>
        }
      />
      <Route
        path="/stockroom"
        element={
          <ProtectedBuyer>
            <HaveListListing />
          </ProtectedBuyer>
        }
      />
      <Route
        path="/my-stockroom"
        element={
          <ProtectedBuyer>
            <HaveList />
          </ProtectedBuyer>
        }
      />
      <Route
        path="/redeemhistory"
        element={
          <ProtectedBuyer>
            <RedeemHistory />
          </ProtectedBuyer>
        }
      />
      <Route element={<AuthProtected />}>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/vendorregistration" element={<RegisterAsSeller />} />
        <Route path="/forgotpassword" element={<Forgot />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} />
        <Route path="/email-template" element={<EmailTemplate />} />
        <Route path="/verify_success" element={<LoginVerifySucess />} />
        <Route path="/verify_fail" element={<LoginverifyFail />} />
      </Route>
      <Route
        path="/newProductRequest"
        element={
          <ProtectedSeller>
            <AddProduct />
          </ProtectedSeller>
        }
      />
      <Route
        path="/sellerdetails"
        element={
          <ProtectedSeller>
            <SellerDetails />
          </ProtectedSeller>
        }
      />
      <Route
        path="/selectedProductList"
        element={
          <ProtectedSeller>
            <SelectedProductList />
          </ProtectedSeller>
        }
      />
      <Route
        path="/reqProductList"
        element={
          <ProtectedSeller>
            <RequestedProductList />
          </ProtectedSeller>
        }
      />
      <Route
        path="/sellerProductList"
        element={
          <ProtectedSeller>
            <SellerProductList />
          </ProtectedSeller>
        }
      />
      <Route
        path="/editProducts/:id"
        element={
          <ProtectedSeller>
            <EditProducts />
          </ProtectedSeller>
        }
      />
      <Route path="/stockroomdetails/:id" element={<HaveListdetails />} />
      <Route
        path="/orderdetails/:id"
        element={
          <ProtectedSeller>
            <SellerOrderDetails />
          </ProtectedSeller>
        }
      />
      <Route path="/sellerviewDetails/:id" element={<ViewdetailsSeller />} />
      <Route path="/thankyouPage" element={<ThankyouPage />} />
      <Route
        path="/orderhistory"
        element={
          <ProtectedOrders>
            <OrderHistory />
          </ProtectedOrders>
        }
      />
      <Route
        path="/orderdetails/:id"
        element={
          <ProtectedOrders>
            <OrderDetails />
          </ProtectedOrders>
        }
      />
      <Route
        path="/sellerprofile"
        element={
          <ProtectedOrders>
            <SellerProfile />
          </ProtectedOrders>
        }
      />
      <Route
        path="/buyerprofile"
        element={
          <ProtectedOrders>
            <BuyerProfile />
          </ProtectedOrders>
        }
      />

      <Route
        path="/buyernotification"
        element={
          <ProtectedOrders>
            <NotificationBuyer />
          </ProtectedOrders>
        }
      />

      <Route
        path="/sellernotification"
        element={
          <ProtectedOrders>
            <NotificationSeller />
          </ProtectedOrders>
        }
      />

      <Route
        path="/reactivationhistorybuyer"
        element={
          <ProtectedOrders>
            <ReactivationHistoryBuyer />
          </ProtectedOrders>
        }
      />

      <Route
        path="/reactivationhistoryseller"
        element={
          <ProtectedOrders>
            <ReactivationHistorySeller />
          </ProtectedOrders>
        }
      />
      <Route
        path="/sellerstats"
        element={
          <ProtectedOrders>
            <StatsSeller />
          </ProtectedOrders>
        }
      />

      <Route path="*" element={<Notfound />} />
      <Route path="/forbidden" element={<Forbidden />} />
    </Routes>
    // </ErrorBoundry>
  );
}
