import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showSellerProductList, removeSellerProduct } from "../api/request";
import SideNavigation from "../components/SideNavigation";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import LoggedInHeader from "../components/LoggedInHeader";
import { clearWantList } from "../redux/slices/QuantitySlice";
import { ReactComponent as DeleteIcon } from "../assets/images/icon-delete.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Swal from "sweetalert2";
import Alert from "@mui/material/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { showErrorMessage, showSuccessMessage } from "../constants/Toast";

export default function SelectedProductList() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [foundUsers, setFoundUsers] = useState([]);
  const [searchResult, setSearchResult] = useState("");
  const { isLoading } = useSelector((state) => state.loader);
  const navigation = useNavigate();
  const [orders, setOrders] = useState([]);
  const [perPageData] = useState(10);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [deleteProducts, setDeleteProducts] = useState([]);
  const [prevPage, setPrevPage] = useState(null);
  const [resetValue, setResetValue] = useState(false);
  const [tags, setTags] = React.useState([]);
  useEffect(() => {
    orderPlace();
  }, [page, resetValue]);

  const orderPlace = () => {
    let pageTitle =
      localStorage.getItem("user_role") === "Buyer"
        ? "BuyerProductListing"
        : "SellerProductListing";
    document.title = `Paysah | ${pageTitle}`;

    const showProductList = async () => {
      let user_details = await JSON.parse(localStorage.getItem("user_details"));
      let data = {
        user_id: user_details?.user_id,
        name: searchResult ? searchResult : null,
        page: page,
        per_page: perPageData,
      };
      let searchData = {
        user_id: user_details?.user_id,
        name: searchResult ? searchResult : null,
        page: 1,
        per_page: perPageData,
      };
      try {
        let result = await showSellerProductList(
          searchResult ? searchData : data
        );
        if (result?.success) {
          if (result?.data) {
            setFoundUsers(result?.data?.data);
            setPrevPage(result?.data?.prev_page_url);
            setNextPage(result?.data?.next_page_url);

            setResetValue(false);
          }
        } else {
          // showErrorMessage(result?.data);
          setFoundUsers([]);
          setResetValue(false);
        }
      } catch (error) {
        setResetValue(false);
        console.log(error);
      }
    };
    showProductList();
  };

  const reset = () => {
    setResetValue(true);
    setTags("");
    setSearchResult("");
  };

  const deleteProduct = async (proId) => {
    setShow(true);
    setDeleteProducts([
      {
        proId: proId,
      },
    ]);
  };
  const handledeleteProduct = async () => {
    if (deleteProducts?.length > 0) {
      setShow(false);
      let user_details = await JSON.parse(localStorage.getItem("user_details"));
      let data = {
        id: deleteProducts[0].proId,
        user_id: user_details?.user_id,
      };
      try {
        let resultData = await removeSellerProduct(data);

        if (resultData?.success) {
          setTimeout(() => {
            showSuccessMessage(resultData?.message);
          }, 1000);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
          const newList = foundUsers.filter(
            (item) => item.id !== deleteProducts[0].proId
          );
          setFoundUsers(newList);
        } else if (resultData?.success == false) {
          showErrorMessage(resultData?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleClose = () => {
    setShow(false);
    setDeleteProducts([]);
  };
  const showOrderDetails = (id) => {
    dispatch(clearWantList());
    localStorage.setItem("view_products_id", JSON.stringify(id));
    navigation(`/editProducts/${id}`);
  };
  const addProducts = () => {
    dispatch(clearWantList());
    navigation(`/addProduct`);
  };
  const filter = (e) => {
    const keyword = e.target.value;
    setSearchResult(keyword);
  };

  // const orderPlace = (event) => {
  //   event.preventDefault();
  //   // Handle form submission with postalCodes array
  // };

  const handleBack = () => {
    navigation(`/sellerProductList`);
  };

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value]);
      // props.selectedTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />
              <div className="right-panel">
                <div className="styleBtn">
                  <button
                    className="btn btn-dark w-40 bg-dark"
                    onClick={handleBack}
                  >
                    <i class="fa fa-arrow-circle-left" aria-hidden="true">
                      {" "}
                    </i>
                  </button>
                </div>
                <h2>
                  <strong className="pro-subheading">My Products</strong>
                </h2>
                {/* <div className="col-12 text-end"style={{ marginBottom: "15px" }} >
                    <button
                      className="addProductBtn btn btn-dark"
                      onClick={addProducts}
                    >
                      Add Products
                    </button>
                  </div> */}
                <div className="col-lg-12 p-2">
                  <Alert
                    variant="filled"
                    severity="info"
                    className="bg-white text-dark"
                  >
                    Only the products for which a variant has been added will be
                    displayed on the buyer's side
                  </Alert>
                </div>
                <div className="headerSearch">
                  <div className="productsearch">
                    {" "}
                    {/* {foundUsers && foundUsers.length > 0 ? ( */}
                    <>
                      <div className="search">
                        <input
                          type="search"
                          value={searchResult}
                          onChange={filter}
                          className="searchTerm"
                          placeholder="Search Product"
                        />
                      </div>
                    </>
                    <div className="seller-listing-p">
                      <button
                        type="submit"
                        className="searchButtonflex btn btn-transparent search"
                        disabled={
                          tags == "" && searchResult == "" ? true : false
                        }
                        onClick={orderPlace}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        type="submit"
                        className="resetButtonflex btn btn-transparent"
                        onClick={reset}
                        value={resetValue}
                        disabled={
                          tags == "" && searchResult == "" ? true : false
                        }
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
                {foundUsers && foundUsers?.length > 0 ? (
                  foundUsers?.map((user) => (
                    <div className="row" key={user.id}>
                      <div className="col-lg-12">
                        {user?.products?.map((itm) => (
                          <div className="productitem" key={itm.id}>
                            <div className="itemheading pro-subheading">
                              <h2
                                style={{ marginLeft: "5px" }}
                                className="pro-subheading"
                                title="Contains Variant"
                              >
                                {user?.seller_product_size_option?.length >
                                0 ? (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    width="20"
                                    title="Contains Variant"
                                    height="20"
                                    src="https://img.icons8.com/ios-filled/50/shopping-bag.png"
                                    alt="shopping-bag"
                                    onClick={() => showOrderDetails(user?.id)}
                                  />
                                ) : null}
                                {itm.title}
                              </h2>

                              <div className="edit-delete-btn">
                                <button
                                  className="cart-btn"
                                  onClick={() => showOrderDetails(user?.id)}
                                >
                                  <i className="fa fa-edit"></i>
                                </button>
                                <button
                                  className="action-btn"
                                  title="Delete Record"
                                  onClick={() => deleteProduct(user?.id)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
                            <p style={{ marginLeft: "10px" }}>
                              {itm.description}
                            </p>
                            {/* {user?.seller_product_size_option?.length > 0 ? (
                              <span
                                style={{ color: "green", fontSize: "12px" }}
                              >
                                Product variant exist
                              </span>
                            ) : (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                Product Variant not added, assign the product
                                variant!
                              </span>
                            )} */}
                            {/* Add more details as needed */}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <h3
                    style={{
                      display: "flex",
                      height: "70vh",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Products
                  </h3>
                )}

                {(nextPage || prevPage) &&
                foundUsers &&
                foundUsers?.length > 0 ? (
                  <div className="StylePagination">
                    <button
                      disabled={prevPage == null ? true : false}
                      onClick={() => {
                        setPage(parseInt(prevPage?.split("=")[1]));
                      }}
                      className="previous text-white bg-dark border-dark"
                    >
                      &laquo; Previous
                    </button>
                    <button
                      disabled={nextPage == null ? true : false}
                      className="next text-white bg-dark border-dark"
                      onClick={() => {
                        setPage(parseInt(nextPage?.split("=")[1]));
                      }}
                    >
                      Next &raquo;
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>PaySah</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this product ?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  No
                </Button>
                <Button variant="primary" onClick={handledeleteProduct}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
            <Footer />
          </LoggedInHeader>
        </>
      )}
    </>
  );
}
