const { createSlice } = require("@reduxjs/toolkit");

const wantList = createSlice({
  name: "wantList",
  initialState: {
    wantList: [],
  },
  reducers: {
    addWantList(state, action) {
      state.wantList = [...action.payload];
    },
    addItems(state, action) {
      state.wantList.push(action.payload);
    },
    addQuantity(state, action) {
      const index = state.wantList.findIndex((object) => {
        return object.id === action.payload.id;
      });
      if (index !== -1) {
        state.wantList[index].quantity = state.wantList[index].quantity + 1;
      }
    },
    removeQuantity(state, action) {
      const index = state.wantList.findIndex((object) => {
        return object.id === action.payload.id;
      });
      if (index !== -1 && state.wantList[index].total_quantity !== 1) {
        state.wantList[index].quantity = state.wantList[index].quantity - 1;
      }
    },
    removeItem(state, action) {
      state.wantList.splice(
        state.wantList.findIndex((arrow) => arrow.id === action.payload),
        1
      );
    },
    clearWantList(state, action) {
      state.wantList = [];
    },
    handleItemName(state, action) {
      const index = state.wantList.findIndex((object) => {
        return object.id === action.payload.item.id;
      });
      if (index !== -1) {
        state.wantList[index].item_name = action.payload.value;
      }
    },
    handleWeightUnit(state, action) {
      const index = state.wantList.findIndex((object) => {
        return object.id === action.payload.item.id;
      });
      if (index !== -1) {
        state.wantList[index].size_id = action.payload.value;
      }
    },
    handleUnit(state, action) {
      const index = state.wantList.findIndex((object) => {
        return object.id === action.payload.item.id;
      });
      if (index !== -1) {
        state.wantList[index].unit = action.payload.value;
      }
    },
    
    handleWeight(state, action) {
      const index = state.wantList.findIndex((object) => {
        return object.id === action.payload.item.id;
      });
      if (index !== -1) {
        state.wantList[index].weight = action.payload.value;
      }
    },
    handlePrice(state, action) {
     
      const index = state.wantList.findIndex((object) => {
       
        return object.id === action.payload.item.id;
      });
      if (index !== -1) {
        state.wantList[index].price = action.payload.value;
      }
    },
    handleQuantity(state, action) {
      const index = state.wantList.findIndex((object) => {
        return object.id === action.payload.item.id;
      });
      if (index !== -1) {
       
        state.wantList[index].total_quantity = action.payload.value;
      }
    },
    handleExpire(state, action) {
      const index = state.wantList.findIndex((object) => {
      return object.id === action.payload.item.id;
      });
      if (index !== -1) {
        state.wantList[index].order_expiration_days = action.payload.value;
      }
    },
    handleBuffer(state, action) {
      const index = state.wantList.findIndex((object) => {
        return object.id === action.payload.item.id;
      });
      if (index !== -1) {
        state.wantList[index].no_of_buffer_days_before_item_could_be_reactivated = action.payload.value;
      }
    },
  },
});

export const {
  addItems,
  addQuantity,
  removeQuantity,
  removeItem,
  clearWantList,
  handleWeightUnit,
  handleExpire,
  handleBuffer,
  handleWeight,
  handlePrice,
  handleItemName,
  addWantList,
  handleQuantity,
  handleUnit
} = wantList.actions;
export default wantList.reducer;
