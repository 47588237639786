import { createSlice } from "@reduxjs/toolkit";

const orderCreateList = createSlice({
  name: "orderCreateList",
  initialState: {
    orderListData: {},
  },
  reducers: {
    storeOrderCreateListData: (state, action) => {
      state.orderListData = action.payload;
    },
  },
});

export const { storeOrderCreateListData } = orderCreateList.actions;
export default orderCreateList.reducer;
