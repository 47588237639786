import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const year = new Date().getUTCFullYear();
  return (
    <div className="footer-section">
      <footer className="end-footer">
        <p>
          ©{year} All rights reserved to <Link to="/">Paysah</Link>
        </p>
      </footer>
    </div>
  );
}
