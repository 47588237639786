import React, {useEffect} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ForgotPassword } from "../../api/request";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { showSuccessMessage, showErrorMessage } from "../../constants/Toast";

export default function EmailTemplate(props) {
  const navigation = useNavigate();
  const location = useLocation();
  let data = {
    email: location.state.email,
  };

  useEffect(() => {
    document.title = "Paysah | Password_Reset";
  });

  const resendLink = async () => {
    try {
      let res = await ForgotPassword(data);
      if (res.success === true) {
        showSuccessMessage("Verification link sent successfully!!!");
      } else {
        showErrorMessage("Invalid username and password");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="top-action-bar">
          <div className="container">
            <Header onclickSection={() => navigation("/")} />
          </div>
        </div>
        <div className="container">
          <div className="px-4 py-5 my-5 text-center">
            <Link to="/">
              <img
                className="d-block mx-auto heading-logo"
                src="../../assets/images/logo.png"
                alt=""
              />
            </Link>
            <div className="register-form">
              <form>
                <div className="section-heading mb-5">
                  <h1 className="m-0">Email has been sent!</h1>
                  <div className="text-center">
                    <strong>To : {location.state.email}</strong>
                  </div>
                </div>
                <div className="text-center">
                  <img src="../../assets/images/icon-email.svg" alt="" />{" "}
                </div>
                <div className="text-center my-5">
                  <p>
                    Please check your inbox and click
                    <br />
                    in the received link to reset a Password
                  </p>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <Link to="/login">
                      <button className="btn btn-dark w-100">Login</button>
                    </Link>
                  </div>
                </div>
                <div className="text-center register-link mb-5">
                  Didn’t Recieve the link?{" "}
                  <strong style={{ cursor: "pointer" }} onClick={resendLink}>
                    Resend
                  </strong>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
