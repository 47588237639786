import React, { useEffect, useState } from "react";
import { showErrorMessage, showSuccessMessage } from "../../constants/Toast";
import {
  getCartApi,
  deleteCartApi,
  ReplacCartItemsQuantity,
  createOrder,
} from "../../api/request";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "../../assets/images/icon-delete.svg";
import LoggedInHeader from "../../components/LoggedInHeader";
import SideNavigation from "../../components/SideNavigation";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import Swal from "sweetalert2";
import { showLoading, hideLoading } from "../../redux/slices/LoaderSlice";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function Cart() {
  const { isLoading } = useSelector((state) => state.loader);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [dataObject, setDataObject] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [subTotal, setSubTotal] = useState();
  const [productId, setProductId] = useState();
  const [variantId, setVariantId] = useState();
  const [deleteProducts, setDeleteProducts] = useState([]);
  const [checkoutShow, setCheckoutShow] = useState(false);
  useEffect(() => {
    getCartApiData();
  }, []);
  useEffect(() => {
    var length = Object.keys(selectedProducts).length;
    if (length > 0) {
      handleAddToCart(productId, variantId);
    }
  }, [selectedProducts]);
  const handleCheckoutClose = () => {
    setCheckoutShow(false);
  };
  const handleCheckout = () => {
    setCheckoutShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setDeleteProducts([]);
  };
  const deleteProduct = async (
    selerId,
    productId,
    itemVarientId,
    quantity,
    price,
    proId
  ) => {
    setShow(true);
    setDeleteProducts([
      {
        selerId: selerId,
        productId: productId,
        itemVarientId: itemVarientId,
        quantity: quantity,
        price: price,
        proId: proId,
      },
    ]);
  };
  const handledeleteProduct = async () => {
    if (deleteProducts.length > 0) {
      setShow(false);
      var cart_id = localStorage.getItem("CartSequenceId");
      let variantsData = {
        supplier_id: deleteProducts[0].selerId,
        product_id: deleteProducts[0].productId,
        variant_id: deleteProducts[0].itemVarientId,
        quantity:
          selectedProducts[deleteProducts[0].productId]?.[
            deleteProducts[0].itemVarientId
          ]?.deleteProducts[0].quantity || deleteProducts[0].quantity,
        item_price: deleteProducts[0].price,
      };
      let myData = [];
      let deletedData = [];
      myData.push(variantsData);
      deletedData = {
        cart_seq_id: cart_id,
        cart_items: myData,
      };
      try {
        let result = await deleteCartApi(deletedData);
        if (result?.success) {
          setDeleteProducts([]);
          getCartApiData();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  let itemQtyValidation = [];
  itemQtyValidation = cartData
    .filter((item) => {
      return item?.quantity > item?.max_available_quantity_in_inventory;
    })
    .map((item) => {
      return item.id;
    });
  const handleUpdateCreateCart = async () => {
    var cart_id = localStorage.getItem("CartSequenceId");
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    let dataWithCart = {
      cart_seq_id: cart_id,
      user_id: user_details.user_id,
      cart_items: dataObject,
    };
    let dataWithoutCart = {
      user_id: user_details.user_id,
      cart_items: dataObject,
    };
    try {
      // dispatch(showLoading());
      let result = await ReplacCartItemsQuantity(
        cart_id ? dataWithCart : dataWithoutCart
      );
      if (result?.success) {
        // localStorage.setItem("cartId", result.data.cart.cart_seq_id);
        // dispatch(hideLoading());
        window.location.reload();
        getCartApiData();
      } else {
        showErrorMessage("Product Not Found");
      }
    } catch (error) {
      console.log(error);
    }
  };
  let data = {};
  const handleAddToCart = (productId, itemVarientId) => {
    data = selectedProducts[productId];
    var result = data[itemVarientId];
    dataObject.push(result);
    setDataObject([...dataObject]);
    handleUpdateCreateCart();
  };
  const getCartApiData = async () => {
    var cart_id = localStorage.getItem("CartSequenceId");
    let data = {
      cart_seq_id: cart_id,
    };
    try {
      let result = await getCartApi(data);
      if (result?.success) {
        setCartData(result.data.cart_items);
        setSubTotal(result.data.cart_items_sum_billing_amount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const increaseQuantity = (
    productId,
    itemVarientId,
    price,
    supplier_id,
    quantity
  ) => {
    setVariantId(itemVarientId);
    setProductId(productId);
    let qty =
      selectedProducts[productId]?.[itemVarientId]?.quantity || quantity;
    setSelectedProducts({
      ...selectedProducts,
      [productId]: {
        ...selectedProducts[productId],
        [itemVarientId]: {
          supplier_id: supplier_id,
          product_id: productId,
          variant_id: itemVarientId,
          quantity: qty + 1,
          item_price: price,
        },
      },
    });

    // showSuccessMessage("Quantity has been updated successfully!!!");
  };
  const decreaseQuantity = (
    productId,
    itemVarientId,
    price,
    supplier_id,
    quantity
  ) => {
    setVariantId(itemVarientId);
    setProductId(productId);
    let qty =
      selectedProducts[productId]?.[itemVarientId]?.quantity || quantity;
    setSelectedProducts({
      ...selectedProducts,
      [productId]: {
        ...selectedProducts[productId],
        [itemVarientId]: {
          supplier_id: supplier_id,
          product_id: productId,
          variant_id: itemVarientId,
          quantity: qty - 1,
          item_price: price,
        },
      },
    });
    // showSuccessMessage("Quantity has been updated successfully!!!");
  };
  const getCreateOrderData = async () => {
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    var cart_id = localStorage.getItem("CartSequenceId");
    let data = {
      cart_seq_id: cart_id,
      buyer_id: user_details?.user_id,
    };
    try {
      let result = await createOrder(data);
      localStorage.setItem("OrderId", result?.data?.orderId);
      if (result?.success == true) {
        // Swal.fire({
        //   icon: "error",
        //   title: "Oops...",
        //   text: result?.message,
        // });
        navigation("/thankyouPage");
      } else if (result?.success == false) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result?.message.toString(),
          html: result?.message.toString(),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleContinueBtn = () => {
    navigation("/buyerhome");
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <LoggedInHeader>
          <div className="body-container d-flex">
            <SideNavigation />
            <div className="right-panel">
              <h2>
                <strong>Cart</strong>
              </h2>
              {/* <div className="page-title"><h2>Cart</h2></div> */}
              <div className="row">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <ul className="cartlist-outer">
                      {cartData?.length ? (
                        <li className="cart-head">
                          <div className="item row">
                            <div className="description-cart col-2">
                              <p>Empty box</p>
                            </div>
                            <div className="total-price col-lg-1 col-md-1 col-sm-4 col-6">
                              <strong>Sizing</strong>
                            </div>
                            <div className="total-price col-lg-1 col-md-2 col-sm-4 col-6">
                              <strong>Price</strong>
                            </div>
                            <div className="quantity col-lg-3 col-md-3 col-sm-4 col-6">
                              <strong>Quantity</strong>
                            </div>
                            <div className="total-price col-lg-2 col-md-2 col-sm-4 col-6">
                              <strong>Sub Total</strong>
                            </div>
                            <div className="total-price col-lg-2 col-md-2 col-sm-4 col-6">
                              <strong>Billing Amount</strong>
                            </div>
                            <div className="description-cart col-lg-1 col-md-0 col-sm-4 col-6">
                              <p>Empty box</p>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      {cartData && cartData?.length ? (
                        cartData?.map((item, id) => {
                          return (
                            <>
                              <li>
                                <div className="item cart-product-list row">
                                  <div className="productname col-lg-2 col-md-2 col-sm-4 col-12">
                                    <span>
                                      <strong>{item?.product?.name}</strong>
                                    </span>
                                    <span>{item?.seller?.name}</span>
                                  </div>
                                  <div
                                    className="cart-total-weight cart-value col-lg-1 col-md-1 col-sm-4 col-12"
                                    data-cell="Sizing"
                                  >
                                    {item?.weight && item?.packing_unit ? (
                                      <>
                                        {" "}
                                        {item?.weight}&nbsp;
                                        {item?.packing_unit}
                                      </>
                                    ) : item?.weight ? (
                                      <> {item?.weight}</>
                                    ) : item?.packing_unit ? (
                                      <> {item?.packing_unit}</>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                  {item?.item_price ? (
                                    <div
                                      className="cart-item-price cart-value col-lg-1 col-md-2 col-sm-4 col-12"
                                      data-cell="Price"
                                    >
                                      £{item?.item_price}
                                    </div>
                                  ) : null}
                                  {/* <div className="weightPrice col-1">
                                    <div className="description">
                                      <span>
                                        <h1>{cartData?.id}</h1>
                                      </span>
                                    </div>
                                 
                                  </div> */}
                                  <div
                                    className="quantity cart-value col-lg-3 col-md-3 col-sm-4 col-12 "
                                    data-cell="Quantity"
                                  >
                                    <div className="quantity-new">
                                      <div className="quantity-cart">
                                        <button
                                          className="quantity-btn"
                                          id={item.variant_id}
                                          onClick={() =>
                                            decreaseQuantity(
                                              item?.product?.id,
                                              item?.variant_id,
                                              item?.variant?.price,
                                              item?.supplier_id,
                                              item?.quantity,
                                              id
                                            )
                                          }
                                          disabled={
                                            selectedProducts[
                                              `${item?.product?.id}`
                                            ]?.[`${item?.variant_id}`]
                                              ?.quantity == 1 ||
                                            item?.quantity == 1
                                          }
                                        >
                                          <svg
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M4 12H20"
                                              stroke="#000000"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </button>

                                        <input
                                          type="text"
                                          name="name"
                                          value={
                                            selectedProducts[
                                              `${item?.product?.id}`
                                            ]?.[`${item?.variant_id}`]
                                              ?.quantity || item?.quantity
                                          }
                                        />

                                        <button
                                          className="quantity-btn"
                                          id={item.variant_id}
                                          onClick={() =>
                                            increaseQuantity(
                                              item?.product?.id,
                                              item?.variant_id,
                                              item?.variant?.price,
                                              item?.supplier_id,
                                              item?.quantity,
                                              id
                                            )
                                          }
                                          disabled={
                                            selectedProducts[
                                              `${item?.product?.id}`
                                            ]?.[`${item?.variant_id}`]
                                              ?.quantity >
                                            item?.max_available_quantity_in_inventory
                                              ? true
                                              : false
                                          }
                                        >
                                          <svg
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                          >
                                            <path
                                              fill="#000000"
                                              fill-rule="evenodd"
                                              d="M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                      <div className="cart-message">
                                        {itemQtyValidation.includes(
                                          item?.id
                                        ) ? (
                                          <div
                                            style={{
                                              color: "red",
                                              textAlign: "center",
                                              fontSize: "12px",
                                              marginTop: "5px",
                                            }}
                                          >
                                            Quantity Limit Exceed
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="total-product cart-value col-lg-2 col-md-2 col-sm-4 col-12"
                                    data-cell="Sub Total"
                                  >
                                    {item?.item_total ? (
                                      <div className="total-price cart-value">
                                        £{item?.item_total}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className="total-price cart-value col-lg-2 col-md-2 col-sm-4 col-12"
                                    data-cell="Billing Amount"
                                  >
                                    £{item?.billing_amount}
                                  </div>
                                  <div className="total-price cart-value col-lg-1 col-md-12 col-sm-12 col-12 cart-delete-btn">
                                    <button
                                      className="action-btn"
                                      onClick={() =>
                                        deleteProduct(
                                          item?.product.user_id,
                                          item?.product?.id,
                                          item?.variant_id,
                                          item?.quantity,
                                          item?.variant?.price,
                                          id
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </div>
                                </div>
                              </li>
                            </>
                          );
                        })
                      ) : (
                        <>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                            }}
                            className="saveWantList col-sm-12"
                          >
                            <button
                              className="btn btn-dark"
                              onClick={handleContinueBtn}
                            >
                              Add Products To Cart
                            </button>
                          </div>
                          {/* <h3 style={{ display: "flex", height: "70vh",justifyContent: "center",alignItems: "center",}} >
                            Your Cart Is Empty
                          </h3> */}
                        </>
                      )}
                      {cartData?.length ? (
                        <li>
                          <div className="item item-subtotal">
                            <div className="item-subprice">
                              <div className="quantity">
                                <strong> Billing Total </strong>
                              </div>
                              <div className="total-price total-count">
                                {cartData && cartData?.length ? (
                                  <strong> £{subTotal} </strong>
                                ) : null}
                              </div>
                            </div>
                            <div className="checkout-btns">
                              <div className="row ml-3 check-out-btn">
                                <div className="col-sm-12">
                                  <button
                                    className="btn btn-dark w-100 border-round"
                                    onClick={handleCheckout}
                                    disabled={
                                      itemQtyValidation.length != 0
                                        ? true
                                        : false
                                    }
                                  >
                                    Checkout
                                  </button>
                                </div>
                              </div>
                              <div className="row ml-3">
                                <div className="saveWantList col-sm-12">
                                  <button
                                    className="btn btn-dark w-100 border-round"
                                    onClick={handleContinueBtn}
                                  >
                                    Continue Shopping
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>PaySah</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this product from cart ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="primary" onClick={handledeleteProduct}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={checkoutShow}
            onHide={handleCheckoutClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>PaySah</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Currently, we are not accepting online payments. Are you sure that
              you want to checkout using offline payments?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary cart-btn" onClick={getCreateOrderData}>
                {isLoading ? <Loader /> : null}
                Yes
              </Button>
              <Button
                variant="secondary cart-btn"
                onClick={handleCheckoutClose}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer />
        </LoggedInHeader>
      )}
    </>
  );
}
