import {
  hideLoading,
  showLoading,
  hideCompLoading,
  showCompLoading,
} from "../redux/slices/LoaderSlice";
import {
  postRequest,
  getRequestToken,
  CancelOrderSellerApi,
  sellertempsApi,
  UpdateFrontUserResetPasswordApi,
  ViewOrderDetailsStockRoomApi,
  ViewOrderDetailsStockRoomSellerWiseApi,
  ViewRedeemHistoryStockRoomApi,
  ViewOrderDetailsSellerNewApi,
  ReplacCartItemsQuantityApi,
  InvoiceApi,
  DeleteSellerProductsItemsApi,
  GetCartExceedQuantityApi,
  OrderHistoryBuyerApi,
  proceedOrderApi,
  cancelOrdersWholeApi,
  RedeemOrderItemQuantityApi,
  UpdateOrderItemQuantityApi,
  redeemOrderItemQuantitySellerApi,
  ViewOrderDetailsSellerApi,
  ViewOrderListSellerApi,
  viewQrCodeOrderInformationApi,
  postRequestToken,
  addBuyerList,
  haveListListingApi,
  viewQrCodeInformationApi,
  haveListOrderDetailsApi,
  createOrderApi,
  deleteCart,
  getCartDataApi,
  updateCreateCartApi,
  buyerWantList,
  getSellerListing,
  deleteSellerProduct,
  getSellerProduct,
  postSellerToken,
  postSellerProductList,
  updateProductList,
  viewSellerProductList,
  sendProductRequest,
  postProductList,
  sellerProductSave,
  showCategory,
  BuyerOrderlistsellerApi,
  ShowSellerSendproductRequestApi,
  DeleteBuyerSideProductQR,
  EditBuyerSideProductQR,
  UpdateBuyerSideProductQR,
  ViewNotificationsBuyerApi,
  ViewNotificationsSellerApi,
  showOrderItemNewReactivationHistoryBuyerApi,
  showOrderItemNewReactivationHistorySellerApi,
  analyticsDataBuyerApi,
  analyticsDataSellerApi,
  updateOrderStatusApi,
  ReactivateOrderItemNewApi,
} from "./api";
import * as endPoints from "./endpoints";
import store from "../redux/store/store";
import { storeProfileData } from "../redux/slices/ProfileSlice";
import { storeOrderCreateListData } from "../redux/slices/OrderCreateSlice";
import { addWantList } from "../redux/slices/QuantitySlice";
import { storeOrderHistoryData } from "../redux/slices/OrderHistorySlice";

export const UserLogin = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequest(endPoints.Login, data);
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const UserRegistration = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequest(endPoints.SignUp, data);
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const ForgotPassword = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequest(endPoints.ForgotPassword, data);
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const GetUserDetails = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await getRequestToken(endPoints.User_Profile);
    if (result.success) {
      store.dispatch(storeProfileData(result.data));
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const ShowSellerSendproductRequest = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await ShowSellerSendproductRequestApi(
      endPoints.Show_Seller_Send_product_Request,
      data
    );
    if (result.success) {
      store.dispatch(storeProfileData(result.data));
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const saveSellerProducts = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await sellerProductSave(endPoints.Save_Seller_Products, data);
    if (result.success) {
      store.dispatch(storeProfileData(result.data));
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const sendProductReq = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await sendProductRequest(endPoints.Send_Product_Request, data);
    if (result.success) {
      store.dispatch(storeProfileData(result.data));
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const BuyerOrderlistseller = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await BuyerOrderlistsellerApi(
      endPoints.Buyer_Order_list_seller,
      data
    );
    if (result.success) {
      store.dispatch(storeProfileData(result.data));
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const categoryList = async () => {
  try {
    store.dispatch(showLoading());
    let result = await showCategory(endPoints.Categories_list);
    if (result.success) {
      store.dispatch(storeProfileData(result.data));
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
// export const categoryList = async () => {
//   try {
//     store.dispatch(showLoading());
//     let result = await showCategory(endPoints.Categories_list);
//     if (result.success) {
//       store.dispatch(storeProfileData(result.data));
//     }
//     store.dispatch(hideLoading());
//     return result;
//   } catch (error) {
//     console.log(error);
//     store.dispatch(hideLoading());
//   }
// };

export const CreateWantList = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequestToken(endPoints.Want_List, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const OrderCreateList = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequest(endPoints.Order_Create_List, data);
    if (result.success) {
      store.dispatch(storeOrderCreateListData(result.data));
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const UpdateProfile = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequestToken(endPoints.Update_Profile, data);
    if (result.success) {
      // GetUserDetails();
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
    // store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const Order_List = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequestToken(endPoints.Orders, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const Order_Details = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequestToken(endPoints.Order_Details, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const View_Buyer_Want_Items = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequestToken(endPoints.View_Buyer_Want_Items, data);

    if (result?.success) {
      if (result?.data?.items?.length > 0) {
        store.dispatch(addWantList(result?.data?.items));
        store.dispatch(hideLoading());
        return result;
      } else {
        store.dispatch(hideLoading());
      }
    }
    return store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const Scan_Qr = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequestToken(endPoints.Scan_QR, data);
    if (result?.success) {
      store.dispatch(hideLoading());
      return result;
    }
    return store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const Create_Order = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequestToken(endPoints.Create_Order, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const OrderHistoryDetails = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequest(endPoints.Order_History, data);
    if (result.success) {
      store.dispatch(storeOrderHistoryData(result.data));
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const UpdateOrderStatus = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequestToken(endPoints.Update_Status, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const DeleteWantItems = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequestToken(endPoints.Delete_Want_Item, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const EmailVerification = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await getRequestToken(
      `${endPoints.Email_Verification_Link}?token=${data}`
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const addSellerProduct = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await getSellerProduct(endPoints.AddSellerProduct, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const showSellerProductList = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postSellerProductList(
      endPoints.Show_Seller_Product,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const showProductList = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postProductList(endPoints.product_List, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const viewSellerProducts = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await viewSellerProductList(
      endPoints.View_Seller_Products,
      data
    );
    if (result.success) {
      store.dispatch(addWantList(result?.data?.size_option));
      store.dispatch(addWantList(result?.data?.size_option));
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const CreateProductList = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postSellerToken(endPoints.Create_Seller_Product, data);
    if (result) {
      store.dispatch(hideLoading());
      return result;
    }

    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const removeSellerProduct = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await deleteSellerProduct(
      endPoints.Delete_Seller_Product,
      data
    );
    if (result.success) {
      // GetUserDetails();
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const updateProductViewList = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await updateProductList(endPoints.Update_Seller_Product, data);

    if (result.success) {
      // GetUserDetails();
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const sellerListing = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await getSellerListing(endPoints.Seller_Lists, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const AddBuyerBasketApi = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await addBuyerList(endPoints.Add_Buyer_Want_List, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const getBuyerWantList = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await buyerWantList(endPoints.Get_Buyer_Want_List, data);
    store.dispatch(showLoading());
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const updateCreateCart = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await updateCreateCartApi(endPoints.Update_Create_Cart, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const getCartApi = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await getCartDataApi(endPoints.Get_Cart, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const deleteCartApi = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await deleteCart(endPoints.Delete_Cart_Item, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const createOrder = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await createOrderApi(endPoints.Create_Orders, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const haveListListing = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await haveListListingApi(endPoints.View_Order_List, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const haveListOrderDetails = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await haveListOrderDetailsApi(
      endPoints.View_Order_Details,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const OrderHistoryBuyer = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await OrderHistoryBuyerApi(
      endPoints.Order_History_Buyer,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const viewQrCodeInformation = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await viewQrCodeInformationApi(
      endPoints.View_Qr_Code_Order_Information,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const ReplacCartItemsQuantity = async (data) => {
  try {
    store.dispatch(showLoading());

    let result = await ReplacCartItemsQuantityApi(
      endPoints.Replac_Cart_Items_Quantity,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const redeemOrder = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await RedeemOrderItemQuantityApi(
      endPoints.Redeem_Order_Item_Quantity,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const UpdateOrder = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await UpdateOrderItemQuantityApi(
      endPoints.Buyer_Update_Qr_Request,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const viewQrCodeOrderInformation = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await viewQrCodeOrderInformationApi(
      endPoints.View_Qr_Code_Order_Information,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const ViewOrderListSeller = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await ViewOrderListSellerApi(
      endPoints.View_Order_List_Seller,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const ViewOrderDetailsSeller = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await ViewOrderDetailsSellerApi(
      endPoints.View_Order_Details_Seller,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const CancelOrderSeller = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await CancelOrderSellerApi(
      endPoints.cancel_Order_Seller,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const redeemOrderItemQuantitySeller = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await redeemOrderItemQuantitySellerApi(
      endPoints.Redeem_Order_Item_Quantity_Seller,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const proceedOrder = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await proceedOrderApi(endPoints.Proceed_Order, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const cancelOrdersWhole = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await cancelOrdersWholeApi(
      endPoints.Cancel_Oorders_Whole,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const GetCartExceedQuantity = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await GetCartExceedQuantityApi(
      endPoints.Get_Cart_Exceed_Quantity,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const ViewOrderDetailsSellerNew = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await ViewOrderDetailsSellerNewApi(
      endPoints.View_Order_Details_Seller_New,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const DeleteSellerProductsItems = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await DeleteSellerProductsItemsApi(
      endPoints.Delete_Seller_Products_Option,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const Invoice = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await InvoiceApi(endPoints.invoice, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const Sellertemps = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await sellertempsApi(endPoints.sellerRegistartion, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const UpdateBuyerProfile = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await postRequestToken(endPoints.update_profile_buyer, data);
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const ViewOrderDetailsStockRoom = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await ViewOrderDetailsStockRoomApi(
      endPoints.view_order_details_stock_room,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const ViewRedeemHistoryStockRoom = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await ViewRedeemHistoryStockRoomApi(
      endPoints.view_redeem_history_stock_room,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const UpdateFrontUserResetPassword = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await UpdateFrontUserResetPasswordApi(
      endPoints.update_front_user_reset_password,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const ViewOrderDetailsStockRoomSellerWise = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await ViewOrderDetailsStockRoomSellerWiseApi(
      endPoints.view_order_details_stock_room_seller_wise,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const EditBuyerSideProduct = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await EditBuyerSideProductQR(
      endPoints.Buyer_Edit_Qr_Request,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const DeleteBuyerSideProduct = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await DeleteBuyerSideProductQR(
      endPoints.Buyer_Delete_Qr_Request,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
export const UpdateBuyerSideProduct = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await UpdateBuyerSideProductQR(
      endPoints.Buyer_Update_Qr_Request,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    } else {
      store.dispatch(hideLoading());
      return result;
    }
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const ViewNotificationsBuyer = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await ViewNotificationsBuyerApi(
      endPoints.View_Notifications_Buyer,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const ViewNotificationsSeller = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await ViewNotificationsSellerApi(
      endPoints.View_Notifications_Seller,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

//** Reactivation History */
export const showOrderItemNewReactivationHistorySeller = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await showOrderItemNewReactivationHistorySellerApi(
      endPoints.show_order_item_new_reactivation_history_seller,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const showOrderItemNewReactivationHistoryBuyer = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await showOrderItemNewReactivationHistoryBuyerApi(
      endPoints.show_order_item_new_reactivation_history_buyer,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
//** Analytics Data Status */
export const analyticsDataBuyer = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await analyticsDataBuyerApi(
      endPoints.analytics_data_buyer,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

export const analyticsDataSeller = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await analyticsDataSellerApi(
      endPoints.analytics_data_seller,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
//** Update Order Status */
export const updateOrderStatus = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await updateOrderStatusApi(
      endPoints.update_order_status,
      data
    );
    if (result.success) {
      GetUserDetails();
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};

//** reactivation history */
export const ReactivateOrderItemNew = async (data) => {
  try {
    store.dispatch(showLoading());
    let result = await ReactivateOrderItemNewApi(
      endPoints.Reactivate_Order_Item_New,
      data
    );
    if (result.success) {
      store.dispatch(hideLoading());
      return result;
    }
    store.dispatch(hideLoading());
    return result;
  } catch (error) {
    console.log(error);
    store.dispatch(hideLoading());
  }
};
