import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SideNavigation from "../../components/SideNavigation";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";
import moment from "moment";

import { analyticsDataSeller } from "../../api/request";

const StatsSeller = () => {
  const [details, setDetails] = useState([]);
  const [sellerKeepProducts, setSellerKeepProducts] = useState([]);
  const { isLoading } = useSelector((state) => state.loader);

  useEffect(() => {
    getAnalyticsDataSeller();
  }, []);

  const getAnalyticsDataSeller = async () => {
    let userInfo = localStorage.getItem("user_details");
    let userData = JSON.parse(userInfo);
    let user_id = userData?.user_id;
    let data = {
      seller_id: parseInt(user_id),
    };
    try {
      let result = await analyticsDataSeller(data);
      if (result?.success) {
        setDetails(
          result?.data?.how_many_users_have_products_in_their_have_list
        );
        setSellerKeepProducts(
          result?.data?.till_how_long_seller_needs_to_keep_products_in_stock
        );
      } else {
        console.log("fail");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        {isLoading ? (
          <div className="loader-BG">
            <Loader />
          </div>
        ) : (
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />
              <div className="right-panel">
                <>
                  <div className="right-panel">
                    <h2>
                      <strong className="pro-subheading">
                        Seller statistics
                      </strong>
                    </h2>
                    <div className="page-title">
                      {/* <h1 className="h5"></h1> */}
                      <p>
                        How many users have products in their have list :{" "}
                        {details}
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card shadow-sm">
                          <div className="card-body">
                            <h6>
                              <strong>
                                How long seller needs to keep products in stock
                              </strong>
                            </h6>

                            <div className="table-responsive">
                              <table className="table-borderless order-table">
                                <thead>
                                  <tr>
                                    <th className="OrderId">Product name</th>
                                    <th className="OrderId">Variant</th>
                                    <th>Item expiry date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sellerKeepProducts.map((curElem, id) => {
                                    return (
                                      <tr>
                                        {/* <td>{curElem.product_id ? `# ${curElem.product_id}` : null}</td> */}
                                        <td>{curElem.name || ""}</td>
                                        <td>
                                          {curElem.variant_name
                                            ? `# ${curElem.variant_name}`
                                            : null}
                                        </td>
                                        <td>
                                          {moment(
                                            curElem.order_item_validity_expires_at ||
                                              ""
                                          ).format("MMMM DD-YYYY hh:mm A")}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>

            <Footer />
          </LoggedInHeader>
        )}
      </div>
    </>
  );
};

export default StatsSeller;
