import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function ModalBox({handleClose, DeleteWantItem}) {

  return (
    <>      
        <Modal.Header closeButton>
          <Modal.Title>PaySah</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure, you want to delete this item
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={DeleteWantItem}>Yes</Button>
        </Modal.Footer>
    </>
  );
}
