import React from "react";
import { useSelector } from "react-redux";
import SideNavigation from "../components/SideNavigation";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import LoggedInHeader from "../components/LoggedInHeader";
import { useNavigate } from "react-router";
export default function ThankyouPage() {
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.loader);
  localStorage.removeItem("orderId");
  localStorage.removeItem("cartId");
  const orderId = localStorage.getItem("OrderId");
  const handlenotify = () => {
    navigate("/my-stockroom", { replace: true });
    localStorage.removeItem('cartId');
    localStorage.removeItem('CartSequenceId');
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />
              <div className="right-panel">
                <div className="page-title"></div>
                <div className="thankYouPageCard">
                  <div className="thankYouPage">
                    <div className="icon-div">
                    <svg fill="none" width="100" height="100" viewBox="0 0 16 16" version="1.1">
  <rect width="100" height="100" id="icon-bound"  />
  <path stroke="#88b04b" d="M2,9.014L3.414,7.6L6.004,10.189L12.593,3.6L14.007,5.014L6.003,13.017L2,9.014Z" />
</svg>
                    </div>
                    <h1>Thankyou!</h1>
                    <p>Your order has been created successfully</p>
                    {/* {orderId ? <h3>Your order id is : #{orderId}</h3> : null} */}
                    <button
                      className="btn btn-dark w-80 detail-btn"
                      onClick={handlenotify}>
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </LoggedInHeader>
          <Footer />
        </>
      )}
    </>
  );
}
