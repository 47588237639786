import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { haveListListing } from "../../api/request";
import SideNavigation from "../../components/SideNavigation";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";
import { FaEye } from "react-icons/fa";

export default function OrderHistory() {
  const { isLoading } = useSelector((state) => state.loader);
  const navigation = useNavigate();
  const [orders, setOrders] = useState([]);
  const [perPageData] = useState(10);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [prevPage, setPrevPage] = useState(null);

  useEffect(() => {
    document.title = "Paysah | HaveListListing";
    const getOrderDetails = async () => {
      let user_details = await JSON.parse(localStorage.getItem("user_details"));
      let data = {
        supplier_id: user_details?.user_id,
        page_limit: perPageData,
        page: page,
      };

      try {
        let result = await haveListListing(data);
        if (result?.success) {
          setOrders(result?.data?.data);
          setPrevPage(result?.data?.prev_page_url);
          setNextPage(result?.data?.next_page_url);
        } else {
          console.log("error there!");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getOrderDetails();
  }, [page]);
  const showOrderDetails = (id) => {
    localStorage.setItem("sellerSide-orderId", id);
    navigation(`/orderdetails/${id}`);
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />

              <div className="right-panel">
                <h2>
                  <strong className="pro-subheading">Order History</strong>
                </h2>
                {orders && orders?.length ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card shadow-sm">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table-borderless order-table">
                              <tbody>
                                <tr>
                                  <th className="OrderId pro-subheading">
                                    Order ID
                                  </th>
                                  <th className="pro-subheading">Order Date</th>
                                  <th className="pro-subheading">View</th>
                                </tr>
                                {orders.map((order) => (
                                  <tr key={order.orderId}>
                                    <td className="pro-subheading">
                                      #{order.orderId}
                                    </td>
                                    <td className="pro-subheading">
                                      {" "}
                                      {moment(order.created_at).format(
                                        "MMMM DD-YYYY hh:mm A"
                                      )}
                                    </td>
                                    <td className="pro-subheading">
                                      <button
                                        className="btn btn-dark bg-dark w-80 order-eye-btn"
                                        onClick={() =>
                                          showOrderDetails(order.id)
                                        }
                                      >
                                        <FaEye className="icon" />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {nextPage || prevPage ? (
                              <div className="StylePagination">
                                <button
                                  disabled={prevPage == null ? true : false}
                                  onClick={() => {
                                    setPage(parseInt(prevPage?.split("=")[1]));
                                  }}
                                  className="previous pro-subheading text-white bg-dark border-dark"
                                >
                                  &laquo; Previous
                                </button>
                                <button
                                  disabled={nextPage == null ? true : false}
                                  className="next pro-subheading text-white bg-dark border-dark"
                                  onClick={() => {
                                    setPage(parseInt(nextPage?.split("=")[1]));
                                  }}
                                >
                                  Next &raquo;
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <h3
                    style={{
                      display: "flex",
                      height: "70vh",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Orders Found
                  </h3>
                )}
              </div>
            </div>
            <Footer />
          </LoggedInHeader>
        </>
      )}
    </>
  );
}
