import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import QrReader from "react-qr-reader";

import { useNavigate } from "react-router-dom";
import SideNavigation from "../../components/SideNavigation";
import Loader from "../../components/Loader";
import { showErrorMessage, showSuccessMessage } from "../../constants/Toast";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";

export default function SellerHome() {
  const navigation = useNavigate();
  const { isLoading } = useSelector((state) => state.loader);
  const [ref, setRef] = useState({});
  const [cameraOpen, setCameraOpen] = useState(false);
  const [viewQrCodeInformations, setViewQrCodeInformations] = useState([]);
  let allDeatils = [];

  useEffect(() => {
    let pageTitle =
      localStorage.getItem("user_role") === "Buyer"
        ? "BuyerDashboard"
        : "SellerDashboard";

    document.title = `Paysah | ${pageTitle}`;
    let user_details = JSON.parse(localStorage.getItem("user_details"));
    setRef(user_details);
    localStorage.setItem("sellerData", "");
  }, []);

  const handleScan = (data) => {
    try {
      if (data) {
        // setCode(data);
        setCameraOpen(false);
        localStorage.setItem("sellerData", data);
        navigation("/sellerdetails");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const handleError = (err) => {
    showErrorMessage("Webcam not found!");
    setCameraOpen(!cameraOpen);
  };

  const keys = Object.keys(viewQrCodeInformations);

  keys.map((key) => {
    const codeInformation = viewQrCodeInformations[key];
    allDeatils.push(codeInformation);
  });

  return (
    <>
      {" "}
      {/* <button onClick={getWantListItems}>getWantListItems</button> */}
      <div>
        {isLoading ? (
          <div className="loader-BG">
            <Loader />
          </div>
        ) : (
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />
              <div className="right-panel">
                <>
                  <div className="text-center">
                    {cameraOpen ? (
                      <QrReader
                        delay={500}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ maxWidth: "457px", margin: "auto" }}
                      />
                    ) : (
                      <img
                        className="d-block mx-auto mb-4"
                        src="assets/images/qr-code.svg"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="text-center">
                    <button
                      className="btn-dark w-50 scanner-btn border-round"
                      onClick={() => {
                        setCameraOpen(!cameraOpen);
                      }}
                    >
                      {cameraOpen ? "Close QR Scanner" : "Scan QR Code"}
                    </button>
                  </div>
                  <div className="text-center mt-3 pro-subheading fw-bold">
                    {" "}
                    {ref?.reference_number !== null
                      ? `Reference No.- ${ref?.reference_number}`
                      : null}
                  </div>
                </>
              </div>
            </div>

            <Footer />
          </LoggedInHeader>
        )}
      </div>
    </>
  );
}

// {redeemsHistory?.length > 0 ? (
//   <>
//     {" "}
//     <div className="redeem-history">
//       <h3>
//         <strong>Redeem History</strong>
//       </h3>
//               <>
//                 <div className="tableStyle">
//                   <div className="row align-items-center mb-3">
//                   <div className="col-md-8"></div>
//                     <span>
//                       <strong>Redeem Date :</strong>{" "}
//                       {moment(
//                         redeemsHistory?.redeem_date
//                       ).format("MMMM DD-YYYY hh:mm A")}
//                     </span>
//                   </div>
//                   <div className="row">
//                     <div>
//                       <div className="card">
//                         <div className="card-body">
//                           <div className="table-responsive">
//                             <table className="table">
//                               <tbody>
//                                 <tr
//                                   style={{
//                                     textAlign: "center",
//                                   }}
//                                 >
//                                   <th>Item Name</th>

//                                   {/* <th>Seller Name</th> */}
//                                   <th>Redeem To Be</th>
//                                   <th>Remaining QTY</th>
//                                 </tr>
//                                 {redeemsHistory?.map(
//                                   (item) => {
//                                     return (
//                                       <tr
//                                         style={{
//                                           textAlign: "center",
//                                         }}
//                                       >
//                                         <td>
//                                           {item.item_name}
//                                         </td>

//                                         {/* <td>{item.seller_name}</td> */}

//                                         <td>
//                                           {
//                                             item.recent_delivered_quantity
//                                           }
//                                         </td>
//                                         <td>
//                                           {
//                                             item.recent_remaining_quantity
//                                           }
//                                         </td>
//                                       </tr>
//                                     );
//                                   }
//                                 )}
//                               </tbody>
//                             </table>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </>

//     </div>
//   </>
// ) : null}
