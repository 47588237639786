import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Import eye and eye-slash icons from react-icons
import { Logo } from "../../constants/StaticFiles";
import { UserRegistration } from "../../api/request";
import Loader from "../../components/Loader";
import { showSuccessMessage, showErrorMessage } from "../../constants/Toast";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";
function Signup() {
  const { isLoading } = useSelector((state) => state.loader);
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const navigation = useNavigate();

  useEffect(() => {
    document.title = "Paysah | SignUp";
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const submitForm = async (data) => {
    toast.dismiss();
    try {
      let res = await UserRegistration(data);
      if (res.success === true) {
        showSuccessMessage(
          res?.message
          // "Buyer has been successfully registered Please check your email for verification."
        );
        navigation("/login");
      } else {
        showErrorMessage(res?.data);
        navigation("/signup");
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  return (
    <>
  
      <div className="container">
        <div className="px-4 py-5 my-5 text-center">
          <Link to="/">
            {" "}
            <img className="d-block mx-auto heading-logo" src={Logo} alt="" />
          </Link>
          <div className="register-form">
            <form
              noValidate
              autoComplete="on"
              onSubmit={handleSubmit(submitForm)}
            >
              
              <br/>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="first_name"
                    {...register("first_name", {
                      required: {
                        value: true,
                        message: "First Name is required",
                      },
                      minLength: {
                        value: 2,
                        message: "First name should be at least 2 character",
                      },
                      pattern: {
                        value: /[A-Za-z]/,
                        message: "Please enter valid first name",
                      },
                    })}
                  />
                  <div
                    style={{
                      color: "red",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    {errors.first_name?.message}
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="last_name"
                    {...register("last_name", {
                      required: {
                        value: true,
                        message: "Last Name is required",
                      },
                      minLength: {
                        value: 2,
                        message: "Last name should be at least 2 character",
                      },
                      pattern: {
                        value: /[A-Za-z]/,
                        message: "Please enter valid last name",
                      },
                    })}
                  />
                  <div
                    style={{
                      color: "red",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    {errors.last_name?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Email address is required",
                      },
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "This is not valid email format! ",
                      },
                    })}
                  />
                  <div
                    style={{
                      color: "red",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    {errors.email?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Mobile Number"
                    name="mobile_number"
                    {...register("mobile_number", {
                      required: {
                        value: true,
                        message: "Number is required",
                      },
                      pattern: {
                        value:
                          /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                        message: "This is not valid mobile number! ",
                      },
                    })}
                  />
                  <div
                    style={{
                      color: "red",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    {errors.mobile_number?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <textarea
                    className="form-control"
                    placeholder="Address"
                    name="address"
                    {...register("address", {
                      required: {
                        value: true,
                        message: "Address is required",
                      },
                    })}
                  />
                  <div
                    style={{
                      color: "red",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    {errors.address?.message}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12" style={{ display: "flex" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Password is required",
                      },
                      pattern: {
                        value: /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}.*$/,
                        message:
                          "Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number and 1 special character(!@#$%^&*)",
                      },
                    })}
                  />
                  <button type="button" onClick={handleTogglePassword}>
                    {showPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </button>
                </div>
                <div
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  {errors.password?.message}
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-12" style={{ display: "flex" }}>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Confirm Password"
                    name="confirm_password"
                    {...register("confirm_password", {
                      required: "Confirm Password is required", // Add required message
                      validate: (val) => {
                        if (watch("password") != val) {
                          return "Your passwords do not match";
                        }
                      },
                    })}
                  />
                  <button type="button" onClick={handleToggleConfirmPassword}>
                    {showConfirmPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </button>
                </div>
                <div
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  {errors.confirm_password?.message}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  {/* {isLoading ? (
                    <Loader />
                  ) : ( */}
                  <button
                    className="btn btn-dark w-100"
                    type="submit"
                    onSubmit={(e) => e.preventDefault()}
                    disabled={!isValid || isLoading}
                  >
                    {isLoading ? <Loader /> : null}
                    Create
                  </button>
                  {/* )} */}
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-sm-12 register-link">
                  <p className="text-center">
                    Already have an account? <Link to="/login">Log In</Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
