import React from "react";
import { useNavigate } from "react-router-dom";


export default function Header({ onclickSection }) {
  const navigation = useNavigate();

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid container">
        <a className="navbar-brand" href="#"></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a
                href="#how-it-works"
                className="nav-link active"
                aria-current="page"
                style={{ textTransform: "capitalize" }}
                onClick={onclickSection}
              >
                How it works
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#pricing"
                className="nav-link"
                style={{ textTransform: "capitalize" }}
                onClick={onclickSection}
              >
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/registerasseller"
                className="nav-link"
                style={{ textTransform: "capitalize" }}
              >
                Become a seller
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </nav>
  );
}
