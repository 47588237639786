import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import SideNavigation from "../components/SideNavigation";
import Swal from "sweetalert2";
import {
  ViewOrderDetailsSeller,
  CancelOrderSeller,
  redeemOrderItemQuantitySeller,
  proceedOrder,
} from "../api/request";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import LoggedInHeader from "../components/LoggedInHeader";
export default function ViewdetailsSeller() {
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.loader);
  const [cartItems, setCartItems] = useState({});
  const [selectedQuantity, setSelectQuantity] = useState({});
  const [dataObject, setDataObject] = useState([]);
  const [cancelOrder, setCancelOrder] = useState();
  const [redeemData, setRedeemData] = useState([]);
  const [code, setCode] = useState();
  let showUpdateButton = false;
  useEffect(() => {
    document.title = "Paysah | HaveList_OrderDetails";
    const getOrderDetails = async () => {
      var sellerListItemId = localStorage.getItem("sellerListId");
      let user_details = await JSON.parse(localStorage.getItem("user_details"));
      let data = {
        id: sellerListItemId,
        seller_id: user_details.user_id,
      };
      try {
        let result = await ViewOrderDetailsSeller(data);
        if (result?.success) {
          if (result?.data) {
            setCartItems(result?.data);
          }
        } else {
          console.log("error there!");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getOrderDetails();
  }, []);
  const handleChangeItems = (e) => {
    const { name, checked } = e.target;
    let obj = {
      order_item_id: e.target.dataset.orderItemId,
      supplier_id: e.target.dataset.supplierId,
      product_id: e.target.dataset.productId,
      variant_id: e.target.dataset.variantId,
      total_quantity: e.target.dataset.totalQuantity,
      delivered_quantity: e.target.dataset.deliveredQuantity,
      item_price: e.target.dataset.price,
    };
    if (checked) {
      dataObject.push(obj);
      setDataObject([...dataObject]);
    } else {
      dataObject.pop(obj);
      setDataObject([...dataObject]);
    }
  };
  const handleRedeem = async () => {
    const sellerListCode = localStorage.getItem("sellerListCode");
    let data = {
      code: sellerListCode,
      order_items: dataObject,
    };
    try {
      let result = await redeemOrderItemQuantitySeller(data);
      if (result?.success) {
        setRedeemData(result.data);
        handleProceedOrder(result.data?.order?.id);
      } else {
        console.log("error there!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleProceedOrder = async (orderId) => {
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    let data = {
      id: orderId,
      seller_id: user_details?.user_id,
    };
    try {
      let result = await proceedOrder(data);
      if (result?.success) {
        Swal.fire({
          title: "Success",
          text: result?.message,
          icon: "success",
        });
      } else {
        console.log("error there!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = async () => {
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    const sellerlistId = localStorage.getItem("sellerListId");
    let data = {
      id: sellerlistId,
      seller_id: user_details.user_id,
    };
    try {
      let result = await CancelOrderSeller(data);
      if (result?.success) {
        setCancelOrder(result?.message);
        Swal.fire({
          title: "Success",
          text: result?.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        console.log("error there!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          {cartItems ? (
            <LoggedInHeader>
              <div className="body-container d-flex">
                <SideNavigation />
                <div className="right-panel">
                  {cartItems && (
                    <div className="page-title">
                      {cancelOrder != "Order Items cancelled successfully." ? (
                        <>
                          {" "}
                          <h2>
                            Order Id: <span>#{cartItems?.orderId}</span>
                          </h2>
                          <p>
                            <strong>Order Date:</strong>{" "}
                            <span>
                              {moment(cartItems.orderCreatedDate).format(
                                "MMMM DD-YYYY hh:mm A"
                              )}
                            </span>
                          </p>
                        </>
                      ) : null}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-lg-9">
                      {cartItems &&
                      cartItems?.items?.length > 0 &&
                      cancelOrder != "Order Items cancelled successfully." ? (
                        <div className="card shadow-sm">
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr style={{ textAlign: "center" }}>
                                    <th></th>
                                    <th>Item Name</th>
                                    <th>Size</th>
                                    <th>Price</th>
                                    <th>Ordered QTY</th>
                                    <th>Delivered QTY</th>
                                    <th>Remaining QTY</th>
                                    <th>Redeem</th>
                                  </tr>
                                  {cartItems.items.map((item) => {
                                    let remainingQty = item?.remaining_quantity;
                                    return (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>
                                          <div>
                                            <input
                                              type="checkbox"
                                              data-size-id="1"
                                              data-order-item-id={
                                                item.order_item_id
                                              }
                                              data-supplier-id={item.sellerId}
                                              data-product-id={item.product_id}
                                              data-variant-id={item.variant_id}
                                              data-total-quantity={
                                                item.orderedQuantity
                                              }
                                              data-delivered-quantity={
                                                selectedQuantity[
                                                  item.variant_id
                                                ]
                                                  ? selectedQuantity[
                                                      item.variant_id
                                                    ]
                                                  : item.delivered_quantity
                                              }
                                              data-price={item.item_price}
                                              onChange={(e) =>
                                                handleChangeItems(e)
                                              }
                                            />
                                          </div>
                                        </td>
                                        <td>{item.productName}</td>
                                        <td>
                                          {item.weight && item?.weightUnit ? (
                                            <>
                                              {" "}
                                              {item.weight}
                                              {item.weightUnit}
                                            </>
                                          ) : item.weight ? (
                                            <> {item.weight}</>
                                          ) : item.weightUnit ? (
                                            <> {item.weightUnit}</>
                                          ) : (
                                            "_"
                                          )}
                                        </td>
                                        <td>£{item.item_price}</td>
                                        <td>{item.orderedQuantity}</td>
                                        <td>
                                          {selectedQuantity[item.variant_id]
                                            ? selectedQuantity[item.variant_id]
                                            : item.delivered_quantity}
                                        </td>
                                        <td>{item.remaining_quantity}</td>

                                        <td>
                                          {remainingQty > 0 ? (
                                            <select
                                              value={
                                                selectedQuantity[
                                                  item.variant_id
                                                ] || ""
                                              }
                                              onChange={(e) => {
                                                if (e.target.value !== "") {
                                                  setSelectQuantity({
                                                    ...selectedQuantity,
                                                    [item.variant_id]:
                                                      e.target.value,
                                                  });
                                                } else {
                                                  const copy = {
                                                    ...selectedQuantity,
                                                  };
                                                  delete copy[item.variant_id];
                                                  setSelectQuantity({
                                                    ...copy,
                                                  });
                                                }
                                              }}
                                              style={{ padding: "10px" }}
                                            >
                                              <option value="">
                                                Please Select
                                              </option>
                                              {Array.from(
                                                Array(remainingQty),
                                                (e, i) => {
                                                  return (
                                                    <option
                                                      value={i + 1}
                                                      key={i}
                                                    >
                                                      {i + 1}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          ) : (
                                            0
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div className="text-center">
                              <button
                                onClick={handleRedeem}
                                className="btn btn-default btn-dark w-20 m-auto px-3 my-2"
                                disabled={dataObject.length == 0 ? true : false}
                              >
                                Confirm
                              </button>
                              <button
                                onClick={handleCancel}
                                className="cancleBtn btn btn-default btn-dark w-20 m-auto px-3 my-2"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <h4>Order Items not found</h4>
                      )}
                      {cancelOrder != "Order Items cancelled successfully." &&
                        cartItems && (
                          <div className="card shadow-sm my-4">
                            <div className="card-body">
                              <h5 className="p-2">
                                {" "}
                                <strong className="pro-subheading">
                                  Redeem History
                                </strong>
                              </h5>
                              <div className="table-responsive">
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <th width="70%">Customer Name</th>
                                      <td>{cartItems?.buyer?.name}</td>
                                    </tr>
                                    <tr>
                                      <th>Mobile Number</th>
                                      <td>{cartItems?.buyer?.mobile_number}</td>
                                    </tr>
                                    <tr>
                                      <th>Email Address</th>
                                      <td>{cartItems?.buyer?.email}</td>
                                    </tr>
                                    <tr>
                                      <th>Address</th>
                                      <td>{cartItems?.buyer?.address}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </div>
              </div>
            </LoggedInHeader>
          ) : (
            <h3
              style={{
                display: "flex",
                height: "70vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Order Items Not found.
            </h3>
          )}
          <Footer />
        </>
      )}
    </>
  );
}
