const { createSlice } = require("@reduxjs/toolkit");

const loginData = createSlice({
  name: "loginData",
  initialState: {
    loginData: {},
  },
  reducers: {
    handleLogin(state, action) {},
  },
});

export const { handleLogin } = loginData.actions;
export default loginData.reducer;
