import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

export default function LoginVerifySuccess() {
  return (
    <>
      <div className="thankYouPageCard">
        <div className="thankyoupage">
          <Link to="/">
            <img
              className="d-block mx-auto heading-logo"
              src="../../assets/images/logo.png"
              alt=""
            />
          </Link>
          <div className="wrapper">
            <div className="container">
              <div className="px-4 py-5 my-5 text-center">
                <div className="register-form">
                  <form>
                    <div className="section-heading mb-4">
                      <i
                        className=" fa fa-check-circle text-success fa-4x"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="text-center" style={{ marginBottom: "6" }}>
                      <h3>Congratulations, Your email has been verified</h3>
                    </div>
                  </form>
                    <br/>
                    <br/>
                  <div className="row mb-3">
                    <div className="col-sm-12">
                      <Link to="/login">
                        <button className="btn btn-dark w-100">Login</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
