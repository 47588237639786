import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";

import SideNavigation from "../../components/SideNavigation";
import { Order_Details, UpdateOrderStatus } from "../../api/request";
import Loader from "../../components/Loader";
import { showSuccessMessage, showErrorMessage } from "../../constants/Toast";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";
export default function OrderDetails() {
  const { isLoading } = useSelector((state) => state.loader);
  const params = useParams();
  const [orders, setOrders] = useState({});
  const [selectedQuantity, setSelectQuantity] = useState({});
  let orderDate = dateFormat(orders?.order_date, "dddd, mmmm dS, yyyy h:MM TT");
  let showUpdateButton = false;
  useEffect(() => {
    document.title = "Paysah | SellerOrderDetails";
    const getOrderDetails = async () => {
      let data = {
        order_id: params.id,
      };
      try {
        let result = await Order_Details(data);
        if (result?.success) {
          if (result?.data) {
            setOrders(result?.data);
          }
        } else {
          console.log("error there!");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getOrderDetails();
  }, []);

  const updateOrderStatus = async () => {
    try {
      let orderItems = Object.entries(selectedQuantity).map(
        ([orderId, qty]) => {
          return {
            order_item_id: Number(orderId),
            deliver_quantity: Number(qty),
          };
        }
      );

      if (orderItems.length > 0) {
        let formdata = {
          order_id: Number(params.id),
          items: orderItems,
        };
        let result = await UpdateOrderStatus(formdata);

        if (result?.success) {
          showSuccessMessage("Order status updated successfully");
          setOrders(result?.data);
          setSelectQuantity({});
        } else {
          showErrorMessage("Error occured while updating order status");
        }
      } else {
        showErrorMessage("Please select an item quantity to update");
      }
    } catch (error) {
      console.log({ error });
    }
  };

  let user_role = localStorage.getItem("user_role");
  let remQTY = orders?.order_items?.remaining_quantity;

  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          {orders && orders?.order_items?.length > 0 ? (
            <LoggedInHeader>
              <div className="body-container d-flex">
                <SideNavigation />
                <div className="right-panel">
                  {orders && (
                    <div className="page-title">
                      <h2>
                        Order Id: <span>#{orders?.order_id}</span>
                      </h2>
                      <p>
                        <strong>Order Date:</strong> <span>{orderDate}</span>
                      </p>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-lg-9">
                      {orders && orders?.order_items?.length > 0 ? (
                        <div className="card shadow-sm">
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr style={{ textAlign: "center" }}>
                                    <th>Item Name</th>
                                    <th>Size</th>
                                    <th>Sub-total</th>
                                    <th>Ordered QTY</th>
                                    <th>Delivered QTY</th>
                                    <th>Remaining QTY</th>
                                  </tr>
                                  {orders.order_items.map((order) => {
                                    let remainingQty =
                                      order?.remaining_quantity;
                                    if (remainingQty > 0) {
                                      showUpdateButton = true;
                                    }
                                    return (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{order.item}</td>
                                        <td>
                                          {order.weight} {order.weight_unit}
                                        </td>
                                        <td>{order.billing_amount}</td>
                                        <td>{order.ordered_quantity}</td>
                                        <td>{order.delivered_quantity}</td>
                                        <td>
                                          {remainingQty > 0 ? (
                                            <select
                                              value={
                                                selectedQuantity[order.id] || ""
                                              }
                                              onChange={(e) => {
                                                if (e.target.value !== "") {
                                                  setSelectQuantity({
                                                    ...selectedQuantity,
                                                    [order.id]: e.target.value,
                                                  });
                                                } else {
                                                  const copy = {
                                                    ...selectedQuantity,
                                                  };
                                                  delete copy[order.id];
                                                  setSelectQuantity({
                                                    ...copy,
                                                  });
                                                }
                                              }}
                                              style={{ padding: "10px" }}
                                            >
                                              <option value="">
                                                Please Select
                                              </option>
                                              {Array.from(
                                                Array(remainingQty),
                                                (e, i) => {
                                                  return (
                                                    <option
                                                      value={i + 1}
                                                      key={i}
                                                    >
                                                      {i + 1}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          ) : (
                                            0
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            {showUpdateButton ? (
                              <div className="text-center">
                                <button
                                  onClick={updateOrderStatus}
                                  className="btn btn-default btn-dark w-20 m-auto px-3 my-2"
                                  disabled={remQTY > 0 ? true : false}
                                >
                                  Update Status
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <h4>Order Items not found</h4>
                      )}
                      {orders && (
                        <div className="card shadow-sm my-4">
                          <div className="card-body">
                            <h5 className="p-2">
                              {" "}
                              <strong> Customer Details</strong>
                            </h5>
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th width="70%">Customer Name</th>
                                    <td>{orders?.buyer?.name}</td>
                                  </tr>
                                  <tr>
                                    <th>Mobile Number</th>
                                    <td>{orders?.buyer?.mobile_number}</td>
                                  </tr>
                                  <tr>
                                    <th>Email Address</th>
                                    <td>{orders?.buyer?.email}</td>
                                  </tr>
                                  <tr>
                                    <th>Address</th>
                                    <td>{orders?.buyer?.address}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3"></div>
                  </div>
                </div>
              </div>
            </LoggedInHeader>
          ) : (
            <h3
              style={{
                display: "flex",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Order Items Not found.
            </h3>
          )}
          <Footer />
        </>
      )}
    </>
  );
}
