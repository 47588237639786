import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SideNavigation from "../../components/SideNavigation";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";
import Loader from "../../components/Loader";
import { showOrderItemNewReactivationHistorySeller } from "../../api/request";
import moment from "moment";

const ReactivationHistorySeller = () => {
  const { isLoading } = useSelector((state) => state.loader);
  const [details, setDetails] = useState([]);
  const [selectedorderItem, setSelectedOrderItem] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedBuyerName, setselectedBuyerName] = useState("");
  const [resetValue, setResetValue] = useState(false);

  useEffect(() => {
    getReactivationDetails();
  }, [resetValue]);

  const getReactivationDetails = async (reset = false) => {
    let userInfo = localStorage.getItem("user_details");
    let userData = JSON.parse(userInfo);
    let user_id = userData?.user_id;
    let sellerData = {
      seller_id: parseInt(user_id),
      product_name: selectedProductName ? selectedProductName : "",
      order_id: parseInt(selectedorderItem) ? selectedorderItem : "",
      buyer_name: selectedBuyerName ? selectedBuyerName : "",
    };
    let defaultSellerData = {
      seller_id: parseInt(user_id),
      product_name: "",
      order_id: "",
      buyer_name: "",
    };
    try {
      let result = await showOrderItemNewReactivationHistorySeller(
        reset ? defaultSellerData : sellerData
      );
      if (result?.success) {
        setDetails(result?.data?.reactivated_items);
      } else {
        setDetails([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const filterData = (event) => {
    const inputValue = event.target.value;
    // Remove any non-digit characters from the input value
    const numericValue = inputValue.replace(/\D/g, "");
    // Check if the numeric value is greater than zero
    if (numericValue === "0") {
      // If it's zero, clear the input value
      setSelectedOrderItem("");
    } else {
      // Otherwise, update the state with the validated numeric value
      setSelectedOrderItem(numericValue);
    }
  };
  const filterDataProductName = (e) => {
    const keyword = e.target.value;
    setSelectedProductName(keyword);
  };

  const filterDataBuyerName = (e) => {
    const keyword = e.target.value;
    setselectedBuyerName(keyword);
  };

  const reset = () => {
    setResetValue(true);
    setSelectedOrderItem("");
    setSelectedProductName("");
    setselectedBuyerName("");
    getReactivationDetails(true);
  };

  const handleSearch = () => {
    getReactivationDetails();
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />

              <div className="right-panel">
                <h2>
                  <strong className="pro-subheading">
                    Reactivation History
                  </strong>
                </h2>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card shadow-sm">
                      <div className="card-body">
                        <div className="table-responsive">
                          <>
                            <h4 className="h5 text-center mb-3">
                              Fiter by your choice
                            </h4>
                            <div style={{ display: "flex", gap: "10px" }}>
                              <input
                                className="form-control"
                                type="text"
                                value={selectedorderItem}
                                onChange={filterData}
                                pattern="[1-9][0-9]*|0[1-9][0-9]*"
                                placeholder="Order id.."
                                title="Type order id"
                              ></input>
                              <input
                                className="form-control"
                                type="text"
                                value={selectedBuyerName}
                                onChange={filterDataBuyerName}
                                placeholder="Buyer name.."
                                title="Type buyer name"
                              ></input>
                              <input
                                className="form-control"
                                type="text"
                                value={selectedProductName}
                                onChange={filterDataProductName}
                                placeholder="Product name.."
                                title="Type Product name"
                              ></input>
                            </div>
                            <br />
                            <div
                              className="activation-btn"
                              style={{
                                textAlign: "center",
                                marginLeft: "-14px",
                              }}
                            >
                              <button
                                type="submit"
                                style={{ textAlign: "center" }}
                                className="btn-transparent"
                                onClick={handleSearch}
                                disabled={
                                  selectedorderItem == "" &&
                                  selectedBuyerName == "" &&
                                  selectedProductName == ""
                                    ? true
                                    : false
                                }
                              >
                                <i className="fa fa-search"></i>
                              </button>
                              <button
                                type="submit"
                                style={{ textAlign: "center" }}
                                className="btn-transparent"
                                onClick={reset}
                                value={resetValue}
                                disabled={
                                  selectedorderItem == "" &&
                                  selectedBuyerName == "" &&
                                  selectedProductName == ""
                                    ? true
                                    : false
                                }
                              >
                                <i className="fa fa-refresh"></i>
                              </button>
                            </div>
                            <br />
                            <br />
                          </>
                          <div className="table-responsive">
                            <table className="table-borderless order-table">
                              <thead>
                                <tr>
                                  <th className="OrderId">Order id</th>
                                  <th>Buyer Name </th>
                                  <th className="OrderId">Product name</th>
                                  <th>Reactivated at</th>
                                </tr>
                              </thead>
                              <tbody>
                                {details.length > 0
                                  ? details.map((curElem, id) => {
                                      return (
                                        <tr>
                                          <td>
                                            {curElem.order_number
                                              ? `# ${curElem.order_number}`
                                              : null}{" "}
                                            {curElem.title || ""}
                                          </td>
                                          <td>{curElem.buyerName || ""}</td>
                                          <td>{curElem.productName}</td>
                                          <td>
                                            {moment(
                                              curElem.reactivatedAt || ""
                                            ).format("MMMM DD-YYYY hh:mm A")}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : "No order items reactivation history found"}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </LoggedInHeader>
        </>
      )}
    </>
  );
};

export default ReactivationHistorySeller;
