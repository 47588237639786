import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { OrderHistoryBuyer } from "../api/request";
import SideNavigation from "../components/SideNavigation";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import { FaEye } from 'react-icons/fa';
import LoggedInHeader from "../components/LoggedInHeader";
export default function HaveListListing() {
  const { isLoading } = useSelector((state) => state.loader);
  const navigation = useNavigate();
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [prevPage, setPrevPage] = useState(null);
  const [perPageData] = useState(10);
  useEffect(() => {
    document.title = "Paysah | HaveListListing";
    const getOrderDetails = async () => {
      let user_details = await JSON.parse(localStorage.getItem("user_details"));
      let data = {
        buyer_id: user_details.user_id,
        page_limit: perPageData,
        page: page,
      };
      try {
        let result = await OrderHistoryBuyer(data);
        if (result?.success) {
          setOrders(result?.data?.data);
          setPrevPage(result?.data?.prev_page_url);
          setNextPage(result?.data?.next_page_url);
        } else {
          console.log("error there!");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getOrderDetails();
  }, [page]);
  const showOrderDetails = (id) => {
    localStorage.setItem("haveListId", id);
    navigation(`/stockroomdetails/${id}`);
  };
  const creatingItemsList = () => {
    navigation("/createWantList");
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />

              <div className="right-panel">
                <div className="page-title">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-7">
                          <h2>Stockroom
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {orders && orders?.length > 0 ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card shadow-sm">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table-borderless order-table">
                              <tbody>
                                <tr className="haveTable havetablehead">
                                  <th className="OrderId">Order ID</th>
                                  <th>Order Date</th>
                                  <th>Total</th>
                                  <th>View</th>
                                </tr>
                                {orders.map((order) => (
                                  <tr
                                    key={order.orderId}
                                    className="havetablelist"
                                  >
                                    <td>#{order.orderId}</td>
                                    <td>
                                      {" "}
                                      {moment(order.created_at).format(
                                        "MMMM DD-YYYY hh:mm A"
                                      )}
                                    </td>
                                    {order?.order_item_new_sum_item_total ? (
                                      <>
                                        {order?.order_item_new_sum_item_total ? (
                                          <td>
                                            £
                                            {
                                              order?.order_item_new_sum_item_total
                                            }
                                          </td>
                                        ) : null}
                                      </>
                                    ) : (
                                      <td></td>
                                    )}

                                    <td>
                                      <button
                                        className="btn btn-dark w-80 detail-btn"
                                        onClick={() =>showOrderDetails(order.id)}
                                      >
                                        <FaEye className="icon" />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {nextPage || prevPage ? (
                              <>
                                {" "}
                                <div className="StylePagination">
                                  <button
                                    disabled={prevPage == null ? true : false}
                                    onClick={() => {
                                      setPage(
                                        parseInt(prevPage?.split("=")[1])
                                      );
                                    }}
                                    className="previous text-white bg-dark border-dark"
                                  >
                                    &laquo; Previous
                                  </button>
                                  <button
                                    disabled={nextPage == null ? true : false}
                                    onClick={() => {
                                      setPage(
                                        parseInt(nextPage?.split("=")[1])
                                      );
                                    }}
                                    className="next text-white bg-dark border-dark"
                                  >
                                    Next &raquo;
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="body-container d-flex">
                      <div className="right-panel align-items-center d-flex justify-content-center have-list">
                        <div className="register-form w-100">
                          <div className="text-center mb-5">
                            <h5>Orders not found</h5>
                          </div>
                          <div>
                            <button
                              className="btn btn-dark w-100"
                              onClick={creatingItemsList}
                            >
                              Create want list
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <Footer />
          </LoggedInHeader>
        </>
      )}
    </>
  );
}
