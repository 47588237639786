import { createSlice } from "@reduxjs/toolkit";

const productList = createSlice({
  name: "productList",
  initialState: {
    productList: {},
  },
  reducers: {
    storeProductListData: (state, action) => {
      state.productList = action.payload;
    },
  },
});

export const { storeProductListData } = productList.actions;
export default productList.reducer;
