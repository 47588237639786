export const Login = "auth/login";
export const SignUp = "auth/signup";
export const ForgotPassword = "auth/forgot-password-email";
export const User_Profile = "user-details";
export const Want_List = "add-buyer-want-items";
export const Order_Create_List = "create-order";
export const Update_Profile = "update-profile";
export const Orders = "orders";
export const Order_Details = "view-order";
export const Create_Order = "create-order";
export const View_Buyer_Want_Items = "view-buyer-want-item-information";
export const Scan_QR = "view-qr-code-buyer-want-item-information";
export const Order_History = "view-order";
export const Update_Status = "update-order-quantity";
export const Delete_Want_Item = "delete-buyer-want-item";
export const Email_Verification_Link = "/auth/email_verify";
export const AddSellerProduct = "show-size-masters";
export const Create_Seller_Product = "create-seller-products";
export const Show_Seller_Product = "seller-products-list";
export const View_Seller_Products = "view-seller-products";
export const Update_Seller_Product = "update-seller-products";
export const Seller_Lists = "seller-lists";
export const Get_Buyer_Want_List = "get-buyer-want-list-seller-products";
export const Add_Buyer_Want_List = "add-buyer-want-items";
export const Delete_Seller_Product = "delete-seller-products";
export const Update_Create_Cart = "update-create-cart";
export const Get_Cart = "get-cart";
export const Delete_Cart_Item = "delete-cart-item";
export const Create_Orders = "create-orders";
export const View_Order_List = "view-order-list";
export const View_Order_Details = "view-order-details";
export const View_Qr_Code_Order_Information = "view-qr-code-order-information";
export const Redeem_Order_Item_Quantity = "redeem-order-item-quantity";
export const Proceed_Order = "proceed-order";
export const Cancel_Order = "cancel-order";
export const View_Order_List_Seller = "view-order-list-seller";
export const View_Order_Details_Seller = "view-order-details-seller";
export const cancel_Order_Seller = "cancel-order-seller";
export const Redeem_Order_Item_Quantity_Seller =
  "redeem-order-item-quantity-seller";
export const Cancel_Oorders_Whole = "cancel-orders-whole";
export const Replac_Cart_Items_Quantity = "replace-cart-items-quantity";
export const Order_History_Buyer = "view-order-list-buyer";
export const Get_Cart_Exceed_Quantity = "get-cart-exceed-quantity";
export const View_Order_Details_Seller_New = "view-order-details-seller-new";
export const Delete_Seller_Products_Option = "delete-seller-products-option";
export const invoice = "invoice";
export const sellerRegistartion = "seller-registartion";
export const update_profile_buyer = "update-profile-buyer";
export const view_order_details_stock_room = "view-order-details-stock-room";
export const view_redeem_history_stock_room = "view-redeem-history-stock-room";
export const update_front_user_reset_password =
  "update-front-user-reset-password";
export const view_order_details_stock_room_seller_wise =
  "view-order-details-stock-room-seller-wise";
export const product_List = "products-list";
export const Save_Seller_Products = "save-seller-products";
export const Categories_list = "categories-list";
export const Send_Product_Request = "send-product-request";
export const Buyer_Order_list_seller = "buyer-order-list-seller";
export const Show_Seller_Send_product_Request =
  "show-seller-send-product-request";

//** Buyer side code */
export const Buyer_Delete_Qr_Request = "delete-redemption-order-item-request";
export const Buyer_Edit_Qr_Request = "edit-redemption-order-item-request";
export const Buyer_Update_Qr_Request = "update-redemption-order-item-request";
//** Notification  */
export const View_Notifications_Buyer = "view-notifications-buyer";
export const View_Notifications_Seller = "view-notifications-seller";
//** Reactivation History */
export const show_order_item_new_reactivation_history_seller =
  "show-order-item-new-reactivation-history-seller";
export const show_order_item_new_reactivation_history_buyer =
  "show-order-item-new-reactivation-history-buyer";
//** Analytics Data status */
export const analytics_data_seller = "analytics-data-seller";
export const analytics_data_buyer = "analytics-data-buyer";
//** update order Status */
export const update_order_status = "update-order-status";
//** reactivation history */
export const Reactivate_Order_Item_New = "reactivate-order-item-new";
