import Spinner from "react-bootstrap/Spinner";

function Loader() {
  return (
    <div className=" loader-BG loader-log">
      <Spinner animation="border" role="status">
      </Spinner>
      <span className="loader-BG loader-log loader-text">Please be patient…</span>
    </div>
  );
}

export default Loader;
