import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import QRCode from "react-qr-code";

import Modal from "react-bootstrap/Modal";
import ModalBox from "../../components/ModalBox";

import {
  showErrorMessage,
  showSuccessMessage,
  showInformation,
} from "../../constants/Toast";

import { DeleteWantItems } from "../../api/request";

import { ReactComponent as DeleteIcon } from "../../assets/images/icon-delete.svg";
import SideNavigation from "../../components/SideNavigation";
import { CreateWantList, View_Buyer_Want_Items } from "../../api/request";

import {
  addItems,
  addQuantity,
  removeQuantity,
  handleWeightUnit,
  handleWeight,
  handleItemName,
  removeItem,
} from "../../redux/slices/QuantitySlice";
import Loader from "../../components/Loader";
import { hideLoading } from "../../redux/slices/LoaderSlice";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";
export default function BuyerItemList() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { wantList } = useSelector((state) => state.wantList);
  const { isLoading } = useSelector((state) => state.loader);
  const [generateQr, setGenerateQr] = useState(false);
  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);
  const [itemID, setitemID] = useState("");
  const [buyerWantID, setBuyerWantId] = useState("");
  const [errorValidation, setErrorValidation] = useState({});
  let nameValidator = /^[A-Za-z]+$/;
  let errorValue = Object.values(errorValidation);

  let errorVaildator = errorValue.every((item) => {
    return item === false;
  });

  useEffect(() => {
    document.title = "Paysah | BuyerList";
    const getBuyerWantItems = async () => {
      let user_details = await JSON.parse(localStorage.getItem("user_details"));
      let data = {
        buyer_id: user_details?.user_id,
      };
      try {
        let result = await View_Buyer_Want_Items(data);
        if (result?.success) {
          setCode(result?.data?.code);
          dispatch(hideLoading());
        } else {
          dispatch(hideLoading());
        }
      } catch (error) {
        console.log(error);
      }
    };
    getBuyerWantItems();
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (item) => {
    setShow(true);
    setitemID(item.id);
    setBuyerWantId(item.buyer_want_list_id);
  };

  let itemNameValidation = wantList.every((item) => {
    return nameValidator.test(item.item_name);
  });
  let itemWeightValidation = wantList.every((item) => {
    return item.weight;
  });

  const handleItemNameVaildation = (value, type, item) => {
    if (type === "item_name") {
      let payload = {
        item: item,
        item_name: value,
      };

      dispatch(handleItemName(payload));

      let nameValidation = nameValidator.test(value);
      if (nameValidation) {
        setErrorValidation({
          ...errorValidation,
          [item.id]: false,
        });
      } else {
        setErrorValidation({
          ...errorValidation,
          [item.id]: true,
        });
      }
    } else {
      console.log("failed");
    }
  };

  const GenerateQRForList = async () => {
    setGenerateQr(true);
    let user_details = await JSON.parse(localStorage.getItem("user_details"));

    let itemNameexist = wantList.every((item) => {
      return item.item_name;
    });
    let itemWeightexist = wantList.every((item) => {
      return item.weight;
    });
    let weightDetails = wantList.map((item) => {
      return {
        item_id: item.id,
        weight_unit: item.weight_unit,
        weight: item.weight,
      };
    });
    let weightData = weightDetails.filter((item) => {
      return item.weight > 100 && item.weight_unit === "kg";
    });

    let weightUnitData = weightDetails.filter((item) => {
      return item.weight > 100000 && item.weight_unit === "gm";
    });

    if (
      user_details?.user_id &&
      errorVaildator &&
      itemNameexist &&
      itemWeightexist
    ) {
      if (weightData.length === 0 && weightUnitData.length === 0) {
        let data = {
          customer_id: user_details?.user_id,
          customer_name: user_details?.user_name,
          items: wantList && wantList,
        };
        try {
          let res = await CreateWantList(data);
          if (res?.success) {
            showSuccessMessage("QR generated successfully");
            navigation("/buyercode", { state: { qr_code: res?.data?.qrcode } });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        showInformation("Weight cannot be more than 100000 gm");
      }
    } else if (
      user_details?.user_id &&
      itemNameexist &&
      errorVaildator === false &&
      itemWeightexist
    ) {
      showInformation("Please fill valid name format");
    } else if (user_details?.user_id && itemNameexist === false) {
      showErrorMessage("Please fill all the details");
    }
  };

  const addRow = () => {
    dispatch(
      addItems({
        id: uuidv4(),
        item_name: "",
        weight_unit: "gm",
        weight: "",
        quantity: 1,
      })
    );
  };

  const DeleteWantItem = async () => {
    try {
      if (typeof itemID === "number" && wantList.length > 0) {
        setShow(false);
        // dispatch(removeItem(itemID));
        let data = {
          want_list_id: buyerWantID,
          want_item_id: itemID,
        };

        let result = await DeleteWantItems(data);

        if (result.success === true) {
          showSuccessMessage("Item deleted sucessfully");
          setitemID("");
          setBuyerWantId("");
          dispatch(removeItem(itemID));
        } else {
          showErrorMessage("Error occured while deleting the item");
        }
      } else if (
        typeof itemID === "string" &&
        wantList &&
        wantList.length > 0
      ) {
        setShow(false);
        dispatch(removeItem(itemID));
        showSuccessMessage("Item deleted sucessfully");
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const defaultList = () => {
    try {
      if (wantList.length <= 0) {
        addRow();
      } else {
        return;
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <LoggedInHeader>
          <div className="body-container d-flex">
            <SideNavigation />
            <div className="right-panel">
              <div className="register-form full-width">
                <div className="text-center mb-5">
                  <h5>Add Want List items</h5>
                </div>
                {wantList && wantList.length > 0 && (
                  <div className="item-list">
                    <div className="item">
                      <table width="100%" cellPadding="15">
                        <tr>
                          <th>Item Name</th>
                          <th>Sizing Unit</th>
                          <th>Size</th>
                          <th>Quantity</th>
                        </tr>
                        {wantList &&
                          wantList.map((listItem, index) => (
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Type Name"
                                  value={listItem.item_name}
                                  // onChange={(e) => {
                                  //   handleItemNameVaildation(
                                  //     e.target.value,
                                  //     "item_name",
                                  //     listItem
                                  //   );
                                  // }}
                                  onChange={(e) => {
                                    let payload = {
                                      item: listItem,
                                      value: e.target.value,
                                    };
                                    dispatch(handleItemName(payload));
                                  }}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: 11,
                                    display: "block",
                                  }}
                                >
                                  {" "}
                                  {itemNameValidation === false && generateQr
                                    ? "Please enter valid name"
                                    : ""}
                                </span>
                              </td>
                              <td>
                                <select
                                  className="form-control"
                                  value={listItem.weight_unit}
                                  onChange={(e) => {
                                    let payload = {
                                      item: listItem,
                                      value: e.target.value,
                                    };
                                    dispatch(handleWeightUnit(payload));
                                  }}
                                >
                                  <option>kg</option>
                                  <option>gm</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  width="100"
                                  type="number"
                                  value={listItem.weight}
                                  onChange={(e) => {
                                    let payload = {
                                      item: listItem,
                                      value: e.target.valueAsNumber,
                                    };
                                    dispatch(handleWeight(payload));
                                  }}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: 11,
                                    display: "block",
                                  }}
                                >
                                  {itemWeightValidation === false && generateQr
                                    ? "Please enter weight"
                                    : ""}
                                </span>
                              </td>
                              <td>
                                <div className="quantity">
                                  <div className="number">
                                    <span
                                      className="minus"
                                      onClick={() => {
                                        if (listItem.quantity === 1) {
                                          return dispatch(
                                            removeItem(listItem.id)
                                          );
                                        }
                                        dispatch(removeQuantity(listItem));
                                      }}
                                    >
                                      -
                                    </span>
                                    <input
                                      width="300"
                                      type="number"
                                      className="quantity input"
                                      value={listItem.quantity}
                                    />
                                    <span
                                      className="plus"
                                      onClick={() =>
                                        dispatch(addQuantity(listItem))
                                      }
                                    >
                                      +
                                    </span>
                                  </div>
                                </div>
                                <div className="icon-delete">
                                  <button onClick={() => handleShow(listItem)}>
                                    <DeleteIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </table>
                    </div>
                  </div>
                )}
                <div className="text-end mt-2">
                  <button
                    className="btn-dark more-btn border-round"
                    onClick={addRow}
                  >
                    {wantList && wantList?.length > 0
                      ? "Add more"
                      : defaultList()}
                  </button>
                </div>
                <div className="text-start mt-2 text-center">
                  {code && (
                    <>
                      <QRCode
                        value={code}
                        style={{ height: "100px", width: "100px" }}
                        size={100}
                      />
                      <p>
                        <b>Note:</b>Current Want-Items QR Code
                      </p>
                    </>
                  )}
                </div>

                {isLoading ? (
                  <Loader />
                ) : (
                  <div>
                    <button
                      disabled={wantList && wantList?.length > 0 ? false : true}
                      onClick={GenerateQRForList}
                      className="btn btn-dark w-100 mt-5"
                    >
                      Generate QR
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <ModalBox
              handleClose={handleClose}
              DeleteWantItem={DeleteWantItem}
            />
          </Modal>
          <Footer />
        </LoggedInHeader>
      )}
    </>
  );
}
