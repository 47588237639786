import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedOrders = ({ redirectPath = "/", children }) => {
  if (!localStorage.getItem("token")) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedOrders;
