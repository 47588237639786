import { createSlice } from "@reduxjs/toolkit";

const userProfile = createSlice({
  name: "userProfile",
  initialState: {
    profileData: {},
  },
  reducers: {
    storeProfileData: (state, action) => {
      state.profileData = action.payload;
    },
  },
});

export const { storeProfileData } = userProfile.actions;
export default userProfile.reducer;
