import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import Modal from "react-bootstrap/Modal";
import ModalBox from "../components/ModalBox";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";

import {
  showErrorMessage,
  showSuccessMessage,
  showInformation,
} from "../constants/Toast";
import {
  DeleteSellerProductsItems,
  sendProductReq,
  categoryList,
} from "../api/request";
import { ReactComponent as DeleteIcon } from "../assets/images/icon-delete.svg";
import SideNavigation from "../components/SideNavigation";
import { CreateWantList, addSellerProduct } from "../api/request";
import {
  addItems,
  addQuantity,
  removeQuantity,
  handleWeightUnit,
  handleWeight,
  handlePrice,
  handleExpire,
  handleBuffer,
  handleItemName,
  removeItem,
  clearWantList,
} from "../redux/slices/QuantitySlice";
import Loader from "../components/Loader";
import { hideLoading } from "../redux/slices/LoaderSlice";
import Footer from "../components/Footer";
import LoggedInHeader from "../components/LoggedInHeader";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

export default function AddProduct() {
  const { isLoading } = useSelector((state) => state.loader);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { wantList } = useSelector((state) => state.wantList);
  const [priceLimit, setPriceLimit] = useState();
  const [submit, setSubmit] = useState(false);
  const [selectOption, setSelectOPtion] = useState([]);
  const [code, setCode] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [show, setShow] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [ProductName, setProductName] = useState("");
  const [itemUniqId, setItemUniqId] = useState("");
  const [itemID, setitemID] = useState("");
  const [buyerWantID, setBuyerWantId] = useState("");
  const [errorValidation, setErrorValidation] = useState({});
  const [addMore, setAddMore] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectionButton, setRedirectionButton] = useState(false);
  const [validation, setValidation] = useState("false");
  const [Category, setCategory] = useState("");
  const [sellerProductSizeOptionId, setSellerProductSizeOptionId] =
    useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectType, setSelectType] = useState("");
  const [selectCount, setSelectCount] = useState(1);
  const [offerValue, setOfferValue] = useState(0);
  const [error, setError] = useState("");
  const [offererror, setOfferError] = useState("");
  const [selectCategory, setSelectCategory] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [chooseCategory, setChooseCategory] = useState("Category Options");
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategoryField, setSelectedCategoryField] =
    useState("Select Category");
  const [categoryId, setCategoryId] = useState();

  let nameValidator = /^[A-Za-z]+$/;
  let errorValue = Object.values(errorValidation);

  // let hasPriceNumberGreaterSeven = false;
  useEffect(() => {
    document.title = "Paysah | CreateSellerProduct";
    addSellerProducts();
    dispatch(clearWantList());
    setProductName("");
    setTextArea("");
    setSelectType(3);
    setSelectCount("");
    setOfferValue("");
    handleSelectCategory();
  }, []);

  const addSellerProducts = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        let response = await addSellerProduct(token);
        if (response?.success) {
          // showSuccessMessage()
          if (response.data.length > 0) {
            let opts = [];
            response.data.forEach((opt, i) => {
              let dt = { id: opt.id, unit: opt.packing_unit };
              opts.push(dt);
            });
            setSelectOPtion(opts);
          }
        } else {
          setSuccessMessage(false);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleTextArea = (event) => {
    setTextArea(event.target.value);
  };
  function handleChangeProductName(e) {
    e.preventDefault();
    setProductName(e.target.value);
  }
  function handleChangeTextArea(e) {
    e.preventDefault();
    setTextArea(e.target.value);
  }
  const handleChangeSelectType = (event, type) => {
    setSelectType(parseInt(event.target.value));
  };

  function handleChangeSelectCount(event, type) {
    const value = Number(event.target.value);
    // setSelectCount({ ...selectCount, [type]: value });
    if (!isNaN(value) && value >= 1) {
      setSelectCount({ ...selectCount, [type]: value });
    } else {
      setSelectCount("");
      // setError('Bought count should be greater than 1');
    }
  }
  function handleChangeOfferValue(event, type) {
    const value = Number(event.target.value);
    // setOfferValue({ ...offerValue, [type]: value });
    if (!isNaN(value) && value >= 0) {
      setOfferValue({ ...offerValue, [type]: value });
    } else {
      setOfferValue("");
      // setOfferError('Offer value should be greater than 0 ');
    }
  }

  function handleKeypress(e, type) {
    if (type == 2) {
      const characterCode = e.key;
      if (characterCode === "Backspace") return;
      const characterNumber = Number(characterCode);
      if (characterNumber >= 0 && characterNumber <= 9) {
        if (e.currentTarget.value && e.currentTarget.value.length) {
          return;
        } else if (characterNumber === 0) {
          e.preventDefault();
        }
      } else {
        e.preventDefault();
      }
    }
  }
  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      className="input-text-box"
      value={value}
      onClick={onClick}
      readOnly // Make the input read-only
    />
  );
  const handleShow = (item) => {
    setShow(true);
    if (item?.seller_id) {
      setitemID(item.seller_id);
      setBuyerWantId(item.seller_product_id);
      setSellerProductSizeOptionId(item.seller_product_size_option_id);
    } else {
      setItemUniqId(item?.id);
    }
  };

  const DeleteWantItem = async () => {
    try {
      if (typeof itemID === "number" && wantList.length > 0) {
        setShow(false);
        let data = {
          seller_id: itemID,
          seller_product_id: buyerWantID,
          seller_product_size_options_id: sellerProductSizeOptionId,
        };
        let result = await DeleteSellerProductsItems(data);
        if (result.success === true) {
          showSuccessMessage("Item deleted sucessfully");
          setitemID("");
          setBuyerWantId("");
          dispatch(removeItem(itemID));
          window.location.reload();
        } else {
          showErrorMessage("Error occured while deleting the item");
        }
      } else if (
        itemID &&
        typeof itemID === "string" &&
        wantList &&
        wantList.length > 0
      ) {
        setShow(false);
        dispatch(removeItem(itemID));
        showSuccessMessage("Item deleted sucessfully");
      } else if (itemUniqId) {
        setShow(false);
        dispatch(removeItem(itemUniqId));
        setItemUniqId("");
      }
    } catch (error) {
      console.log({ error });
    }
  };
  let itemPriceValidation = [];
  let itemPriceLengthValidation = [];
  let itemWeightValidation = [];
  let itemWeightUnitValidation = [];
  let itemWeightLimitValidation = [];
  let itemSizeValidation = [];
  let itemUnitValidation = [];
  itemWeightLimitValidation = wantList
    .filter((item) => {
      return item.weight.toString().length >= 7;
    })
    .map((item) => {
      return item.id;
    });

  itemWeightValidation = wantList
    .filter((item) => {
      return !item.weight;
    })
    .map((item) => item.id);

  const WeightLimits = itemWeightLimitValidation.map(
    (opt) => opt.toString().length
  );

  const itemPriceLimitValidation = wantList
    .filter((item) => {
      return item.price;
    })
    .map((item) => {
      return item.price;
    });
  const priceLimits = itemPriceLimitValidation.map((opt) => {
    let number = opt.toString();
    if (number.includes(".")) {
      return opt.toString().length - 2;
    } else {
      return opt.toString().length;
    }
  });
  const hasWeightNumberGreaterSeven = WeightLimits.some((number) => {
    return number > 7;
  });

  const hasPriceNumberGreaterSeven = priceLimits.some((number) => {
    return number > 7;
  });
  itemPriceLengthValidation = wantList
    .filter((item) => {
      return item.price.toString().length >= 7;
    })
    .map((item) => {
      return item.id;
    });

  itemPriceValidation = wantList
    .filter((item) => {
      return !item.price;
    })
    .map((item) => item.id);

  itemSizeValidation = wantList
    .filter((item) => {
      return !item.weight;
    })
    .map((item) => item.id);
  itemUnitValidation = wantList
    .filter((item) => {
      return !item.size_id;
    })
    .map((item) => item.id);

  itemWeightUnitValidation = wantList
    .filter((item) => {
      return !item.size_id;
    })
    .map((item) => item.id);

  const addRow = () => {
    setAddMore(true);
    dispatch(
      addItems({
        id: uuidv4(),
        weight: "",
        size_id: "",
        price: "",
        total_quantity: 1,
      })
    );
  };

  const defaultList = () => {
    try {
      if (wantList.length <= 0) {
        addRow();
      } else {
        return;
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSubmit = async () => {
    setSubmit(true);
    toast.dismiss();
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    let data;
    if (selectedCategoryField == "Others") {
      data = {
        user_id: user_details.user_id,
        product_title: ProductName,
        category_id: 0,
        category_name: Category,
        description: textArea,
      };
    } else {
      data = {
        user_id: user_details.user_id,
        product_title: ProductName,
        category_id: categoryId,
        description: textArea,
      };
    }
    try {
      let result = await sendProductReq(data);

      if (result?.success) {
        showSuccessMessage(result?.message);
        setProductName("");
        setTextArea("");
        setCategory("");
        setCategoryId("");
        setSelectedCategoryField("Select Category");
        setSubmit(false);
      } else {
        showErrorMessage(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function handleChangeCategory(e) {
    e.preventDefault();
    setCategory(e.target.value);
  }
  const handleCategoryData = (title, id) => {
    setCategoryId(id);
    if (title == "Others") {
      setAddCategory(true);
    } else {
      setAddCategory(false);
    }
    setSelectedCategoryField(title);
  };

  const handleBack = () => {
    navigation(`/sellerProductList`);
  };
  const handleSelectCategory = async () => {
    setAddCategory(false);
    setSelectCategory(true);
    setChooseCategory("Choose Category");
    try {
      let result = await categoryList();
      if (result?.success) {
        if (result?.data) {
          setCategoryData(result?.data);
        }
      } else {
        showErrorMessage(result?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <LoggedInHeader>
          <div className="body-container d-flex">
            <SideNavigation />
            <div className="right-panel">
              <div className="styleBtn">
                <button
                  className="btn btn-dark w-40 bg-dark"
                  onClick={handleBack}
                >
                  <i class="fa fa-arrow-circle-left" aria-hidden="true">
                    {" "}
                  </i>
                </button>
              </div>
              <div className="register-form full-width">
                <div className="text-center mb-5">
                  <h2>
                    <strong>New Product Request</strong>
                  </h2>
                </div>
                <div className="cat-dropdown">
                  <label>Select Category (optional)</label>
                  <Dropdown
                    style={{
                      boxShadow: "none !important",
                    }}
                  >
                    <div className="d-flex justify-content-start">
                      <Dropdown.Toggle
                        style={{
                          boxShadow: "none !important",
                        }}
                      >
                        {selectedCategoryField}
                      </Dropdown.Toggle>
                    </div>

                    <Dropdown.Menu
                      style={{
                        maxHeight: "250px",
                        overflowY: "auto",
                        boxShadow: "none !important",
                      }}
                    >
                      {categoryData && categoryData.length ? (
                        categoryData.map((item, id) => (
                          <Dropdown.Item
                            key={id}
                            style={{ display: "flex" }}
                            onClick={() => {
                              handleCategoryData(item.title, item.id);
                            }}
                          >
                            {item.title}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <div className="saveWantList col-sm-12">
                          <span>Category not available</span>
                        </div>
                      )}
                      <Dropdown.Item
                        style={{ display: "flex" }}
                        onClick={() => {
                          handleCategoryData("Others");
                        }}
                      >
                        Others
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* {submit && selectedCategoryField == "Select Category" ?  <div
                          style={{
                            color: "red",
                            textAlign: "left",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          Category Is Required
                        </div> : null} */}
                  {addCategory ? (
                    <>
                      <br />
                      <label>
                        Category Name <label style={{ color: "red" }}>*</label>
                      </label>
                      <input
                        type="text"
                        name="category"
                        className="form-control"
                        placeholder="Category Name"
                        value={Category}
                        onChange={handleChangeCategory}
                      />
                      {Category.length > 255 ? (
                        <div
                          style={{
                            color: "red",
                            textAlign: "left",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          Category should not increase 255 characters
                        </div>
                      ) : null}

                      {submit && Category?.length == 0 ? (
                        <div
                          style={{
                            color: "red",
                            textAlign: "left",
                            fontSize: "14px",
                            marginTop: "10px",
                          }}
                        >
                          Category is required
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
                <br />
                <div>
                  <label>
                    Product Name <label style={{ color: "red" }}>*</label>
                  </label>
                  <input
                    type="text"
                    name="item_name"
                    className="form-control"
                    placeholder="Product Name"
                    value={ProductName}
                    onChange={handleChangeProductName}
                  />
                  {ProductName.length > 255 ? (
                    <div
                      style={{
                        color: "red",
                        textAlign: "left",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      Product Name should not increase 255 characters
                    </div>
                  ) : null}

                  {submit && ProductName?.length == 0 ? (
                    <div
                      style={{
                        color: "red",
                        textAlign: "left",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      Product name is required
                    </div>
                  ) : null}
                  <div>
                    <br />
                    <label>Product Description (optional)</label>
                    <input
                      className="form-control"
                      type="textarea"
                      placeholder="Product Description"
                      name="textValue"
                      maxLength={100}
                      value={textArea}
                      onChange={handleTextArea}
                    />
                  </div>
                  <br />
                </div>

                {isLoading ? (
                  <div className="loader-BG">
                    <Loader />
                  </div>
                ) : (
                  <div className="add-form-submit">
                    <button
                      onClick={handleSubmit}
                      className="btn btn-dark w-100 mt-5 submit-btn-style "
                    >
                      Send Request
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <ModalBox
              handleClose={handleClose}
              DeleteWantItem={DeleteWantItem}
            />
          </Modal>
          <Footer />
        </LoggedInHeader>
      )}
    </>
  );
}
