import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showSuccessMessage = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const showErrorMessage = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const showWarning = (message) => {
  toast.warn(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const showInformation = (message) => {
  toast.info(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};
