import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Logo } from "../../constants/StaticFiles";
import { ForgotPassword } from "../../api/request";
import Loader from "../../components/Loader";

import { showSuccessMessage, showInformation } from "../../constants/Toast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";

export default function Forgot() {
  const { isLoading } = useSelector((state) => state.loader);

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const navigation = useNavigate();

  useEffect(() => {
    document.title = "Paysah | ForgotPassword";
  });

  const submitForm = async (data) => {
    toast.dismiss();
    try {
      let res = await ForgotPassword(data);
      if (res.success === true) {
        showSuccessMessage("Verification link sent successfully.");
        navigation("/email-template", {
          state: {
            email: res.data,
          },
        });
      } else {
        showInformation(
          "If you are registered with us, you will get a verification email."
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="wrapper">
        {/* <div className="top-action-bar">
          <div className="container">
            <Header onclickSection={() => navigation("/")} />
          </div>
        </div> */}
        <div className="container">
          <div className="px-4 py-5 my-5 text-center">
            <Link to="/">
              <img className="d-block mx-auto heading-logo" src={Logo} alt="" />
            </Link>
            <div className="register-form">
              <form noValidate onSubmit={handleSubmit(submitForm)}>
                <div className="section-heading">
                  <h1>Forgot Password</h1>
                </div>
                <div className="row">
                  <div className="col-sm-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Registered Email Address"
                      name="email"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Email address is required",
                        },
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "This is not valid email fromat! ",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    {/* {isLoading ? (
                      <Loader />
                    ) : ( */}
                    <button className="btn btn-dark w-100" disabled={!isValid || isLoading}>
                    {isLoading ? <Loader /> : null}
                      Submit
                    </button>
                    {/* )} */}
                  </div>
                </div>
                <div className="text-center register-link mb-5">
                  Remember Password? <Link to="/login">Login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
