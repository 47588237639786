import { configureStore } from "@reduxjs/toolkit";
//import createSagaMiddleware from "@redux-saga/core";

import wantListReducer from "../slices/QuantitySlice";
import loginDataReducer from "../slices/LoginSlice";
import loaderReducer from "../slices/LoaderSlice";
import userProfileReducer from "../slices/ProfileSlice";
import productListReducer from "../slices/productList";
//const saga = createSagaMiddleware();

const store = configureStore({
  reducer: {
    wantList: wantListReducer,
    loginData: loginDataReducer,
    loader: loaderReducer,
    userProfile: userProfileReducer,
    productList : productListReducer
  },
  // middleware: [saga],
});

export default store;
