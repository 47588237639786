import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SideNavigation from "../../components/SideNavigation";
import { GetUserDetails, UpdateProfile } from "../../api/request";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { showErrorMessage, showSuccessMessage } from "../../constants/Toast";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";

export default function SellerProfile() {
  const { userProfile } = useSelector((state) => state);
  const { isLoading } = useSelector((state) => state.loader);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tags, setTags] = React.useState([]);
  const [postalCodeError, setPostalCodeError] = useState(false);

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event, postalValidator) => {
    if (event.key === "Enter") {
      const trimmedValue = event.target.value.trim();
      if (trimmedValue && postalValidator.test(trimmedValue)) {
        setUserDetail((prevUserDetail) => ({
          ...prevUserDetail,
          postcodes: "",
        }));
        setTags([...tags, trimmedValue]);
      }
    }
  };

  const [userDetail, setUserDetail] = useState({
    user_id: "",
    name: "",
    business_name: "",
    business_type: "",
    mobile_number: "",
    address: "",
    address_2: "",
    email: "",
    postcodes: [],
    errorFirstName: "",
    errorBusinessName: "",
    errorBusinessType: "",
    errorPhoneNumber: "",
    errorPostalCode: "",
    errorCity: "",
    errorAddress1: "",
    // errorCountry: "",
  });

  let profileTitle;
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    profileTitle =
      localStorage.getItem("user_role") === "Buyer"
        ? "BuyerProfile"
        : "SellerProfile";
    setUserRole(profileTitle);

    document.title = `Paysah | ${profileTitle}`;
    const getUserData = async () => {
      try {
        let data = await GetUserDetails();
        if (data.success) {
          const {
            name,
            business_name,
            business_type,
            mobile_number,
            email,
            address,
            address_2,
            city,
            country,
postcode,
            postcodes,
          } = data?.data;
          const postCodeList =
            (postcodes && postcodes.map((item) => item.postcode)) || null;
          if (profileTitle == "SellerProfile") {
            setTags(postCodeList);
            setUserDetail({
              ...userDetail,
              name: name,
              business_name,
              business_type,
              mobile_number,
              email,
              address,
              address_2,
              city,
              country,
              postcodes:  postcode
              
            });
          }
          // else {
          //   setUserDetail({
          //     ...userDetail,
          //     first_name: first_name,
          //     last_name,
          //     email,
          //     mobile_number,
          //     address,
          //   });
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, []);

  const postalValidator = /^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][A-Za-z]{2}$/;
  const handleFieldValidations = (value, type) => {
    let nameValidator = /[A-Za-z]/;
    let phoneValidator =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

    if (type === "name") {
      setUserDetail({
        ...userDetail,
        name: value,
        errorFirstName: type === "name" ? nameValidator.test(value) : false,
      });
    } else if (type === "business_name") {
      setUserDetail({
        ...userDetail,
        business_name: value,
        errorBusinessName:
          type === "business_name" ? nameValidator.test(value) : false,
      });
    } else if (type === "business_type") {
      setUserDetail({
        ...userDetail,
        business_type: value,
        errorBusinessType:
          type === "business_type" ? nameValidator.test(value) : false,
      });
    } else if (type === "mobile_number") {
      setUserDetail({
        ...userDetail,
        mobile_number: value,
        errorPhoneNumber:
          type === "mobile_number" ? phoneValidator.test(value) : false,
      });
    } else if (type === "address") {
      setUserDetail({
        ...userDetail,
        address: value,
        errorAddress1:
          type === "business_type" ? nameValidator.test(value) : false,
      });
    } else if (type === "postcodes") {
      const isValidPostalCode = postalValidator.test(value);
      setUserDetail({
        ...userDetail,
        postcodes: value,
        errorPostalCode: !isValidPostalCode,
      });
      setPostalCodeError(!isValidPostalCode);
    } else if (type === "city") {
      setUserDetail({
        ...userDetail,
        city: value,
        errorCity: type === "city" ? nameValidator.test(value) : false,
      });
    } else if (type === "country") {
      setUserDetail({
        ...userDetail,
        country: value,
        // errorCountry: type === "country" ? nameValidator.test(value) : false,
      });
    }
  };

  const submitProfile = async () => {
    setIsSubmitting(true);
    if(postalCodeError){
      showErrorMessage("Something went wrong")
    }
    else{
      toast.dismiss();
      let getUser_id = await JSON.parse(localStorage.getItem("user_details"));
      let user_id = await getUser_id?.user_id;
      const {
        name,
        business_name,
        business_type,
        mobile_number,
        email,
        address,
        address_2,
        city,
        country,
      } = userDetail;
      let data = {
        user_id: user_id,
        first_name: name,
        business_name: business_name,
        business_type: business_type,
        mobile_number: mobile_number,
        email: email,
        address: address,
        address_2: address_2,
        city: city,
        country: country,
        postcodes: userDetail?.postcodes,
      };

      try {
        let result = await UpdateProfile(data);
        if (result?.success) {
          showSuccessMessage(result?.message);
          setIsSubmitting(false);
        } else {
          if (result?.errors?.postcodes?.[0]) {
            showErrorMessage(result?.errors?.postcodes?.[0]);
          }
          if (result?.success == false) {
            showErrorMessage(result?.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
   
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <LoggedInHeader>
          <div className="body-container d-flex pro-subheading">
            <SideNavigation />
            <div className="right-panel">
              <h2>
                <strong className="pro-subheading">Account</strong>
              </h2>
              <div className="card shadow-sm mb-4 py-4">
                <div className="card-body text-center">
                  <div className="profile-container">
                    <h5>
                      {" "}
                      <strong className="pro-subheading">
                        {userProfile &&
                          "Welcome " + userProfile?.profileData?.name}
                      </strong>{" "}
                    </h5>
                    <hr />
                    <div className="text-start pt-4">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Name</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="text"
                              value={userDetail && userDetail?.name}
                              onChange={(e) =>
                                handleFieldValidations(e.target.value, "name")
                              }
                            />
                            <span style={{ color: "red", fontSize: 15 }}>
                              {userDetail?.errorFirstName === false
                                ? "Please enter valid name (Name cannot be numbers only!)"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Business Name</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="text"
                              value={userDetail && userDetail?.business_name}
                              onChange={(e) =>
                                handleFieldValidations(
                                  e.target.value,
                                  "business_name"
                                )
                              }
                            />
                            <span style={{ color: "red", fontSize: 15 }}>
                              {userDetail?.errorBusinessName === false
                                ? "Please enter valid business name (Business name cannot be numbers only!)"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Business Type</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="text"
                              value={userDetail && userDetail?.business_type}
                              onChange={(e) =>
                                handleFieldValidations(
                                  e.target.value,
                                  "business_type"
                                )
                              }
                            />
                            <span style={{ color: "red", fontSize: 15 }}>
                              {userDetail?.errorBusinessType === false
                                ? "Please enter valid business type(Business type cannot be numbers only!)"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Phone Number</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="number"
                              value={userDetail && userDetail?.mobile_number}
                              onChange={(e) =>
                                handleFieldValidations(
                                  e.target.value,
                                  "mobile_number"
                                )
                              }
                            />
                            <span style={{ color: "red", fontSize: 15 }}>
                              {userDetail?.errorPhoneNumber === false
                                ? "Please enter valid phone number"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Email Address</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="email"
                              value={userDetail && userDetail?.email}
                              readOnly
                            />
                          </div>

                          <div className="col-md-6"></div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Address Line 1</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="text"
                              value={userDetail && userDetail?.address}
                              onChange={(e) =>
                                handleFieldValidations(
                                  e.target.value,
                                  "address"
                                )
                              }
                            />
                            <span style={{ color: "red", fontSize: 15 }}>
                              {userDetail?.errorAddress1 === false
                                ? "Please enter valid address"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Address Line 2</div>
                          <div className="col-md-6">
                            <textarea
                              className="form-control"
                              onChange={(e) => {
                                setUserDetail({
                                  ...userDetail,
                                  address_2: e.target.value,
                                });
                              }}
                              value={userDetail && userDetail?.address_2}
                            />
                          </div>
                        </div>
                      </div>

                      {userRole == "SellerProfile" ? (
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-6">Postal Code</div>
                            <div className="col-md-6">
                              <div className="tags-input-profile">
                                {/* {tags.length > 0
                                  ? tags?.map((tag, index) => (
                                      <li key={index} className="tag">
                                        <span className="tag-title">{tag}</span>
                                        <span
                                          className="tag-close-icon"
                                          onClick={() => removeTags(index)}
                                        >
                                          x
                                        </span>
                                      </li>
                                    ))
                                  : null} */}
                                <input
                                  type="text"
                                  className="form-control"
                                   value={userDetail?.postcodes}
                                  onKeyUp={(event) =>
                                    addTags(event, postalValidator)
                                  }
                                  placeholder="Enter Postal Code"
                                  maxLength={9}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    setUserDetail((prevUserDetail) => ({
                                      ...prevUserDetail,
                                      postcodes: value,
                                    }));
                                    handleFieldValidations(value, "postcodes");
                                  }}
                                />
                                <span style={{ color: "red", fontSize: 15 }}>
                                  {postalCodeError
                                    ? "Please enter a valid postal code"
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Town/City</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="text"
                              value={userDetail && userDetail?.city}
                              onChange={(e) =>
                                handleFieldValidations(e.target.value, "city")
                              }
                            />
                            <span style={{ color: "red", fontSize: 15 }}>
                              {userDetail?.errorCity === false
                                ? "Please enter valid city (city cannot be numbers only!)"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Country</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="text"
                              value={userDetail && userDetail?.country}
                              onChange={(e) =>
                                handleFieldValidations(
                                  e.target.value,
                                  "country"
                                )
                              }
                            />
                            {/* <span style={{ color: "red", fontSize: 15 }}>
                              {userDetail?.errorCountry === false
                                ? "Please enter valid country name (country name cannot be numbers only!)"
                                : ""}
                            </span> */}
                            <div className="w-100 profile-update-btn">
                              <button
                                className="btn btn-dark float-right profile-btn border-round"
                                type="submit"
                                onClick={submitProfile}
                                // disabled={isSubmitting ? true : false} // Disable the button when submitting
                              >
                                {isLoading ? <Loader /> : null}
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </LoggedInHeader>
      )}
    </>
  );
}
