
export const handleLogout = () => {
  // localStorage.removeItem('user_role');
  // localStorage.removeItem('token');
  // localStorage.removeItem('setupTime');
  localStorage.clear();
}



 

