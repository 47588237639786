import React, { useEffect, useState } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import SideNavigation from "../components/SideNavigation";
import { ViewRedeemHistoryStockRoom } from "../api/request";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import LoggedInHeader from "../components/LoggedInHeader";
// import { showErrorMessage, showInformation } from "../constants/Toast";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

export default function RedeemHistory() {
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.loader);
  const [cartItems, setCartItems] = useState({});
  const [selectedQuantity, setSelectQuantity] = useState({});
  const [dataObject, setDataObject] = useState([]);
  const [code, setCode] = useState();
  const [apiResponse, setApiResponse] = useState();
  const [detailRedeemedOrderItems, setDetailRedeemedOrderItems] = useState([]);
  const [sellerId, setSellerId] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [redeemQtyV, setRedeemQtyV] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);
  const [prevPage, setPrevPage] = useState(null);
  const [perPageData] = useState(10);

  useEffect(() => {
    document.title = "Paysah | RedeemHistory";
    const fetchData = async () => {
      let viewRedeemHistory = [];
      let buyerId = await JSON.parse(localStorage.getItem("user_details"));
      let data = {
        buyer_id: buyerId?.user_id,
        page_limit: perPageData,
        page: page,
      };
      try {
        let result = await ViewRedeemHistoryStockRoom(data);
        if (result?.success) {
          if (result?.data) {
            setCartItems(result?.data?.items);
            setApiResponse(result?.data);
            setCode(result?.data?.code);
            setPrevPage(result?.data?.previousPageUrl);
            setNextPage(result?.data?.nextPageUrl);
            localStorage.setItem("code", result?.data?.code);
            localStorage.setItem(
              "sellerQr_image_name",
              result?.data?.image_name
            );
            const viewRedeemKeys = Object.keys(result?.data[0]?.redeem_history);
            viewRedeemKeys.map((key) => {
              const viewsellerRedeemData = result?.data[0]?.redeem_history[key];
              viewRedeemHistory.push(viewsellerRedeemData);
            });
            setDetailRedeemedOrderItems(viewRedeemHistory);
          }
        } else {
          console.log("error there!");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [page]);

  const handleInvoice = (id) => {
    window.open(
      "https://atlasbackend.24livehost.com/uploads/invoice_pdfs/" + `${id}.pdf`,
      "_blank"
    );
  };

  // const handlePopup = (imgName) => {
  //   Swal.fire({
  //     imageUrl:
  //       "https://atlasbackend.24livehost.com/uploads/buyer-qr-codes/" +
  //       `${imgName}`,
  //     imageWidth: 700,
  //     imageHeight: 400,
  //     imageAlt: "Custom image",
  //     confirmButtonText: "Cancel",
  //   });
  // };

  const handleClose = () => {
    setShow(false);
  };

  const handleOkayBtn = () => {
    window.location.reload();
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <>
          <LoggedInHeader>
            <div className="body-container d-flex">
              <SideNavigation />
              <div className="right-panel">
                <h2>
                  <strong className="pro-subheading">Redeem History</strong>
                </h2>
                {detailRedeemedOrderItems?.length > 0 ? (
                  <>
                    <div className="redeem-history">
                      {detailRedeemedOrderItems.length > 0
                        ? detailRedeemedOrderItems.map((redeemItems) => {
                            if (
                              redeemItems &&
                              Object.keys(redeemItems).length > 0
                            ) {
                              return Object.entries(redeemItems).map(
                                ([key, item]) => {
                                  return (
                                    <>
                                      <div className="tableStyle">
                                        <div className="row align-items-center mb-3">
                                          <div className="col-6 ">
                                            <h4>
                                              <strong>
                                                {
                                                  item?.bunch_data[0]
                                                    ?.seller_name
                                                }{" "}
                                              </strong>{" "}
                                            </h4>
                                          </div>
                                          <div className="col-6 text-end">
                                            {item?.qrdata
                                              ?.is_invoice_generated == 2 ? (
                                              <button
                                                onClick={() =>
                                                  handleInvoice(
                                                    item?.bunch_data[0]
                                                      ?.bunch_id
                                                  )
                                                }
                                                className="btn btn-default btn-dark w-20 m-auto px-3 my-2"
                                                disabled={
                                                  item?.qrdata
                                                    ?.is_invoice_generated == 1
                                                    ? true
                                                    : false
                                                }
                                              >
                                                Invoice
                                              </button>
                                            ) : null}
                                          </div>
                                          <div className="page-title">
                                            <h2>
                                              Order Id:{" "}
                                              <span>
                                                #
                                                {
                                                  item?.bunch_data[0]
                                                    ?.order_id_generated
                                                }
                                              </span>
                                            </h2>
                                          </div>
                                          <span>
                                            <strong>Redeem Date :</strong>{" "}
                                            {moment(
                                              item?.qrdata.redeem_date
                                            ).format("MMMM DD-YYYY hh:mm A")}
                                          </span>
                                          {/* {item?.bunch_data?.map((items) => {
                                            return (
                                              <span>
                                                <strong>Subtotal :</strong> £
                                                {items.billing_amount}
                                              </span>
                                            );
                                          })} */}

                                          {item?.qrdata.pick_up_later == 2 ? (
                                            <>
                                              <strong>Pick up later</strong>
                                              <span>
                                                <strong>Date : </strong>{" "}
                                                {moment(
                                                  item?.qrdata
                                                    .pick_up_later_date_time
                                                ).format("MMMM DD-YYYY ")}
                                              </span>
                                              <br />
                                              <span>
                                                <strong>Time : </strong>{" "}
                                                {
                                                  item?.qrdata
                                                    .pick_up_later_time_range
                                                }
                                              </span>
                                            </>
                                          ) : null}
                                        </div>
                                        <div className="row">
                                          <div>
                                            <div className="card">
                                              <div className="stock-table">
                                                <div className="table-responsive">
                                                  <table className="table">
                                                    <tbody>
                                                      <tr>
                                                        <th>Name</th>
                                                        {/* <th>Date</th> */}
                                                        <th>Size</th>
                                                        {/* {item?.qrdata
                                                              .isBunchPartiallyRedeemed ==
                                                              2 ||
                                                            item?.qrdata
                                                              .isBunchPartiallyRedeemed ==
                                                              3 ? (
                                                              <th>
                                                                Redeem By Seller
                                                                Date
                                                              </th>
                                                            ) : null} */}

                                                        {/* {item?.qrdata
                                                              .isBunchPartiallyRedeemed ==
                                                              2 ||
                                                            item?.qrdata
                                                              .isBunchPartiallyRedeemed ==
                                                              3 ? (
                                                              <th>Redeemed</th>
                                                            ) : (
                                                              <th>Redeem To Be</th>
                                                            )} */}
                                                        <th>Redeemed</th>
                                                        <th>Sub-total</th>
                                                      </tr>
                                                      {item?.bunch_data?.map(
                                                        (items) => {
                                                          return (
                                                            <tr>
                                                              <td>
                                                                {
                                                                  items.item_name
                                                                }
                                                              </td>
                                                              {/* <td>
                                                                {moment(
                                                                  items.order_date
                                                                ).format(
                                                                  "MMMM DD-YYYY hh:mm A"
                                                                )}
                                                              </td> */}
                                                              <td>
                                                                {items.weight &&
                                                                items?.packing_unit ? (
                                                                  <>
                                                                    {" "}
                                                                    {
                                                                      items.weight
                                                                    }
                                                                    {" " +
                                                                      items.packing_unit}
                                                                  </>
                                                                ) : items.weight ? (
                                                                  <>
                                                                    {" "}
                                                                    {
                                                                      items.weight
                                                                    }
                                                                  </>
                                                                ) : items.packing_unit ? (
                                                                  <>
                                                                    {" "}
                                                                    {" " +
                                                                      items.packing_unit}
                                                                  </>
                                                                ) : (
                                                                  "_"
                                                                )}
                                                              </td>

                                                              {/* {item?.qrdata
                                                                    .isBunchPartiallyRedeemed ==
                                                                    2 ||
                                                                  item?.qrdata
                                                                    .isBunchPartiallyRedeemed ==
                                                                    3 ? (
                                                                    <td>
                                                                      {items?.redeemed_by_seller !=
                                                                      null
                                                                        ? moment(
                                                                            items?.redeemed_by_seller
                                                                          ).format(
                                                                            "MMMM DD-YYYY hh:mm A"
                                                                          )
                                                                        : "_"}
                                                                    </td>
                                                                  ) : null} */}

                                                              <td>
                                                                {
                                                                  items.available_qty
                                                                }
                                                              </td>
                                                              {/* <td>
                                                                    {
                                                                      items.actual_redeemed_qty
                                                                    }
                                                                  </td> */}
                                                              <td>
                                                                £
                                                                {
                                                                  items.billing_amount
                                                                }
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              );
                            } else {
                              return null;
                            }
                          })
                        : null}
                    </div>
                  </>
                ) : (
                  <h3
                    style={{
                      display: "flex",
                      height: "70vh",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No redeem history found
                  </h3>
                )}
                {(nextPage || prevPage) &&
                detailRedeemedOrderItems &&
                detailRedeemedOrderItems?.length > 0 ? (
                  <>
                    {" "}
                    <div className="StylePagination">
                      <button
                        disabled={prevPage == null ? true : false}
                        onClick={() => {
                          setPage(parseInt(prevPage?.split("=")[1]));
                        }}
                        className="previous text-white bg-dark border-dark"
                      >
                        &laquo; Previous
                      </button>
                      <button
                        disabled={nextPage == null ? true : false}
                        onClick={() => {
                          setPage(parseInt(nextPage?.split("=")[1]));
                        }}
                        className="next text-white bg-dark border-dark"
                      >
                        Next &raquo;
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </LoggedInHeader>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>PaySah</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p
                style={{
                  color: "red",
                }}
              >
                {error}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleOkayBtn}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Footer />
        </>
      )}
    </>
  );
}
