import React from "react";
import { Navigate } from "react-router-dom";

export const ProtectedIntro = ({ children }) => {
  const Token = localStorage.getItem("token");
  const User_Role = localStorage.getItem("user_role");

  if (!Token) {
    return children;
  }

  return <Navigate to={User_Role === "Buyer" ? "/buyerhome" : "sellerhome"} />;
};
