import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Import eye and eye-slash icons from react-icons
import { toast } from "react-toastify";

import { GetUserDetails, UserLogin } from "../../api/request";
import Loader from "../../components/Loader";
import { showSuccessMessage, showErrorMessage } from "../../constants/Toast";
// import Header from "../../components/Header";
import { hideLoading } from "../../redux/slices/LoaderSlice";
import { Logo } from "../../constants/StaticFiles";
import Footer from "../../components/Footer";

export default function Login() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { isLoading } = useSelector((state) => state.loader);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    document.title = "Paysah | LogIn";
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
const handleVenderRegistration =()=>{
  navigation("/vendorregistration");
}
  const submitForm = async (data) => {
    toast.dismiss();
    try {
      let result = await UserLogin(data);
      if (result.success) {
        localStorage.setItem("token", result?.data?.access_token);
        GetUserDetails();
        localStorage.setItem("user_role", result?.data?.user_role);
        localStorage.setItem("user_details", JSON.stringify(result?.data));
        localStorage.setItem("setupTime", JSON.stringify(result?.data?.login_time));
        localStorage.setItem("CartSequenceId", result?.data?.cart_sequence_id);
        let user_role = result.data.user_role;
        if (user_role === "Buyer") {
          navigation("/buyerhome", { replace: true });
        } else if (user_role === "Seller") {
          navigation("/sellerhome", { replace: true });
        } else {
          console.log(null);
        }
        dispatch(hideLoading());
        showSuccessMessage(result?.data);
      } else if (result.success == false) {
        dispatch(hideLoading());
        showErrorMessage(result?.data);
      }
    } catch (error) {
      dispatch(hideLoading());
      console.log("ERROR", error);
    }
  };

  return (
    <>
      <div className="wrapper pro-subheading">
        {/* <div className="top-action-bar">
          <div className="container">
            <Header onclickSection={() => navigation("/")} />
          </div>
        </div> */}

        <div className="container">
          <div className="px-4 py-5 my-5 text-center">
          <Link to="/">
            <img className="d-block mx-auto heading-logo style-logo" src={Logo} alt="" />
          </Link>
            {/* <Link to="/">
              
              <img className="d-block mx-auto heading-logo" src={Logo} alt="" />
            </Link> */}
            <div className="register-form">
              <form
                noValidate
                autoComplete="on"
                onSubmit={handleSubmit(submitForm)}
              >
                <br/>
                <br/>
                {/* <div className="section-heading">
                  <h1>Login</h1>
                </div> */}

                <div className="row">
                  <div className="col-sm-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                      name="email"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Email address is required",
                        },
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "This is not valid email format! ",
                        },
                      })}
                    />
                    <div
                      style={{
                        color: "red",
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      {errors.email?.message}
                    </div>
                  </div>
                  <div className="col-sm-12 mb-3">
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        name="pswd"
                        {...register("password", {
                          required: {
                            value: true,
                            message: "Password is required",
                          },
                        })}
                      />
                      <button type="button" onClick={handleTogglePassword}>
                        {showPassword ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </button>
                    </div>
                    <div
                      style={{
                        color: "red",
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      {errors.password?.message}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <button
                      className="btn btn-dark w-100 pro-subheading"
                      type="submit"
                      onSubmit={(e) => e.preventDefault()}
                      disabled={!isValid || isLoading}
                    >
                     {isLoading ? <Loader /> : null}
                      Login
                    </button>
                  </div>
                </div>
                <div className="login-forget">
                <div className="register-link mb-3 forget">
                  <Link to="/forgotpassword" className="text-decoration-underline">Forgot Password</Link>
                </div>
                <div className="register-link">
                  <Link to="/signup" className="text-decoration-underline">
                    Sign up
                  </Link>
                </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-12">
                    {/* {isLoading ? (
                      <Loader />
                    ) : (  */}
                    <button
                      className="btn btn-bordered btn-dark w-100 pro-subheading"
                      type="submit"
                      onClick = {handleVenderRegistration}
                      // onSubmit={(e) => e.preventDefault()}
                      // disabled={!isValid || isLoading}
                    >
                      Vendor Registration
                      
                    </button>
                  </div>
                </div>


              
                
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
