import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SideNavigation from "../../components/SideNavigation";
import { GetUserDetails, UpdateBuyerProfile } from "../../api/request";
import Loader from "../../components/Loader";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../constants/Toast";
import Footer from "../../components/Footer";
import LoggedInHeader from "../../components/LoggedInHeader";
import { toast } from "react-toastify";

export default function BuyerProfile() {
  const { userProfile } = useSelector((state) => state);
  const { isLoading } = useSelector((state) => state.loader);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ref,setRef]=useState("");
  const [userDetail, setUserDetail] = useState({
    user_id: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    address: "",
    email: "",
    errorFirstName: "",
    errorLastName: "",
    errorPhoneNumber: "",
  });

  let profileTitle;
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    profileTitle =
      localStorage.getItem("user_role") === "Buyer"
        ? "BuyerProfile"
        : "SellerProfile";
    setUserRole(profileTitle);

    document.title = `Paysah | ${profileTitle}`;
    const getUserData = async () => {
      try {
        let data = await GetUserDetails();
       setRef(data?.data?.reference_number)
        if (data.success) {
          const {
            first_name,
            last_name,
            email,
            mobile_number,
            address,
          } = data?.data;
            setUserDetail({
              ...userDetail,
              first_name: first_name,
              last_name,
              email,
              mobile_number,
              address,
            });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, []);

  const handleFieldValidations = (value, type) => {
    let nameValidator = /[A-Za-z]/;
    let phoneValidator =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

    if (type === "first_name") {
      setUserDetail({
        ...userDetail,
        first_name: value,
        errorFirstName:
          type === "first_name" ? nameValidator.test(value) : false,
      });
    } else if (type === "last_name") {
      setUserDetail({
        ...userDetail,
        last_name: value,
        errorLastName: type === "last_name" ? nameValidator.test(value) : false,
      });
    } else if (type === "mobile_number") {
      setUserDetail({
        ...userDetail,
        mobile_number: value,
        errorPhoneNumber:
          type === "mobile_number" ? phoneValidator.test(value) : false,
      });
    }
  };
  const updateBuyerProfile = async () => {
    setIsSubmitting(true);
    toast.dismiss();
    let getUser_id = await JSON.parse(localStorage.getItem("user_details"));
    let user_id = await getUser_id?.user_id;
    const { first_name, last_name, mobile_number, address, postcodes } =
      userDetail;
      let data = {
        user_id: user_id,
        first_name,
        last_name,
        mobile_number,
        address,
      };
      try {
      let result = await UpdateBuyerProfile(data);
      if (result?.success) {
        showSuccessMessage(result?.message);
          setIsSubmitting(false);
      } 

      else {
        if (result?.errors?.postcodes?.[0]) {
          showErrorMessage(result?.errors?.postcodes?.[0]);
        }
        if (result?.success == false) {
          showErrorMessage(result?.data);
        }
        // if (result?.data) {
        //   showErrorMessage(result?.data);
        // }
      }
    
    } catch (error) {
      console.log(error);
    }
  };
  return (  
    <>
      {isLoading ? (
        <div className="loader-BG">
          <Loader />
        </div>
      ) : (
        <LoggedInHeader>
          <div className="body-container d-flex">
            <SideNavigation />
            <div className="right-panel">
            <h2><strong>Account</strong></h2>
              <div className="card shadow-sm mb-4 py-4">
                <div className="card-body text-center">
                  <div className="profile-container">
                    <h5>
                      {" "}
                      <strong>
                        {userProfile &&
                          "Welcome " + userProfile?.profileData?.name}
                      </strong>{" "}
                    </h5>
                    <hr />
                    <div className="text-start pt-4">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">First Name</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="text"
                              value={userDetail && userDetail?.first_name}
                              onChange={(e) =>
                                handleFieldValidations(
                                  e.target.value,
                                  "first_name"
                                )
                              }
                            />
                            <span style={{ color: "red", fontSize: 15 }}>
                              {userDetail?.errorFirstName === false
                                ? "Please enter valid first name (First name cannot be numbers only!)"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Last Name</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="text"
                              value={userDetail && userDetail?.last_name}
                              onChange={(e) =>
                                handleFieldValidations(
                                  e.target.value,
                                  "last_name"
                                )
                              }
                            />
                            <span style={{ color: "red", fontSize: 15 }}>
                              {userDetail?.errorLastName === false
                                ? "Please enter valid last name (Last name cannot be numbers only!)"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Phone Number</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="number"
                              value={userDetail && userDetail?.mobile_number}
                              onChange={(e) =>
                                handleFieldValidations(
                                  e.target.value,
                                  "mobile_number"
                                )
                              }
                            />
                            <span style={{ color: "red", fontSize: 15 }}>
                              {userDetail?.errorPhoneNumber === false
                                ? "Please enter valid phone number"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Email Address</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="email"
                              value={userDetail && userDetail?.email}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      {ref != null ?  <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Reference Number</div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="email"
                              value={ref}
                              readOnly
                            />
                          </div>
                        </div>
                      </div> : null}
                     
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">Address</div>
                          <div className="col-md-6">
                            <textarea
                              className="form-control"
                              value={userDetail && userDetail?.address}
                              onChange={(e) => {
                                setUserDetail({
                                  ...userDetail,
                                  address: e.target.value,
                                });
                              }}
                            />
                            <div className="w-100 profile-update-btn">
                              <button
                                className="w-25 btn-dark float-right profile-btn border-round"
                                type="submit"
                                onClick={updateBuyerProfile}
                                // disabled={isSubmitting ? true : false} // Disable the button when submitting
                              >
                                {isLoading ? <Loader /> : null}
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </LoggedInHeader>
      )}
    </>
  );
}
