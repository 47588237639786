import React, {
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { ReactComponent as DeleteIcon } from "../assets/images/icon-delete.svg";
import SideNavigation from "../components/SideNavigation";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  addSellerProduct,
  viewSellerProducts,
  updateProductViewList,
  DeleteSellerProductsItems,
} from "../api/request";
import Loader from "../components/Loader";
import {
  showSuccessMessage,
  showErrorMessage,
  showInformation,
} from "../constants/Toast";
import Footer from "../components/Footer";
import LoggedInHeader from "../components/LoggedInHeader";
import {
  addItems,
  addQuantity,
  removeQuantity,
  handleWeightUnit,
  handleWeight,
  handlePrice,
  removeItem,
  addWantList,
  handleQuantity,
  handleUnit,
  handleExpire,
  handleBuffer,
  clearWantList,
} from "../redux/slices/QuantitySlice";
import Modal from "react-bootstrap/Modal";
import ModalBox from "../components/ModalBox";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from "uuid";
function AddProducts() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { wantList } = useSelector((state) => state.wantList);
  const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [ProductName, setProductName] = useState("");
  const [selectOption, setSelectOPtion] = useState([]);
  const [textArea, setTextArea] = useState("");
  const [show, setShow] = useState(false);
  const [itemID, setitemID] = useState("");
  const [itemUniqId, setItemUniqId] = useState("");
  const [sellerProductSizeOptionId, setSellerProductSizeOptionId] = useState();
  const [buyerWantID, setBuyerWantId] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectType, setSelectType] = useState("");
  const [selectCount, setSelectCount] = useState("");
  const [offerValue, setOfferValue] = useState("");
  const [discountRuleId, setDiscountRuleId] = useState("");
  const [error, setError] = useState("");
  const [offererror, setOfferError] = useState("");
  const [Category, setCategory] = useState("");
  const [proId, setProId] = useState();
  const [productImage, setProductImage] = useState("");
  const [isValid, setIsValid] = useState(false);

  let itemPriceValidation = [];
  let itemPriceLengthValidation = [];
  let itemWeightValidation = [];
  let itemWeightSizeLengthValidation = [];
  let itemExpireValidation = [];
  let itemBufferValidation = [];
  let itemSizeValidation = [];
  let itemUnitValidation = [];
  let itemQuantityLengthValidation = [];
  let itemQuantityValidation = [];

  useLayoutEffect(() => {
    document.title = "Paysah | EditSellerProduct";
    addSellerProducts();
    SellerProductList();
    // setSubmit(false);
  }, []);

  const SellerProductList = useCallback(async () => {
    setIsLoading(true);
    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    let id = await JSON.parse(localStorage.getItem("view_products_id"));

    try {
      let data = {
        id: id,
        user_id: user_details?.user_id,
      };
      let result = await viewSellerProducts(data);

      if (result?.success) {
        setProductName(result?.data?.item_name);
        setTextArea(result?.data?.description);
        setProId(result?.data?.main_product_id);
        setSellerProductSizeOptionId(result?.data?.id);
        setProductImage(result?.data?.item_image);
        setCategory(result?.data?.category_name);
        localStorage.setItem("offerType", result?.data?.offer_type);
        setSelectType(result?.data?.offer_type);
        setSelectCount({
          ...selectCount,
          [result?.data?.offer_type]: result?.data?.bought_count,
        });
        setOfferValue({
          ...offerValue,
          [result?.data?.offer_type]: result?.data?.offer_value,
        });

        setDiscountRuleId(result?.data?.discount_rule_id);
        const formattedDate = moment
          .utc(result?.data?.discount_valid_till_date)
          .toDate();

        setSelectedDate({
          ...selectedDate,
          [result?.data?.offer_type]: formattedDate,
        });
        // if (result?.data?.size_option?.length > 0) {
        let datas = result?.data?.size_option?.map((obj) => {
          return {
            id: uuidv4(),
            weight: obj.weight,
            seller_product_size_option_id: obj.seller_product_size_option_id,
            available_quantity: obj.available_quantity,
            size_id: obj.size_id,
            price: obj.price,
            order_expiration_days: obj.order_expiration_days,
            no_of_buffer_days_before_item_could_be_reactivated:
              obj.no_of_buffer_days_before_item_could_be_reactivated,
            total_quantity: obj.total_quantity,
            seller_id: obj.seller_id,
            seller_product_id: obj.seller_product_id,
            seller_product_size_options_id: obj.seller_product_size_option_id,
            packing_unit: obj.packing_unit,
          };
        });
        // let selectedUnit = selectOption.find((opt) => opt.id === +selectedValue)?.unit;
        console.log("defaultList", { datas });
        dispatch(addWantList(datas));
        // }
        // else {
        //   console.log("defaultList else", { datas: {} });

        //   dispatch(
        //     addWantList({
        //       id: uuidv4(),
        //       weight: "",
        //       seller_product_size_option_id: "",
        //       available_quantity: "",
        //       size_id: "",
        //       price: "",
        //       order_expiration_days: "",
        //       no_of_buffer_days_before_item_could_be_reactivated: "",
        //       total_quantity: "",
        //       seller_id: "",
        //       seller_product_id: "",
        //       seller_product_size_options_id: "",
        //       packing_unit: "",
        //     })
        //   );
        // }

        setIsLoading(false);
      } else {
        showErrorMessage("Something Went Wrong");
      }
    } catch (error) {
      setIsLoading(false);
      setIsValid(false);
      console.log(error);
    }
  }, [dispatch, offerValue, selectCount, selectedDate]);

  const handleShow = (item) => {
    setShow(true);
    if (item?.seller_id) {
      setitemID(item.seller_id);
      setBuyerWantId(item.seller_product_id);
      setSellerProductSizeOptionId(item.seller_product_size_option_id);
    } else {
      setItemUniqId(item?.id);
    }
  };

  const DeleteWantItem = async () => {
    try {
      if (typeof itemID === "number" && wantList.length > 0) {
        setShow(false);
        let data = {
          seller_id: itemID,
          seller_product_id: buyerWantID,
          seller_product_size_options_id: sellerProductSizeOptionId,
        };
        let result = await DeleteSellerProductsItems(data);

        if (result.success === true) {
          showSuccessMessage("Item deleted sucessfully");
          setitemID("");
          setBuyerWantId("");
          dispatch(removeItem(itemID));
          window.location.reload();
        } else if (result.success === false) {
          showErrorMessage(result?.data);
        }
      } else if (
        itemID &&
        typeof itemID === "string" &&
        wantList &&
        wantList.length > 0
      ) {
        setShow(false);
        dispatch(removeItem(itemID));
        showSuccessMessage("Item deleted sucessfully");
      } else if (itemUniqId) {
        setShow(false);
        dispatch(removeItem(itemUniqId));
        setItemUniqId("");
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const addRow = () => {
    setSubmit(false);
    dispatch(
      addItems({
        id: uuidv4(),
        weight: "",
        size_id: "",
        price: "",
        total_quantity: "",
        order_expiration_days: null,
        unit: "",
        no_of_buffer_days_before_item_could_be_reactivated: null,
        // total_quantity: null,
      })
    );
  };

  const defaultList = () => {
    try {
      if (wantList.length <= 0) {
        addRow();
      } else {
        return;
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleBack = () => {
    navigation("/selectedProductList");
  };

  const addSellerProducts = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        let response = await addSellerProduct(token);
        if (response?.success) {
          if (response.data.length > 0) {
            let opts = [];
            response.data.forEach((opt, i) => {
              let dt = { id: opt.id, unit: opt.packing_unit };
              opts.push(dt);
            });
            setSelectOPtion(opts);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleTextArea = (event) => {
    setTextArea(event.target.value);
  };

  function handleChangeProductName(e) {
    e.preventDefault();
    setProductName(e.target.value);
  }

  function handleChangeCategory(e) {
    e.preventDefault();
    setCategory(e.target.value);
  }

  const handleChangeSelectType = (event, type) => {
    setSelectType(parseInt(event.target.value));
  };

  function handleChangeSelectCount(event, type) {
    const value = Number(event.target.value);
    // setSelectCount({ ...selectCount, [type]: value });
    if (!isNaN(value) && value >= 1) {
      setSelectCount({ ...selectCount, [type]: value });
    } else {
      setSelectCount("");
      // setError('Bought count should be greater than 1');
    }
  }

  function handleChangeOfferValue(event, type) {
    const value = Number(event.target.value);
    // setOfferValue({ ...offerValue, [type]: value });
    if (!isNaN(value) && value >= 0) {
      setOfferValue({ ...offerValue, [type]: value });
    } else {
      setOfferValue("");
      // setOfferError('Offer value should be greater than 0 ');
    }
  }

  function handleKeypress(e, type) {
    if (type == 2) {
      const characterCode = e.key;
      if (characterCode === "Backspace") return;
      const characterNumber = Number(characterCode);
      if (characterNumber >= 0 && characterNumber <= 9) {
        if (e.currentTarget.value && e.currentTarget.value.length) {
          return;
        } else if (characterNumber === 0) {
          e.preventDefault();
        }
      } else {
        e.preventDefault();
      }
    }
  }

  const handleChangedateValue = (date, type) => {
    setSelectedDate({ ...selectedDate, [type]: date });
  };
  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      className="input-text-box"
      value={value}
      onClick={onClick}
      readOnly // Make the input read-only
    />
  );

  const itemPriceLimitValidation = wantList
    .filter((item) => {
      return !item.price.length;
    })
    .map((item) => {
      return item.price;
    });

  itemPriceValidation = wantList
    .filter((item) => {
      return !item.price;
    })
    .map((item) => item.id);

  itemPriceLengthValidation = wantList
    .filter((item) => {
      return item?.price?.toString().length >= 7;
    })
    .map((item) => {
      return item.id;
    });
  itemQuantityValidation = wantList
    .filter((item) => {
      return !item.total_quantity;
    })
    .map((item) => item.id);

  itemQuantityLengthValidation = wantList
    .filter((item) => {
      return item?.total_quantity?.toString().length >= 7;
    })
    .map((item) => {
      return item.id;
    });

  itemWeightSizeLengthValidation = wantList
    .filter((item) => {
      return item?.size_id?.toString().length >= 7;
    })
    .map((item) => {
      return item.id;
    });

  itemWeightValidation = wantList
    .filter((item) => {
      return item?.weight?.toString().length >= 7;
    })
    .map((item) => {
      return item.id;
    });

  itemSizeValidation = wantList
    .filter((item) => {
      return !item.weight;
    })
    .map((item) => item.id);
  itemUnitValidation = wantList
    .filter((item) => {
      return !item.size_id;
    })
    .map((item) => item.id);

  itemExpireValidation = wantList
    .filter((item) => {
      return !item.order_expiration_days;
    })
    .map((item) => item.id);
  itemBufferValidation = wantList
    .filter((item) => {
      return !item.no_of_buffer_days_before_item_could_be_reactivated;
    })
    .map((item) => item.id);

  const WeightLimits = itemWeightValidation.map(
    (opt) => opt?.toString().length
  );
  const WeightSizeLimits = itemWeightSizeLengthValidation.map(
    (opt) => opt?.toString().length
  );
  const priceLimits = itemPriceLimitValidation.map((opt) => {
    let number = opt?.toString();
    if (number.includes(".")) {
      return opt?.toString().length - 2;
    } else {
      return opt?.toString().length;
    }
  });
  const hasWeightNumberGreaterSeven = WeightLimits.some((number) => {
    return number > 7;
  });
  const hasWeightSizeGreaterSeven = WeightSizeLimits.some((number) => {
    return number > 7;
  });

  const hasPriceNumberGreaterSeven = priceLimits.some((number) => {
    return number > 7;
  });
  const hasQuantityZero = wantList.some((qty) => {
    return parseInt(qty.total_quantity) === 0;
  });
  const orderPlace = async () => {
    setIsLoading(true);

    let user_details = await JSON.parse(localStorage.getItem("user_details"));
    let id = await JSON.parse(localStorage.getItem("view_products_id"));
    setSubmit(true);
    toast.dismiss();
    if (
      hasPriceNumberGreaterSeven ||
      hasWeightNumberGreaterSeven ||
      hasWeightSizeGreaterSeven
    ) {
      showInformation(
        "Limit Exceed! You can not set Sizing and price length more than 7"
      );
      return;
    }
    if (hasQuantityZero) {
      showErrorMessage("Please valid quantity");
      return;
    }
    try {
      let formData = [];
      wantList.map((data, index) => {
        formData.push({
          id: uuidv4(),
          seller_product_size_option_id: parseInt(
            data.seller_product_size_option_id
          ),
          available_quantity: parseInt(data.available_quantity),
          weight: data.weight,
          size_id: data.size_id,
          price: parseFloat(data.price),
          order_expiration_days: parseInt(data.order_expiration_days),
          no_of_buffer_days_before_item_could_be_reactivated: parseInt(
            data.no_of_buffer_days_before_item_could_be_reactivated
          ),
          total_quantity: parseInt(data.total_quantity),
        });
      });
      let data = {
        user_id: user_details?.user_id,
        seller_product_id: sellerProductSizeOptionId,
        product_id: proId,
        item_name: ProductName,
        size_option: formData,
        description: textArea,
        offer_type: selectType,
        bought_count: parseInt(selectCount[selectType] || ""),
        offer_value: offerValue[selectType] || "",
        discount_valid_till_date: selectedDate[selectType] || "",
        discount_rule_id: discountRuleId,
      };
      if (
        Category !== null &&
        ProductName !== null &&
        ProductName !== "" &&
        ProductName?.length <= 255 &&
        itemPriceLengthValidation.length == 0 &&
        hasWeightNumberGreaterSeven == false &&
        hasPriceNumberGreaterSeven == false &&
        hasWeightSizeGreaterSeven == false &&
        hasQuantityZero == false
        // itemUnitValidation.length < 1
      ) {
        setIsValid(true);
        let result = await updateProductViewList(data);
        if (result?.success == true) {
          showSuccessMessage(result?.message);
          setTimeout(() => {
            navigation("/selectedProductList");
          }, 1000);
        } else {
          setIsLoading(false);
          setIsValid(false);
          showErrorMessage(result?.data);
          if (result?.data?.name?.[0]) {
            showErrorMessage(result?.data?.name[0]);
          }
          if (result?.data?.bought_count?.[0]) {
            showErrorMessage(result?.data?.bought_count[0]);
          }
          if (result?.data?.offer_value?.[0]) {
            showErrorMessage(result?.data?.offer_value[0]);
          }
          if (itemPriceValidation.length > 0) {
            showErrorMessage("Price is required for all items");
          } else if (wantList.some((item) => item.price < 1)) {
            showErrorMessage(result?.data);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsValid(false);
      console.log(error);
    }
  };
  const handlePreview = (data) => {
    // Generate table rows based on the provided data
    const tableRows = data
      .map(
        (item) => `
      <tr>
      <td>${item.weight ? item.weight : "-"}</td>
      <td>${
        item.unit ? item.unit : item.packing_unit ? item.packing_unit : "-"
      }</td>

     
      <td>${item.price ? `£${item.price}` : "-"}</td>
      <td>${item.total_quantity ? item.total_quantity : "-"}</td>
       </tr>
    `
      )
      .join("");

    // Your complete table HTML content
    const tableContent = `  
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Size</th>
            <th>Unit</th>
            <th>Price</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;

    let title = `<h5><strong>${ProductName}</strong></h5>`;

    if (textArea !== null) {
      title += `<p>${textArea}</p>`;
    }

    Swal.fire({
      title: title,
      imageUrl: `${productImage}`,
      showCloseButton: true,
      confirmButtonText: "Close Preview",
      html: tableContent,
      customClass: {
        container: "my-custom-modal-container",
      },
      imageWidth: 200, // Set the desired width
      // Set the desired height
    });
  };
  return (
    <>
      <>
        <LoggedInHeader>
          <div className="body-container d-flex">
            <SideNavigation />
            <div className="right-panel">
              <div className="styleBtn">
                <button
                  className="btn btn-dark w-40 bg-dark"
                  onClick={handleBack}
                >
                  <i class="fa fa-arrow-circle-left" aria-hidden="true">
                    {" "}
                  </i>
                </button>
              </div>
              <div className="register-form full-width">
                <div className="text-center mb-5">
                  <strong>View/Edit Products</strong>
                </div>

                <div>
                  <label>
                    <strong>Product Name</strong>
                  </label>
                  <input
                    type="text"
                    name="item_name"
                    className="form-control"
                    value={ProductName}
                    readOnly
                  />

                  <div>
                    <br />
                    <strong>
                      <label>Product Description</label>
                    </strong>

                    <textarea
                      className="form-control"
                      type="textarea"
                      name="textValue"
                      value={textArea}
                      readOnly
                    />
                  </div>

                  <div>
                    <br />
                    <strong>
                      {" "}
                      <label>Category</label>
                    </strong>
                    <input
                      className="form-control"
                      type="textarea"
                      name="textValue"
                      value={Category}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="register-form full-width">
                <>
                  <div>
                    <br />
                    <div>
                      <strong>
                        {" "}
                        <label>Discount Rule</label>
                      </strong>

                      <div style={{ marginTop: "5px" }}>
                        <div
                          className="radio-type input-field"
                          onClick={() => {
                            setSelectType(1);
                          }}
                        >
                          <input
                            className="radio-type "
                            type="radio"
                            value="1"
                            name="selectType"
                            onChange={handleChangeSelectType}
                            checked={selectType == 1}
                          />
                          Percentage
                        </div>
                        <div
                          className="radio-type input-field"
                          onClick={() => {
                            setSelectType(2);
                          }}
                        >
                          <input
                            className="radio-type"
                            type="radio"
                            value="2"
                            name="selectType"
                            onChange={handleChangeSelectType}
                            checked={selectType == 2}
                          />
                          Buy and get free
                        </div>
                        <div
                          className="radio-type input-field"
                          onClick={() => {
                            setSelectType(3);
                          }}
                        >
                          <input
                            className="radio-type"
                            type="radio"
                            value="3"
                            name="selectType"
                            onChange={handleChangeSelectType}
                            checked={selectType == 3}
                          />
                          None
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectType == 1 ? (
                    <div
                      class="note alert alert-primary d-flex align-items-center my-2"
                      role="alert"
                    >
                      <div>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.41489 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM8.6875 4.625C8.87292 4.625 9.05418 4.67998 9.20835 4.783C9.36252 4.88601 9.48268 5.03243 9.55364 5.20373C9.6246 5.37504 9.64316 5.56354 9.60699 5.7454C9.57082 5.92725 9.48153 6.0943 9.35042 6.22541C9.2193 6.35652 9.05226 6.44581 8.8704 6.48199C8.68854 6.51816 8.50004 6.49959 8.32874 6.42864C8.15743 6.35768 8.01101 6.23752 7.908 6.08335C7.80499 5.92918 7.75 5.74792 7.75 5.5625C7.75 5.31386 7.84878 5.0754 8.02459 4.89959C8.20041 4.72377 8.43886 4.625 8.6875 4.625ZM9.625 13.375C9.29348 13.375 8.97554 13.2433 8.74112 13.0089C8.5067 12.7745 8.375 12.4565 8.375 12.125V9C8.20924 9 8.05027 8.93415 7.93306 8.81694C7.81585 8.69973 7.75 8.54076 7.75 8.375C7.75 8.20924 7.81585 8.05027 7.93306 7.93306C8.05027 7.81585 8.20924 7.75 8.375 7.75C8.70652 7.75 9.02447 7.8817 9.25889 8.11612C9.49331 8.35054 9.625 8.66848 9.625 9V12.125C9.79076 12.125 9.94974 12.1908 10.0669 12.3081C10.1842 12.4253 10.25 12.5842 10.25 12.75C10.25 12.9158 10.1842 13.0747 10.0669 13.1919C9.94974 13.3092 9.79076 13.375 9.625 13.375Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div>
                        <p>
                          Discount in percentage that offers price reduction as
                          per given percentage.
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {selectType == 2 ? (
                    <div
                      class=" note alert alert-primary d-flex align-items-center my-2"
                      role="alert"
                    >
                      <div>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.41489 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM8.6875 4.625C8.87292 4.625 9.05418 4.67998 9.20835 4.783C9.36252 4.88601 9.48268 5.03243 9.55364 5.20373C9.6246 5.37504 9.64316 5.56354 9.60699 5.7454C9.57082 5.92725 9.48153 6.0943 9.35042 6.22541C9.2193 6.35652 9.05226 6.44581 8.8704 6.48199C8.68854 6.51816 8.50004 6.49959 8.32874 6.42864C8.15743 6.35768 8.01101 6.23752 7.908 6.08335C7.80499 5.92918 7.75 5.74792 7.75 5.5625C7.75 5.31386 7.84878 5.0754 8.02459 4.89959C8.20041 4.72377 8.43886 4.625 8.6875 4.625ZM9.625 13.375C9.29348 13.375 8.97554 13.2433 8.74112 13.0089C8.5067 12.7745 8.375 12.4565 8.375 12.125V9C8.20924 9 8.05027 8.93415 7.93306 8.81694C7.81585 8.69973 7.75 8.54076 7.75 8.375C7.75 8.20924 7.81585 8.05027 7.93306 7.93306C8.05027 7.81585 8.20924 7.75 8.375 7.75C8.70652 7.75 9.02447 7.8817 9.25889 8.11612C9.49331 8.35054 9.625 8.66848 9.625 9V12.125C9.79076 12.125 9.94974 12.1908 10.0669 12.3081C10.1842 12.4253 10.25 12.5842 10.25 12.75C10.25 12.9158 10.1842 13.0747 10.0669 13.1919C9.94974 13.3092 9.79076 13.375 9.625 13.375Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div>
                        <p>
                          Discounts in quantity that offers price reduction by
                          not counting the Qty specified. For example - if user
                          will buy X Qty, system will charge for Y Qty. It will
                          apply for every X Qty. (Say X = 5, Y = 4)
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {selectType == 1 || selectType == 2 ? (
                    <>
                      <div className="product-rules">
                        <div>
                          <br />
                          <label for="txtbox">Enter Number Of Quantity :</label>
                          <input
                            type="number"
                            className="input-text-box"
                            placeholder="Bought Count"
                            id="txtbox"
                            min={1}
                            value={selectCount[selectType] || ""}
                            onChange={(e) =>
                              handleChangeSelectCount(e, selectType)
                            }
                          />
                          {error && (
                            <p
                              style={{
                                color: "red",
                                textAlign: "left",
                                fontSize: "14px",
                                marginTop: "1px",
                              }}
                            >
                              {error}
                            </p>
                          )}
                        </div>
                        <div>
                          <br />
                          {selectType == 1 ? (
                            <label for="txtbox">Offer value (in %) :</label>
                          ) : (
                            <label for="txtbox">
                              Offer value (deduct no. of quantity) :
                            </label>
                          )}
                          <input
                            type="number"
                            className="input-text-box"
                            placeholder="Offer Value"
                            id="txtbox"
                            value={offerValue[selectType] || ""}
                            onKeyDown={(e) => handleKeypress(e, selectType)}
                            onChange={(e) =>
                              handleChangeOfferValue(e, selectType)
                            }
                          />
                          {offererror && (
                            <p
                              style={{
                                color: "red",
                                textAlign: "left",
                                fontSize: "14px",
                                marginTop: "1px",
                              }}
                            >
                              {offererror}
                            </p>
                          )}
                        </div>
                        <div>
                          <br />
                          <label for="txtbox">Discount valid till date :</label>
                          <DatePicker
                            className="input-text-box"
                            selected={selectedDate[selectType] || ""}
                            onChange={(e) =>
                              handleChangedateValue(e, selectType)
                            }
                            minDate={new Date()} // Disable previous dates starting from today
                            customInput={<CustomInput />}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
                {wantList && wantList.length > 0 && (
                  <div className="item-list">
                    <div className="item item-table">
                      <table width="93%" cellPadding="8">
                        <tr>
                          <th width="15%">Size</th>
                          <th width="10%">Unit</th>
                          <th width="15%">Price</th>
                          <th width="20%">Expire</th>
                          <th width="20%">Buffer</th>
                          <th width="18%">Quantity</th>
                        </tr>
                        {wantList &&
                          wantList.map((listItem, index) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <input
                                      width="100"
                                      type="number"
                                      value={listItem.weight}
                                      onChange={(e) => {
                                        let inputValue = e.target.value
                                          .toString()
                                          .slice(0, 6); // Limit to 7 characters
                                        let payload = {
                                          item: listItem,
                                          value: inputValue,
                                        };
                                        dispatch(handleWeight(payload));
                                      }}
                                    />{" "}
                                    {/* {submit &&
                                    itemSizeValidation.includes(listItem.id) ? (
                                      <div
                                        style={{
                                          color: "red",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          marginTop: "0px",
                                          position: "absolute",
                                          bottom: "-5px",
                                        }}
                                      >
                                        Size is required
                                      </div>
                                    ) : null} */}
                                    {itemWeightValidation.includes(
                                      listItem.id
                                    ) ? (
                                      <div
                                        className="err-table-msg"
                                        style={{
                                          color: "red",
                                          textAlign: "left",
                                          fontSize: "10px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        Sizing limit exceed
                                      </div>
                                    ) : null}
                                  </td>

                                  <td>
                                    <select
                                      name="size_id"
                                      className="form-controls" // Corrected class to className
                                      value={listItem.size_id}
                                      onChange={(e) => {
                                        let payload = {
                                          item: listItem,
                                          value: e.target.value,
                                        };
                                        let selectedValue = e.target.value;

                                        let selectedUnit = selectOption.find(
                                          (opt) => opt.id === +selectedValue
                                        )?.unit;

                                        let payloadForUnit = {
                                          item: listItem,
                                          value: selectedUnit,
                                        };

                                        dispatch(handleWeightUnit(payload));
                                        dispatch(handleUnit(payloadForUnit));
                                      }}
                                    >
                                      <option>Select weight Unit</option>
                                      {selectOption.map((opt) => (
                                        <option
                                          key={opt?.id}
                                          value={`${opt?.id}`}
                                        >
                                          {opt?.unit}
                                        </option>
                                      ))}
                                    </select>
                                    {/* {submit &&
                                    itemUnitValidation.includes(listItem.id) ? (
                                      <div
                                        style={{
                                          color: "red",
                                          textAlign: "left",
                                          fontSize: "12px",
                                          marginTop: "0px",
                                          position: "absolute",
                                          bottom: "-5px",
                                        }}
                                      >
                                        Unit is required
                                      </div>
                                    ) : null} */}
                                  </td>

                                  {/* <input
                                      width="100"
                                      type="text"
                                      value={listItem.size_id}
                                        onChange={(e) => {                                        
                                          const sanitizedInput = e.target.value.replace(/[^A-Za-z\s]/g, "");                                   
                                          const truncatedInput = sanitizedInput.slice(0, 6);                                   
                                          let payload = {
                                            item: listItem,
                                            value: truncatedInput,
                                          };
                                          dispatch(handleWeightUnit(payload));
                                        }}
                                      />
                                          
                                    {itemWeightSizeLengthValidation.includes(
                                      listItem.id
                                    ) ? (
                                      <>
                                        <div
                                          style={{
                                            color: "red",
                                            textAlign: "left",
                                            fontSize: "14px",
                                            marginTop: "1px",
                                          }}
                                        >
                                          Unit Limit Exceed
                                        </div>
                                      </>
                                    ) : null} */}

                                  <td>
                                    <input
                                      width="100"
                                      min="1"
                                      type="number"
                                      value={listItem.price}
                                      onChange={(e) => {
                                        let inputValue = e.target.value
                                          .toString()
                                          .slice(0, 6); // Limit to 7 characters
                                        let payload = {
                                          item: listItem,
                                          value: inputValue,
                                        };
                                        dispatch(handlePrice(payload));
                                      }}
                                    />

                                    {submit &&
                                    itemPriceValidation.includes(
                                      listItem.id
                                    ) ? (
                                      <div
                                        className="err-table-msg"
                                        style={{
                                          color: "red",
                                          textAlign: "left",
                                          fontSize: "10px",
                                          marginTop: "0px",
                                        }}
                                      >
                                        Price is required
                                      </div>
                                    ) : null}
                                    {itemPriceLengthValidation.includes(
                                      listItem.id
                                    ) ? (
                                      <>
                                        <div
                                          className="err-table-msg"
                                          style={{
                                            color: "red",
                                            textAlign: "left",
                                            fontSize: "10px",
                                            marginTop: "1px",
                                          }}
                                        >
                                          Price Limit Exceed
                                        </div>
                                      </>
                                    ) : null}
                                  </td>
                                  <td>
                                    <input
                                      width="100"
                                      type="number"
                                      min="1"
                                      value={listItem.order_expiration_days}
                                      onChange={(e) => {
                                        let payload = {
                                          item: listItem,
                                          value: e.target.value,
                                        };
                                        dispatch(handleExpire(payload));
                                      }}
                                    />
                                    {submit &&
                                    itemExpireValidation.includes(
                                      listItem.id
                                    ) ? (
                                      <div
                                        className="err-table-msg"
                                        style={{
                                          color: "red",
                                          textAlign: "left",
                                          fontSize: "10px",
                                          marginTop: "1px",
                                        }}
                                      >
                                        Expire days is required
                                      </div>
                                    ) : null}
                                  </td>
                                  <td>
                                    <input
                                      width="100"
                                      min="1"
                                      type="number"
                                      value={
                                        listItem.no_of_buffer_days_before_item_could_be_reactivated
                                      }
                                      onChange={(e) => {
                                        let payload = {
                                          item: listItem,
                                          value: e.target.value,
                                        };
                                        dispatch(handleBuffer(payload));
                                      }}
                                    />
                                    {submit &&
                                    itemBufferValidation.includes(
                                      listItem.id
                                    ) ? (
                                      <div
                                        className="err-table-msg"
                                        style={{
                                          color: "red",
                                          textAlign: "left",
                                          fontSize: "10px",
                                          marginTop: "1px",
                                        }}
                                      >
                                        Buffer days is required
                                      </div>
                                    ) : null}
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        width="300"
                                        min="1"
                                        type="number"
                                        value={listItem.total_quantity}
                                        onChange={(e) => {
                                          let inputValue = e.target.value
                                            .toString()
                                            .slice(0, 6); // Limit to 7 characters
                                          let payload = {
                                            item: listItem,
                                            value: inputValue,
                                          };
                                          dispatch(handleQuantity(payload));
                                        }}
                                      />
                                      {submit &&
                                      itemQuantityValidation.includes(
                                        listItem.id
                                      ) ? (
                                        <div
                                          className="err-table-msg"
                                          style={{
                                            color: "red",
                                            textAlign: "left",
                                            fontSize: "10px",
                                            marginTop: "1px",
                                          }}
                                        >
                                          Quantity is required
                                        </div>
                                      ) : null}
                                      {itemQuantityLengthValidation.includes(
                                        listItem.id
                                      ) ? (
                                        <>
                                          <div
                                            className="err-table-msg"
                                            style={{
                                              color: "red",
                                              textAlign: "left",
                                              fontSize: "10px",
                                              marginTop: "1px",
                                            }}
                                          >
                                            Quantity Limit Exceed
                                          </div>
                                        </>
                                      ) : null}

                                      {wantList.length >= 2 ? (
                                        <div className="icon-delete">
                                          <button
                                            onClick={() => handleShow(listItem)}
                                          >
                                            <DeleteIcon />
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                )}
                <div
                  className="err-table-msg"
                  style={{
                    color: "red",
                    textAlign: "left",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  * Please note that the quantity field only accepts whole
                  numbers like 1, 2.. (no decimals)
                </div>
                <div className="text-end mt-2">
                  {wantList && wantList.length > 0 ? (
                    <button
                      className="btn-dark more-btn btn-blue border-round"
                      onClick={addRow}
                    >
                      Add more
                    </button>
                  ) : (
                    defaultList()
                  )}
                </div>

                <div className="row justify-content-center">
                  <div className="add-form-submit previewButton">
                    <button
                      onClick={() => handlePreview(wantList)}
                      className="btn btn-dark w-100 mt-2"
                    >
                      Preview
                    </button>
                  </div>
                  <div className="add-form-submit previewButton">
                    <button
                      onClick={orderPlace}
                      type="submit"
                      // disabled={isValid || isLoading}
                      className="btn btn-dark w-100 mt-2"
                    >
                      {isValid && isLoading ? <Loader /> : null}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <ModalBox
              handleClose={handleClose}
              DeleteWantItem={DeleteWantItem}
            />
          </Modal>
          <Footer />
        </LoggedInHeader>
      </>
    </>
  );
}
export default AddProducts;
